import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { List } from "@mui/material";
import { MenuItem } from "../";

import { navigationConfig } from "@shared/configs";
import { useOrganizationsData, useUserData } from "@shared/components";

import { routesConfig, type Routes } from "@features/routing";

export type MenuListProps = {
  isCollapsed: boolean;
};

export const MenuList = ({ isCollapsed }: MenuListProps) => {
  const { t } = useTranslation("common");

  const { userData } = useUserData();
  const { userOrganizationData } = useOrganizationsData();

  const filteredItems = useMemo(() => {
    if (!userData?.role) return null;

    return Object.entries(navigationConfig)
      .filter(([route, _]) => {
        const routeRoles = routesConfig[route as Routes]?.roles || [];
        const hasRoleAccess = routeRoles.includes(userData.combinedUserRole);
        const customAccess =
          routesConfig[route as Routes]?.hasAccess?.(
            userData,
            userOrganizationData
          ) ?? true;

        return hasRoleAccess && customAccess;
      })
      .map(([route, item]) => (
        <MenuItem
          key={route}
          icon={item.icon}
          // next-i18next doesn't work correctly with recursive generated types,
          // which we get from [filename].json with nested objects
          label={t(item.label as any)}
          route={route as Routes}
          isCollapsed={isCollapsed}
        />
      ));
  }, [userData, userOrganizationData, t, isCollapsed]);

  return <List>{filteredItems}</List>;
};
