import { useTranslation } from "next-i18next";
import { Task } from "@_types/*";
import { RadioInput } from "@pairtreefamily/ui";

export function ThreeStateInput(props: {
  //TODO make this more generalizable?
  answer: boolean | null;
  isAdmin: boolean;
  onChange: (e: boolean | null) => void;
  task?: Task;
  disabled?: boolean;
}) {
  const { t } = useTranslation("common");

  return (
    <>
      <div className="flex gap-4">
        <RadioInput
          checked={props.answer === true}
          onChange={() => props.onChange(true)}
          label={t("labels.yes")}
          name={`${props?.task?.id}`}
          disabled={props.disabled}
        />
        <RadioInput
          checked={props.answer !== null && !props.answer}
          onChange={() => props.onChange(false)}
          label={t("labels.no")}
          name={`${props?.task?.id}`}
          disabled={props.disabled}
        />

        {/* display only for admins  */}
        {props.isAdmin && (
          <RadioInput
            checked={props.answer === null}
            onChange={() => props.onChange(null)}
            label={t("labels.notSelected")}
            name={`${props?.task?.id}`}
          />
        )}
      </div>
    </>
  );
}
