import { useMemo } from "react";

import { Template } from "@api";
import { SelectOption } from "@pairtreefamily/ui";

export const useGetTemplatesOptions = (
  templates: Template[] | undefined
): SelectOption<string>[] => {
  return useMemo(() => {
    if (!templates || !templates.length) return [];

    const templateOptions = templates.map((i) => {
      return {
        id: i.id,
        label: `${i.name} - ${i.id}`,
      };
    });

    templateOptions.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return templateOptions;
  }, [templates]);
};
