import { ExtendedTaskGroup } from "@_types/*";

export const getTaskGroupCompletedPercent = (taskGroup: ExtendedTaskGroup) => {
  const totalTasks = taskGroup.tasks.length;
  if (totalTasks === 0) {
    return 0;
  }
  const completedTasks = taskGroup.tasks.filter(
    (task) => task.status === "completed"
  ).length;

  return Math.floor((completedTasks * 100) / totalTasks);
};
