interface FamilyData {
  parentFirstName: string;
  parentLastName: string;
  secondParentFirstName?: string | null;
  secondParentLastName?: string | null;
}
export function getFamilyName(fam: FamilyData): string {
  // no second parent: "Jane Doe"
  if (!fam.secondParentFirstName) {
    return getFullName(fam.parentFirstName, fam.parentLastName);
  }

  // shared last name: "Jane & John Doe"
  if (
    fam.parentLastName === fam.secondParentLastName ||
    !fam.secondParentLastName
  ) {
    return `${fam.parentFirstName} & ${getFullName(
      fam.secondParentFirstName,
      fam.parentLastName
    )}`;
  }

  // different last names: "Jane Doe & John Smith"
  return getFamilyNameComplete(fam);
}

export function getFamilyNameComplete(fam: FamilyData): string {
  return `${getFullName(fam.parentFirstName, fam.parentLastName)}${
    fam.secondParentFirstName &&
    ` & ${getFullName(fam.secondParentFirstName, fam.secondParentLastName)}`
  }`;
}

export function getFullName(
  firstName: string | null = "",
  lastName: string | null = ""
): string {
  return [firstName, lastName].join(" ").trim();
}
