import { httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import superjson from "superjson";

// TODO This is not a blessed approach.
// We should either have the client live in the same project as the tRPC API,
// or make the tRPC router type live in a shared library.
// This is a workaround so we can get our MVP out.
import { type AppRouter } from "@pairtreefamily/home-study-t3/src/server/trpc/router/_app";
import { token as caseJwt } from "../../shared/hooks/useCaseJwt";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { firebaseApp } from "@lib";
import * as Sentry from "@sentry/nextjs";

// These types are imported because home-study-t3 overrides some type values in next-auth. Without this, typechecking will fail when
// compiling home-study.
import type _nextAuthTypes from "@pairtreefamily/home-study-t3/src/types/next-auth";
import { appConfig } from "@lib";

export const trpc = createTRPCNext<AppRouter>({
  config() {
    const auth = getAuth(firebaseApp);
    if (appConfig.useAuthEmulator) {
      connectAuthEmulator(auth, "http://localhost:9099");
    }
    return {
      transformer: superjson,
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${appConfig.apiUrl}/api/trpc`,
          async headers() {
            const userToken = await auth.currentUser?.getIdTokenResult();
            if (userToken) {
              const isExpired =
                new Date(userToken.expirationTime).getTime() <=
                new Date().getTime();
              if (isExpired) {
                // somehow token is expired despite getting it from `getIdTokenResult`
                const message = `User token from firebase auth is expired for user ${auth.currentUser?.email}`;
                Sentry.captureException(message);
                throw new Error(message);
              }
            }
            const authToken = userToken?.token ?? null;
            return {
              ...(authToken && { Authorization: "Jwt " + authToken }),
              ...(caseJwt && { ["pairtree-case-auth"]: "CaseJwt " + caseJwt }),
            };
          },
        }),
      ],
    };
  },
  ssr: false,
});
