import {
  memo,
  useState,
  MouseEvent,
  isValidElement,
  cloneElement,
  ReactNode,
} from "react";

import { Box, Menu, MenuProps } from "@mui/material";
import { cloneChildrenWithProps } from "./helpers";

export type DropdownMenuProps = Omit<MenuProps, "open"> & {
  triggerElement: ReactNode;
  children: ReactNode[];
};

export const DropdownMenu = memo((props: DropdownMenuProps) => {
  const { triggerElement, children, ...restProps } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderTrigger = () => {
    if (!isValidElement(triggerElement)) return null;

    return cloneElement(triggerElement, {
      onClick: handleClick,
      isToggled: Boolean(anchorEl),
      ...triggerElement.props,
    });
  };

  return (
    <Box display="inline-block">
      {renderTrigger()}

      <Menu
        {...restProps}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {cloneChildrenWithProps(children, handleClose)}
      </Menu>
    </Box>
  );
});

DropdownMenu.displayName = "DropdownMenu";
