import { useCallback, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import { appConfig } from "@lib";

// Added this height to make sure, users will see custom modal buttons
const pdfViewMaxHeight = "70vh";
const pdfViewHeight = "800px";
const pdfViewWidth = "900px";

export function PandaDocIframe({
  sessionUuid,
  onDocumentCompleted,
}: {
  sessionUuid: string;
  onDocumentCompleted?: () => void;
}) {
  const [isDocumentCompleted, setIsDocumentCompleted] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const pandaDocAttachedRef = useRef(false);

  const attachPandadoc = useCallback(
    (
      pandaRef: HTMLDivElement,
      sessionId: string,
      onDocumentCompleted?: () => void
    ): void => {
      const iframe = document.createElement("iframe");
      iframe.src = `${appConfig.pandaDocUrl}${sessionId}`;
      pandaRef.style.width = pdfViewWidth;
      iframe.style.maxHeight = pdfViewMaxHeight;
      iframe.height = pdfViewHeight;
      iframe.width = pdfViewWidth;

      if (pandaDocAttachedRef.current) {
        return;
      }

      pandaRef.append(iframe);

      pandaDocAttachedRef.current = true;

      window.addEventListener("message", (event) => {
        const type = event.data && event.data.type;

        switch (type) {
          case "session_view.document.loaded":
            break;
          case "session_view.document.completed":
            if (isDocumentCompleted) {
              // abort if we've already processed this event
              return;
            }
            onDocumentCompleted && onDocumentCompleted();
            setIsDocumentCompleted(true);
            break;
          case "session_view.document.exception":
            Sentry.captureException(
              `Pandadoc iframe message listener received a session_view.document.exception. Full event object sent to the listener function: ${JSON.stringify(
                event
              )}`
            );
        }
      });
    },
    [isDocumentCompleted]
  );

  useEffect(() => {
    if (divRef.current) {
      attachPandadoc(divRef.current, sessionUuid, onDocumentCompleted);
    }
    // TODO: Investigate, can we add attachPandadoc to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef, sessionUuid, onDocumentCompleted]);
  return <div ref={divRef}></div>;
}
