import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { ErrorMessage } from "../error-message";
import { DatePicker, DatePickerProps } from "../../mui/date-picker/Datepicker";

export type DateInputFieldProps<TData> = { name: string } & Omit<
  DatePickerProps<TData>,
  "onChange" | "value"
>;

export function DateInputField<TData>({
  name,
  ...rest
}: DateInputFieldProps<TData>) {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <div className="flex flex-col">
          <DatePicker onChange={field.onChange} value={field.value} {...rest} />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </div>
      );
    },
    [rest]
  );

  return <Controller name={name} render={render} />;
}
