import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummaryProps,
  AccordionProps,
  AccordionDetailsProps,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { memo } from "react";

export const AccordionSummary = memo((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} expandIcon={<ExpandMoreIcon />}>
    {props.children}
  </MuiAccordionSummary>
));

AccordionSummary.displayName = "AccordionSummary";

export const AccordionDetails = memo((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props}>{props.children}</MuiAccordionDetails>
));

AccordionDetails.displayName = "AccordionDetails";

export const AccordionContainer = memo((props: AccordionProps) => (
  <MuiAccordion {...props}>{props.children}</MuiAccordion>
));

AccordionContainer.displayName = "AccordionContainer";
