import { palette } from "@pairtreefamily/ui";

export const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyItems: "space-between",
    gap: 1,
  },
  tableCell: {
    "&:hover": {
      backgroundColor: palette.divider,

      "& button": {
        opacity: 1,
      },
    },
  },
};

export function getIconButtonStyles({
  isSorted,
}: {
  isSorted: string | boolean;
}) {
  return {
    height: "24px",
    width: "24px",
    padding: 0,
    color: palette.text.light,
    opacity: isSorted ? 1 : 0,
    backgroundColor: "transparent",
    transform:
      isSorted && isSorted === "asc" ? "rotate(90deg)" : "rotate(-90deg)",

    "&:hover": {
      backgroundColor: "white",
      color: palette.text.light,
    },

    svg: {
      height: "10px",
      width: "10px",
    },
  };
}
