import {
  ElectronicDocumentTask,
  FileUploadTask,
  ReferenceTask,
  Task,
} from "@_types/*";
import Markdown from "./Markdown";
import { ThreeStateInput } from "./ThreeStateInput";
import {
  TaskRefreshDateAndFilesView,
  TaskRefreshDateProps,
} from "./refresh-task";

type Props = {
  task: Task;
  answer?: boolean | null;
  onAnswer?: (e: boolean | null) => void;
} & Pick<
  TaskRefreshDateProps,
  | "getFileUrl"
  | "viewDocumentsLink"
  | "shouldDisplayViewDocumentsLink"
  | "getFileName"
>;

export function TaskTitle({
  task,
  answer,
  onAnswer,
  ...taskRefreshProps
}: Props) {
  const hasQuestion =
    !!task?.question && answer !== undefined && onAnswer !== undefined;
  const answeredYes = answer === true;
  const showTitle = !hasQuestion || (hasQuestion && answeredYes);

  return (
    <>
      {hasQuestion && (
        <div className="mt-2 mb-2">
          <Question
            task={task}
            changeSelection={onAnswer}
            answer={task.answer}
          />
        </div>
      )}
      {showTitle && <Markdown content={task.title} />}

      {task.clonedFromId && (
        <TaskRefreshDateAndFilesView task={task} {...taskRefreshProps} />
      )}
    </>
  );
}

function Question(props: {
  task: ElectronicDocumentTask | FileUploadTask | ReferenceTask;
  answer: boolean | null;
  changeSelection: (e: boolean | null) => void;
}) {
  const { task, changeSelection, answer } = props;

  return (
    <>
      <div className="text-body-4">{task.question}</div>

      <div className="mt-1 flex gap-8">
        <ThreeStateInput
          isAdmin={false}
          answer={answer}
          onChange={changeSelection}
          task={task}
          disabled={task.isLocked}
        />
      </div>
    </>
  );
}
