import {
  DatePicker as DatePickerMui,
  DatePickerProps as DatePickerMuiProps,
} from "@mui/x-date-pickers";
import { TextInput } from "../text-input";
import { useMemo } from "react";
import { Calendar } from "../../icons";
import { palette } from "../../theme";

const slots = { textField: TextInput, openPickerIcon: Calendar };

const slotProps = {
  openPickerButton: {
    sx: { color: "success.light" },
    color: "success" as const,
  },
  desktopPaper: {
    sx: {
      border: `1px solid ${palette.divider}`,
      borderRadius: "10px",
    },
  },
};

export type DatePickerProps<TDate> = {} & Omit<
  DatePickerMuiProps<TDate>,
  "slots"
>;

export function DatePicker<TDate>({
  minDate,
  ...props
}: DatePickerProps<TDate>) {
  const minDateValue = useMemo(() => {
    return minDate || new Date("1900-01-01");
  }, [minDate]);

  return (
    <DatePickerMui
      showDaysOutsideCurrentMonth
      slotProps={slotProps}
      slots={slots}
      minDate={minDateValue as TDate}
      {...props}
    />
  );
}
