export function CorporateIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H10C10.55 3 11.0208 3.19583 11.4125 3.5875C11.8042 3.97917 12 4.45 12 5V7H20C20.55 7 21.0208 7.19583 21.4125 7.5875C21.8042 7.97917 22 8.45 22 9V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H4ZM4 19H10V17H4V19ZM4 15H10V13H4V15ZM4 11H10V9H4V11ZM4 7H10V5H4V7ZM12 19H20V9H12V19ZM15 13C14.7167 13 14.4792 12.9042 14.2875 12.7125C14.0958 12.5208 14 12.2833 14 12C14 11.7167 14.0958 11.4792 14.2875 11.2875C14.4792 11.0958 14.7167 11 15 11H17C17.2833 11 17.5208 11.0958 17.7125 11.2875C17.9042 11.4792 18 11.7167 18 12C18 12.2833 17.9042 12.5208 17.7125 12.7125C17.5208 12.9042 17.2833 13 17 13H15ZM15 17C14.7167 17 14.4792 16.9042 14.2875 16.7125C14.0958 16.5208 14 16.2833 14 16C14 15.7167 14.0958 15.4792 14.2875 15.2875C14.4792 15.0958 14.7167 15 15 15H17C17.2833 15 17.5208 15.0958 17.7125 15.2875C17.9042 15.4792 18 15.7167 18 16C18 16.2833 17.9042 16.5208 17.7125 16.7125C17.5208 16.9042 17.2833 17 17 17H15Z"
        fill="currentColor"
      />
    </svg>
  );
}
