import { alpha, Palette } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    active: Omit<PaletteColor, "light">;
    proBlue: PaletteColor;
  }

  interface PaletteColor {
    background?: string;
    gradientMain?: string;
    snackbar?: string;
    snackbarShadow?: string;
    backgroundLight?: string;
    backgroundDark?: string;
  }

  interface TypeText {
    light: string;
    light50: string;
    dark: string;
  }
  interface TypeBackground {
    light: string;
    light75: string;
    light50: string;
    light15: string;
  }
}

type CustomPalette = Omit<
  Palette,
  "common" | "tonalOffset" | "getContrastText" | "augmentColor"
>;

export const palette: CustomPalette = {
  primary: {
    main: "#44978D",
    dark: "#3C867D",
    light: "#4CA99E",
    contrastText: "#FFFFFF",
    background: "#EDF8F7",
    gradientMain: "#006679",
    snackbar: "#005261",
    snackbarShadow: "#111F3424",
    backgroundDark: "#E2F5F3",
    backgroundLight: "#F5FBFA",
  },
  secondary: {
    main: "#99CA44",
    dark: "#8DBF36",
    light: "#A4D058",
    contrastText: "#FFFFFF",
  },
  proBlue: {
    main: "#006679",
    dark: "#005261",
    light: "#007D94",
    contrastText: "#FFFFFF",
    background: "#E6F4F7",
  },
  error: {
    main: "#DE4A39",
    dark: "#DB3724",
    light: "#E25F50",
    contrastText: "#FFFFFF",
    background: "#FCEDEB",
  },
  warning: {
    main: "#F8A400",
    dark: "#EB7F00",
    light: "#F4BB2A",
    contrastText: "#FFFFFF",
    background: "#FFF8E6",
  },
  info: {
    main: "#00A7E1",
    dark: "#00A7E1",
    light: "#00A7E1",
    contrastText: "#FFFFFF",
  },
  success: {
    main: "#414141",
    dark: "#333333",
    light: "#5E5E5E",
    contrastText: "#FFFFFF",
  },
  text: {
    primary: "#414141",
    secondary: "#333333",
    disabled: "#CCCCCC",
    light: "#5E5E5E",
    light50: "#A0A0A0",
    dark: "#333333",
  },
  active: {
    main: "#FFBD00",
    dark: "#F89600",
    background: "#FFF8E6",
    contrastText: "#E4A900",
  },
  background: {
    default: "#FFFFFF",
    paper: "#FFFFFF",
    light: "#F7F7F7",
    light75: alpha("#F7F7F7", 0.75),
    light50: alpha("#F7F7F7", 0.5),
    light15: alpha("#F7F7F7", 0.15),
  },
  action: {
    active: "#FFBD00",
    hover: "#F6F6F6",
    hoverOpacity: 0.08,
    selected: "#000000",
    selectedOpacity: 0.16,
    disabled: "#CCCCCC",
    disabledBackground: "#000000",
    disabledOpacity: 0.38,
    focus: "#005fcc",
    focusOpacity: 0.5,
    activatedOpacity: 0.24,
  },
  divider: "#EBEBEB",
  grey: {
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FFFFFF",
    400: "#FFFFFF",
    500: "#FFFFFF",
    600: "#FFFFFF",
    700: "#FFFFFF",
    800: "#FFFFFF",
    900: "#FFFFFF",
    A100: "#FFFFFF",
    A200: "#FFFFFF",
    A400: "#FFFFFF",
    A700: "#FFFFFF",
  },
  mode: "light",
  contrastThreshold: 3,
};
