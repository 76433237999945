import { forwardRef, useCallback } from "react";
import { IMaskInput } from "react-imask";

import { formatPhoneNumber } from "./utils";
import type { IMaskInputProps } from "react-imask";

export type IMaskCustomInputProps = IMaskInputProps<HTMLInputElement> & {
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
};

export const IMaskCustomInput = forwardRef<
  HTMLInputElement,
  IMaskCustomInputProps
>(({ onChange, ...props }, ref) => {
  const handleInputAccept = useCallback(
    (value: string) => {
      const formattedValue = formatPhoneNumber(value);
      onChange({ target: { name: props.name, value: formattedValue } });
    },
    [onChange, props.name]
  );

  return <IMaskInput {...props} inputRef={ref} onAccept={handleInputAccept} />;
});

IMaskCustomInput.displayName = "IMaskCustomInput";
