import { Case } from "@_types/tasks";
import { OrgMember } from "../organization";

export enum UserRole {
  Provider = "provider",
  Admin = "admin",
  Family = "family",
}

export interface UserData {
  role: UserRole;
  email: string;
  cases: Case[];
  details: UserDetails | null;
  isHomeBaseUser: boolean;
}

// TODO share with server - there's a sibling type defined in `home-study-t3`
type UserDetails =
  | {
      role: "provider";
      orgMemberships: OrgMember[];
      hasPendingOrgRequests: boolean;
    }
  | {
      role: "family";
    }
  | {
      role: "admin";
    };
