export const formatPhoneNumber = (value: string) => {
  // Assuming value is a string of digits, format it as "000-000-0000"
  const match = value.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return value;
};
