import { palette } from "@pairtreefamily/ui";
import { alpha } from "@mui/material";

export const swapIconClass = "swap-icon";

export const styles = {
  tableHead: {
    backgroundColor: "white",
    border: "none",
    borderBottom: `1px solid ${palette.divider}`,
  },
  swapIconTableHeadCell: {
    maxWidth: "64px",
    minWidth: "64px",
    width: "64px",
    p: 0,
  },
  headSwapIcon: { color: "action.disabled" },
  swapIconTableRow: {
    cursor: "default",

    "&:nth-child(odd)": {
      backgroundColor: alpha(palette.background.light, 0.5),
    },

    [`&:hover .${swapIconClass}`]: {
      display: "flex",
    },
  },
  swapIconTableCell: { cursor: "default" },
  swapIconWrapper: {
    display: "none",
    color: "action.disabled",
    cursor: "grab",
    justifyContent: "center",

    "&:hover": {
      color: "primary.main",
    },
  },
  taskGroupBellContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    color: palette.success.light,
    position: "relative",
  },
  counter: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    backgroundColor: palette.error.main,
    height: "20px",
    width: "20px",
    top: "-4px",
    left: "16px",
    color: palette.primary.contrastText,
  },
  progressBar: {
    marginRight: "40px",
  },
};
