import axios from "axios";
import { appConfig } from "@lib";
import {
  paramsSerializer,
  payloadRequestTransformer,
  payloadResponseTransformer,
} from "./utils";

import type { Archetype } from "@_types/*";
import {
  FamilyListItem,
  FamilyListFilterPayload,
  FamilyListRequestPayload,
  FamilyListCountResponse,
  FamilyProfileDetails,
  FamilyProfileDetailsRequest,
  RequestFamilyInformationRequest,
  OrganizationDataForFamilyResponse,
  UnlinkFamFromOrgRequest,
  NewSafetyCenterThreadRequest,
  ThreadsListResponse,
  ThreadDetailsRequest,
  ThreadDetailsResponse,
  NewCommentRequest,
  ThreadsListRequest,
  FamilyListCountRequestArgs,
} from "@api";
import {
  InfinitePaginationResponse,
  Organization,
  OrganizationInviteRequest,
  OrganizationMember,
  PendingInvitation,
  PendingInvitesRequest,
  ResendOrganizationInviteRequest,
  RevokeOrganizationInviteRequest,
} from "./types";

export namespace CoreClient {
  export const ApiClient = axios.create({
    baseURL: `${appConfig.coreApiUrl}/${appConfig.coreApiVersion}`,
    transformRequest: [payloadRequestTransformer],
    transformResponse: [payloadResponseTransformer],
    paramsSerializer: paramsSerializer,
    headers: {
      "Content-Type": "application/json",
    },
  });

  ApiClient.interceptors.response.use((response) => {
    return response.data;
  });

  export async function getArchetypeList(): Promise<Archetype[]> {
    return ApiClient.get("/archetypes/");
  }

  export async function getFamilyList({
    organizationUuid,
    query,
    body,
  }: FamilyListRequestPayload): Promise<
    InfinitePaginationResponse<FamilyListItem>
  > {
    const queryParams = paramsSerializer(query);
    return ApiClient.post(
      `/organization/${organizationUuid}/families/search?${queryParams}`,
      body
    );
  }

  // the `organizationUuid` arg is part of the resource, while the (optional)
  // on in `args` is part of the search request body
  export async function getFamilyListCount({
    organizationUuid,
    body,
  }: FamilyListCountRequestArgs): Promise<FamilyListCountResponse> {
    return ApiClient.post(
      `/organization/${organizationUuid}/families/search/count`,
      body
    );
  }

  export async function getFamilyProfileDetails(
    args: FamilyProfileDetailsRequest
  ): Promise<FamilyProfileDetails> {
    return ApiClient.get(
      `/organization/${args.organizationUuid}/families/profile-details/slug/${args.slug}`
    );
  }

  // Organization
  export async function getOrganization(uuid?: string): Promise<Organization> {
    return ApiClient.get(`/organization/${uuid}`);
  }

  export async function updateOrganization({
    uuid,
    ...args
  }: Organization): Promise<Organization> {
    return ApiClient.patch(`/organization/${uuid}`, args);
  }

  export async function getOrganizationMembersList(
    uuid?: string
  ): Promise<OrganizationMember[]> {
    return ApiClient.get(`/organization/${uuid}/members`);
  }

  export async function updateOrganizationMember(
    args: Omit<OrganizationMember, "orgName">
  ): Promise<OrganizationMember> {
    return ApiClient.post(
      `/organization/${args.orgUuid}/members/${args.proUuid}`,
      args
    );
  }

  export async function deleteOrganizationMember(args: {
    proUuid?: string;
    orgUuid?: string;
  }): Promise<OrganizationMember> {
    return ApiClient.delete(
      `/organization/${args.orgUuid}/members/${args.proUuid}`
    );
  }
  export async function getMyProDetails(): Promise<any> {
    return ApiClient.get("/pro");
  }

  export async function getPendingInvites({
    uuid,
  }: PendingInvitesRequest): Promise<PendingInvitation[]> {
    return ApiClient.get(`/organization/${uuid}/invites`);
  }

  export async function getFamPendingInvites({
    uuid,
  }: PendingInvitesRequest): Promise<PendingInvitation[]> {
    return ApiClient.get(`/organization/${uuid}/fam-invites`);
  }

  export async function sendOrganizationInvite({
    uuid,
    ...args
  }: OrganizationInviteRequest): Promise<OrganizationMember> {
    return ApiClient.post(`/organization/${uuid}/invites`, args);
  }

  export async function resendOrganizationInvite({
    inviteUuid,
    organizationUuid,
  }: ResendOrganizationInviteRequest): Promise<OrganizationMember> {
    return ApiClient.post(
      `/organization/${organizationUuid}/invites/${inviteUuid}`
    );
  }

  export async function revokeOrganizationInvite({
    inviteUuid,
    organizationUuid,
  }: RevokeOrganizationInviteRequest): Promise<null> {
    return ApiClient.delete(
      `/organization/${organizationUuid}/invites/${inviteUuid}`
    );
  }

  export async function unlikFamFromOrg({
    famUuid,
    organizationUuid,
  }: UnlinkFamFromOrgRequest): Promise<null> {
    return ApiClient.delete(`/organization/${organizationUuid}/fam`, {
      data: { uuid: famUuid },
    });
  }

  export async function requestFamilyInformation({
    organizationUuid,
    ...args
  }: RequestFamilyInformationRequest) {
    return ApiClient.post(
      `/organization/${organizationUuid}/fam-info-request`,
      args
    );
  }

  export async function getOrganizationDataForFamilyRequest(args: {
    organizationUuid: string;
  }): Promise<OrganizationDataForFamilyResponse> {
    return ApiClient.get(
      `/organization/${args.organizationUuid}/fam-info-request-details`
    );
  }

  export async function getSafetyCenterThreads({
    query,
    body,
    organizationUuid,
  }: ThreadsListRequest): Promise<ThreadsListResponse> {
    const queryParams = paramsSerializer(query);

    return ApiClient.post(
      `/organization/${organizationUuid}/safety-center/threads/search?${queryParams}`,
      {
        searchQuery: body.searchQuery || null,
      }
    );
  }

  export async function getSafetyCenterThreadsCount(args: {
    searchQuery: string;
    organizationUuid: string;
  }): Promise<{ count: number }> {
    return ApiClient.post(
      `/organization/${args.organizationUuid}/safety-center/threads/search/count`,
      {
        searchQuery: args.searchQuery || null,
      }
    );
  }

  export async function getThreadDetails(
    args: ThreadDetailsRequest
  ): Promise<ThreadDetailsResponse> {
    return ApiClient.get(
      `/organization/${args.organizationUuid}/safety-center/threads/${args.id}`
    );
  }

  export async function createNewSafetyCenterThread(
    args: NewSafetyCenterThreadRequest
  ): Promise<void> {
    return ApiClient.post(
      `/organization/${args.organizationUuid}/safety-center/threads`,
      args
    );
  }

  export async function getOrgsFromFam(args: {
    famEmail: string;
  }): Promise<Organization[]> {
    return ApiClient.get(`/organization/${args.famEmail}/orgs-from-fam`);
  }

  export async function sendNewComment(args: NewCommentRequest): Promise<void> {
    return ApiClient.post(
      `/organization/${args.organizationUuid}/safety-center/threads/${args.threadId}/comment`,
      args
    );
  }
}
