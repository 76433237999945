import Head from "next/head";

import { firebaseApp } from "@lib";
import { AuthMode, LoginAndSignUp } from "@shared/components";

export function Login() {
  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <LoginAndSignUp mode={AuthMode.login} onlyGoogle app={firebaseApp} />
    </>
  );
}
