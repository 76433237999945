import { TaskType } from "@_types/*";

// TODO: move to the translation file
export const taskTypeOptions: { id: TaskType; label: string }[] = [
  {
    id: "electronicDocument",
    label: "Electronic Document",
  },
  {
    id: "fileUpload",
    label: "File Upload",
  },
  {
    id: "reference",
    label: "Reference",
  },
];

export const taskTypeLabelsMap: Record<TaskType, string> =
  taskTypeOptions.reduce((acc, { id, label }) => {
    acc[id] = label;
    return acc;
  }, {} as Record<TaskType, string>);
