import { Routes, routesConfig } from "@features/routing";

import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import {
  ConnectIcon,
  HomeBaseIcon,
  OrganizationIcon,
} from "@pairtreefamily/ui";

import type { ReactNode } from "react";
import { CombinedUserRole } from "../components/user-provider/types";

export type NavigationItemType = {
  role: CombinedUserRole[];
  icon: ReactNode;
  label: ReactNode;
};

export const navigationConfig: Partial<Record<Routes, NavigationItemType>> = {
  [Routes.Admin]: {
    role: routesConfig[Routes.Admin].roles,
    icon: <AdminPanelSettingsOutlinedIcon />,
    label: "layout.navigation.menu.adminDashboard",
  },
  [Routes.ProfessionalDashboard]: {
    role: routesConfig[Routes.ProfessionalDashboard].roles,
    icon: <HomeBaseIcon />,
    label: "layout.navigation.menu.homeBase",
  },
  [Routes.Connect]: {
    role: routesConfig[Routes.Connect].roles,
    icon: <ConnectIcon />,
    label: "layout.navigation.menu.connect",
  },
  [Routes.SafetyCenter]: {
    role: routesConfig[Routes.SafetyCenter].roles,
    icon: <AdminPanelSettingsOutlinedIcon />,
    label: "layout.navigation.menu.safetyCenter",
  },
  [Routes.Organization]: {
    role: routesConfig[Routes.Organization].roles,
    icon: <OrganizationIcon />,
    label: "layout.navigation.menu.myOrganization",
  },
};
