import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { TextInput, TextInputProps } from "../../mui";
import { ErrorMessage } from "../error-message";
import { ThemeProvider } from "../../theme";
import { InputProps } from "@mui/material";

export interface TextFieldProps
  extends Omit<TextInputProps, "onChange" | "value"> {
  name: string;
}

export const TextField = ({ name, ...rest }: TextFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <ThemeProvider>
          <TextInput
            value={field.value}
            onChange={field.onChange}
            name={name}
            {...rest}
          />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </ThemeProvider>
      );
    },
    [name, rest]
  );

  return <Controller name={name} render={render} />;
};
