import { alpha, Box, Typography } from "@mui/material";
import { palette } from "../../theme";

type ErrorMessageProps = {
  error: string;
};

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    mt: 4,
    p: 2,
    backgroundColor: alpha(palette.error.main, 0.05),
    borderRadius: "16px",
  },
};

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.content}>
        <Typography variant="caption2" color="error">
          {error}
        </Typography>
      </Box>
    </Box>
  );
};
