import { useTranslation } from "next-i18next";
import { StatusIcon, InfoIcon } from "@pairtreefamily/ui";

import type { Task } from "@_types/tasks";

type ErrorStatusProps = {
  error: Task["documentIssueError"];
};

export function ErrorStatus({ error }: ErrorStatusProps) {
  const { t } = useTranslation("components");

  return (
    <div className="text-body-4 flex items-center justify-start font-bold">
      <StatusIcon status="error" />
      {t("statusElement.error.label")}
      <div
        className="cursor-pointer"
        onClick={() => alert(t("statusElement.error.alert", { error }))}
      >
        <InfoIcon />
      </div>
    </div>
  );
}
