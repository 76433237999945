import {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactElement,
  ReactNode,
} from "react";

import { DropdownItem } from "@pairtreefamily/ui";
import type { MenuItemProps } from "@mui/material";

export const cloneChildrenWithProps = (
  children: ReactNode,
  handleClose: () => void
): ReactNode => {
  return Children.map(children, (child) => {
    if (!isValidElement(child)) return null;

    if (child.type === DropdownItem) {
      return cloneElement(child as ReactElement<MenuItemProps>, {
        onClick: (event: MouseEvent<HTMLLIElement>) => {
          handleClose();
          child.props.onClick?.(event);
        },
      });
    }

    if (child.props.children) {
      return cloneElement(child as ReactElement<MenuItemProps>, {
        children: cloneChildrenWithProps(child.props.children, handleClose),
      });
    }

    return child;
  });
};
