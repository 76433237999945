import { useCallback, useEffect, useState } from "react";
import { useGetArchivedTaskFiles } from "@api";
import { UseFileUpload } from "@shared/hooks";

type UseGetFilesDataProps = {
  getFileName?: UseFileUpload["getFileName"];
  taskId: string;
};

export const useGetFilesData = ({
  getFileName,
  taskId,
}: UseGetFilesDataProps) => {
  const [files, setFiles] = useState<{ id: string; name: string }[]>([]);
  const getArchivedTaskFiles = useGetArchivedTaskFiles();

  const getFiles = useCallback(async () => {
    const fileIds = await getArchivedTaskFiles({ taskId });

    function getFilesData(fileId: string) {
      return new Promise<{ id: string; name: string }>(async (resolve) => {
        if (!getFileName) {
          resolve({ id: fileId, name: fileId });
          return;
        }

        const name = await getFileName(fileId);

        resolve({ id: fileId, name: name ?? fileId });
      });
    }

    if (fileIds.ok) {
      const filesData = await Promise.all(fileIds.content.map(getFilesData));

      setFiles(filesData);
    }
    // Currently, getFileName is constantly updated and this creates an infinite loop of requests to the Google Drive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getArchivedTaskFiles, taskId]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  return files;
};
