export const GoogleIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5398 12.2541C23.5407 11.4949 23.4758 10.737 23.3458 9.98877H12.7234V14.2797H18.8074C18.6829 14.965 18.4194 15.6183 18.0328 16.2001C17.6461 16.782 17.1444 17.2804 16.5578 17.6653V20.4506H20.1888C22.3148 18.5118 23.5398 15.6445 23.5398 12.2541Z"
      fill="#4285F4"
    />
    <path
      d="M12.7233 23.1417C15.763 23.1417 18.3224 22.1545 20.1887 20.4523L16.5577 17.667C15.5471 18.3448 14.2455 18.7316 12.7233 18.7316C9.78534 18.7316 7.29166 16.7727 6.39994 14.1328H2.65942V17.0032C3.59693 18.8484 5.03448 20.3996 6.8116 21.4836C8.58872 22.5675 10.6355 23.1416 12.7233 23.1417Z"
      fill="#34A853"
    />
    <path
      d="M6.40012 14.1325C5.9287 12.7493 5.9287 11.2512 6.40012 9.86796V6.99756H2.6596C1.87094 8.54982 1.46021 10.263 1.46021 12.0003C1.46021 13.7375 1.87094 15.4507 2.6596 17.0029L6.40012 14.1325Z"
      fill="#FBBC04"
    />
    <path
      d="M12.7234 5.26917C14.3298 5.24322 15.8819 5.84353 17.0443 6.94035L20.2592 3.76047C18.2207 1.86659 15.52 0.826821 12.7234 0.859129C10.6356 0.859224 8.58884 1.43329 6.81173 2.51725C5.03461 3.6012 3.59705 5.15238 2.65955 6.9976L6.40006 9.868C7.29178 7.22816 9.78546 5.26917 12.7234 5.26917Z"
      fill="#EA4335"
    />
  </svg>
);
