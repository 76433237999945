import { Box } from "@mui/material";
import { Logo, WorkspaceToolbar } from "./components";

import { styles } from "./styles";

export const Header = () => (
  <Box sx={styles.wrapper}>
    <Box sx={styles.container}>
      <Logo sx={styles.image} />

      <WorkspaceToolbar />
    </Box>
  </Box>
);
