import { CheckedIcon, Icon } from "./Icons";
import { palette } from "../../palette";
import { alpha } from "@mui/material";

export const MuiCheckbox = {
  defaultProps: {
    icon: <Icon />,
    checkedIcon: <CheckedIcon />,
  },
  styleOverrides: {
    root: {
      padding: "5px",

      "&.Mui-focusVisible svg": {
        boxShadow: `0 0 0 2px ${alpha(
          palette.action.focus,
          palette.action.focusOpacity
        )}`,
        borderRadius: "4px",
      },
    },
  },
};
