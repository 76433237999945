import { palette, theme, typography } from "../../theme";

export const modalContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: palette.background.paper,
  borderRadius: "20px",
  py: 5,
  px: 3,
  boxShadow: "0px 24px 38px 0px #111F3424",
  maxHeight: `calc(100% - ${theme.spacing(4)})`,
  maxWidth: "80vw",
  display: "flex",
  flexDirection: "column",
};

export const scrollingContainer = {
  overflowY: "auto",
  px: 2,
  flex: "1 1 auto",
};

export const title = {
  color: palette.text.primary,
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  gap: 2,
  marginBottom: 4,
  px: 2,
};

export const closeButton = {
  color: palette.text.primary,
  height: "32px",
  width: "32px",
};

export const buttons = {
  display: "flex",
  justifyContent: "center",
  gap: 3,
  marginTop: 4,
};

export const error = {
  ...typography.body1,
  color: palette.error.main,
  marginY: "20px",
};
