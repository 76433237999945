import { useState } from "react";
import { ModalLegacy } from "../../../components";
import { DreamcatcherMetadataWithTooltip } from "./tooltip";

export type MetadataTooltipProps = {
  metadata: DreamcatcherMetadataWithTooltip;
};

export function MetadataTooltip(props: MetadataTooltipProps) {
  const { tooltipLabel, tooltipContent } = props.metadata;
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <button
        className="text-body-3 inline-block font-bold"
        onClick={() => setModalOpen(true)}
      >
        {tooltipLabel}
      </button>
      <ModalLegacy
        title={tooltipLabel}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <div className="mt-8 max-w-[600px]">{tooltipContent}</div>
      </ModalLegacy>
    </>
  );
}

export default MetadataTooltip;
