import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import {
  OrgMember,
  useGetAuthUserData,
  useGetMyProDetails,
  UserData,
} from "@api";
import { useAuthStatus } from "@pairtreefamily/ui";
import { getCombinedUserRole } from "./utils";
import { CombinedUserRole } from "./types";

export type UserDataWithProDetails = {
  proDetails: null | {
    email: string;
    group: "pro";
    org_memberships: OrgMember[];
    isHomeBaseUser: boolean;
    has_pending_org_requests: boolean;
  };
} & UserData & {
    combinedUserRole: CombinedUserRole;
  };

const UserDataContext = createContext<{
  userData: UserDataWithProDetails | null;
  isUserDataLoading: boolean;
  invalidate: () => void;
}>({
  userData: null,
  isUserDataLoading: true,
  invalidate: () => {},
});

export const UserDataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthStatus();
  const {
    data: myProData,
    isFetching: isMyProDataFetching,
    isLoading: isMyProDataLoading,
    remove: removeProDetails,
  } = useGetMyProDetails({ enabled: isAuthenticated });

  const {
    data,
    isLoading: isUserDataLoading,
    isFetching: isUserDataFetching,
    remove: removeAuthData,
  } = useGetAuthUserData({
    enabled: isAuthenticated,
  });

  const proData = myProData?.ok ? myProData?.content : null;
  const userData = data?.ok ? data?.content : null;

  const isLoading =
    (isUserDataLoading && isUserDataFetching) ||
    (isMyProDataLoading && isMyProDataFetching);

  const value = useMemo(() => {
    const userWithProDetails =
      isAuthenticated && userData
        ? {
            ...userData,
            proDetails: proData,
          }
        : null;

    return {
      userData: userWithProDetails
        ? {
            ...userWithProDetails,
            combinedUserRole: getCombinedUserRole(userWithProDetails),
          }
        : null,
      isUserDataLoading: isLoading,
      invalidate: () => {
        removeAuthData();
        removeProDetails();
      },
    };
  }, [
    isAuthenticated,
    userData,
    proData,
    isLoading,
    removeAuthData,
    removeProDetails,
  ]);

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
