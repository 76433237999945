import { SxProps, Theme } from "@mui/system";
import { palette, theme } from "@pairtreefamily/ui";
import { alpha } from "@mui/material/styles";

export const styles = {
  wrapper: {
    paddingY: theme.spacing(0.5),
  },
  button: (isActiveRoute: boolean): SxProps<Theme> => ({
    minHeight: 48,
    borderRadius: "10px",
    backgroundColor: isActiveRoute
      ? alpha(palette.background.light, 0.75)
      : "transparent",
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: isActiveRoute
        ? palette.background.light
        : alpha(palette.background.light, 0.75),
      "& .MuiListItemIcon-root": {
        color: palette.secondary.light,
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(palette.background.light, 0.75),
      "&:hover, &.Mui-focusVisible": {
        backgroundColor: palette.background.light,
      },

      "& .MuiListItemIcon-root": {
        color: palette.secondary.light,
      },
    },
  }),
  icon: {
    minWidth: 0,
    color: palette.text.primary,
    "& .MuiSvgIcon-root": {
      fontSize: 24,
    },
  },
  text: (isCollapsed: boolean): SxProps<Theme> => ({
    marginLeft: isCollapsed ? 0 : theme.spacing(1),
    opacity: isCollapsed ? 0 : 1,
    color: palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
};
