import { useTranslation } from "next-i18next";
import { CircularProgressbar } from "react-circular-progressbar";
import { Loading, palette } from "@pairtreefamily/ui";
import "react-circular-progressbar/dist/styles.css";
import { Typography } from "@mui/material";

export function RadialProgressBar({
  percentComplete,
}: {
  percentComplete: number;
}) {
  const { t } = useTranslation("components");

  const idCSS = "idCss";
  const gradientTransform = `rotate(90)`;

  return (
    <div className="relative max-w-[120px]">
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id={idCSS} gradientTransform={gradientTransform}>
            <stop offset="16.29%" stopColor="#44978d" />
            <stop offset="85.56%" stopColor="#99CA44" />
          </linearGradient>
        </defs>
      </svg>
      {percentComplete >= 0 && (
        <>
          <CircularProgressbar
            className="fill-green"
            strokeWidth={6}
            value={percentComplete}
            styles={{
              path: { stroke: `url(#${idCSS})`, height: "100%" },
              trail: {
                stroke: "#F5F5F5",
              },
            }}
          />
          <div className="absolute top-0 flex h-full w-full flex-col justify-center text-center">
            <Typography
              variant="head3"
              sx={{
                // TODO revisit this with design team (font is not from UI kit)
                color: palette.secondary.main,
                fontSize: "30px",
                lineHeight: "40px",
                fontFamily: "Montserrat, sans-serif",
                fontStyle: "normal",
              }}
            >
              {percentComplete}%
            </Typography>

            <p className="text-body-5">
              <strong className="uppercase">
                {t("radialProgressBar.completeLabel")}
              </strong>
            </p>
          </div>
        </>
      )}
      {isNaN(percentComplete) && <Loading />}
    </div>
  );
}
