import { useCallback } from "react";
import { ExtendedTaskGroup, TaskGroup } from "@_types/tasks";
import { Result } from "@pairtreefamily/utils";

export const useReorderTasks = (
  taskGroups: ExtendedTaskGroup[] | undefined,
  caseId: string,
  updateTaskOrder: (args: {
    tasks: Array<{ taskId: string; order: number }>;
    caseId: string;
  }) => Promise<Result<{ success: string }, string>>
) => {
  return useCallback(
    async (newOrder: string[], group: TaskGroup | ExtendedTaskGroup) => {
      const extendedGroup = taskGroups?.find((item) => item.id === group.id);
      const tasks: { taskId: string; order: number }[] = [];
      newOrder.forEach((item, index) => {
        if (extendedGroup?.tasks[index].id !== item) {
          tasks.push({
            taskId: item,
            order: index,
          });
        }
      });

      await updateTaskOrder({
        tasks,
        caseId,
      }).catch(() => {
        console.log("error updating task order");
      });
    },
    [caseId, taskGroups, updateTaskOrder]
  );
};
