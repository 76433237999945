import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "next-i18next";

import {
  TextField,
  TextAreaField,
  SwitchField,
  FormModal,
} from "@pairtreefamily/ui";
import { Separator } from "@shared/components";

import { defaultValues } from "./constants";
import { validationSchema, FormValues } from "./validation-schema";
import { updateFormValues } from "./utils";

import type { CreateTaskGroupModalProps } from "./types";

export function CreateTaskGroupModal({
  isOpen,
  taskGroup,
  isProvider,
  createTaskGroup,
  onClose,
}: CreateTaskGroupModalProps) {
  const { t } = useTranslation(["components", "common"]);

  const formMethods = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues,
    values: updateFormValues(taskGroup),
  });

  const handleModalClose = useCallback(() => {
    formMethods.reset(defaultValues);
    onClose();
  }, [formMethods, onClose]);

  const modalTitle =
    taskGroup?.title ?? t("components:createTaskGroupModal.title");

  const handleSubmit = useCallback(
    async ({ providerOnly, ...values }: FormValues) => {
      await createTaskGroup({
        id: taskGroup?.id ?? "",
        providerOnly: isProvider,
        percentComplete: 0,
        ...values,
      });

      handleModalClose();
    },
    [createTaskGroup, handleModalClose, isProvider, taskGroup?.id]
  );

  return (
    <FormModal
      submitLabel={t("common:actions.submit")}
      formMethods={formMethods}
      onSubmit={handleSubmit}
      title={modalTitle}
      open={isOpen}
      onClose={handleModalClose}
    >
      <TextField
        name="title"
        label={t("components:createTaskGroupModal.fields.name")}
      />

      <TextAreaField
        rows={4}
        name="taskGroupInstruction"
        label={t("components:createTaskGroupModal.fields.description")}
      />

      <Separator className="mb-4 mt-8" />

      <div className="pt-8" />

      <p className="text-body-4-semi mb-[5px] text-left">
        {t("components:createTaskGroupModal.fields.hiddenDescription")}
      </p>

      <p className="text-body-4 pb-4 text-left text-onyx-light">
        <em>{t("components:createTaskGroupModal.fields.hiddenWarning")}</em>
      </p>

      <SwitchField
        name="isHidden"
        label={t("components:createTaskGroupModal.fields.hidden")}
      />

      <Separator className="my-8" />
    </FormModal>
  );
}
