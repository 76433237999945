import { ButtonLegacyProps, ButtonLegacy } from "../legacy/Button";
import ArrowIcon from "../icons/Arrow";

const GoBackButton = (props: ButtonLegacyProps) => {
  return (
    <ButtonLegacy {...props} otherStyles="mb-7" style="text">
      <ArrowIcon className="mr-2.5 rotate-180" />
      Go back
    </ButtonLegacy>
  );
};

export default GoBackButton;
