import { createContext, useContext, useState } from "react";
import { Modal } from "../mui/modal/Modal";
export interface ConfirmationModalContextProps {
  withConfirmation: (message: string, onConfirm: () => void) => void;
}

const ConfirmationModalContext = createContext<ConfirmationModalContextProps>({
  withConfirmation: () => {},
});

export const useConfirmationModal = () => useContext(ConfirmationModalContext);

export const ConfirmationModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [onConfirm, setOnConfirm] = useState(() => () => {});

  return (
    <ConfirmationModalContext.Provider
      value={{
        withConfirmation: (message, onConfirm) => {
          setMessage(message);
          setOnConfirm(() => onConfirm);
          setIsOpen(true);
        },
      }}
    >
      {children}
      <Modal
        title="Are you sure?"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        submitButton={{
          label: "Confirm",
          onClick: async () => {
            onConfirm();
            setIsOpen(false);
            return null;
          },
        }}
      >
        <p>{message}</p>
      </Modal>
    </ConfirmationModalContext.Provider>
  );
};
