import React, { ReactNode } from "react";
import { useTranslation } from "next-i18next";
import { ExtendedTaskGroup, Task } from "@_types/*";
import { DragAndDrop, SortableItem } from "@shared/components";
import { TaskItem } from "../../TaskItem";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { SwapIcon } from "@pairtreefamily/ui";
import { styles, swapIconClass } from "./styles";

interface Props {
  taskGroup: ExtendedTaskGroup;
  row?: (
    task: Task,
    rowNum: number
  ) => {
    column1?: ReactNode;
    column2?: ReactNode;
    column3?: ReactNode;
    column4?: ReactNode;
  };
  updatedTasksOrder?: (newOrder: string[]) => void;
  allowReorder?: boolean;
}

export function TaskTable({
  taskGroup,
  row,
  updatedTasksOrder,
  allowReorder,
}: Props) {
  const { t } = useTranslation("components");

  return (
    <Box p={2} pt={5}>
      <Table className="w-full">
        <TableHead sx={styles.tableHead}>
          <TableRow>
            {allowReorder && (
              <TableCell sx={styles.swapIconTableHeadCell}>
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={styles.headSwapIcon}
                >
                  <SwapIcon />
                </Box>
              </TableCell>
            )}

            <TableCell>
              <Typography textAlign="left" variant="overline">
                {t("taskGroupView.table.task")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="overline">
                {t("taskGroupView.table.description")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="overline">
                {t("taskGroupView.table.status")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="overline">
                {t("taskGroupView.table.action")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allowReorder ? (
            <DragAndDrop
              onSort={(newOrder) =>
                updatedTasksOrder && updatedTasksOrder(newOrder)
              }
              itemsList={taskGroup.tasks.map((task: Task, idx) => ({
                id: task.id,
                element: (
                  <SortableItem key={task.id} id={task.id}>
                    {(props) => (
                      <TableRow
                        ref={props.setNodeRef}
                        style={props.style}
                        sx={styles.swapIconTableRow}
                        {...props.attributes}
                      >
                        <TableCell sx={styles.swapIconTableCell}>
                          <Box
                            className={swapIconClass}
                            sx={styles.swapIconWrapper}
                            {...props.listeners}
                          >
                            <SwapIcon />
                          </Box>
                        </TableCell>
                        <TaskItem
                          key={`${task.id}`}
                          idx={idx}
                          task={task}
                          row={row}
                        />
                      </TableRow>
                    )}
                  </SortableItem>
                ),
              }))}
            />
          ) : (
            taskGroup.tasks.map((task: Task, idx) => (
              <TableRow sx={styles.swapIconTableRow} key={`tr-${task.id}`}>
                <TaskItem key={`${task.id}`} idx={idx} task={task} row={row} />
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Box>
  );
}
