import { defaultValues } from "./constants";
import type { TaskGroup } from "@_types/*";

export const updateFormValues = (taskGroup: TaskGroup | null) => {
  if (!taskGroup) {
    return defaultValues;
  }

  const { title, taskGroupInstruction, providerOnly, isHidden } = taskGroup;
  return {
    title,
    taskGroupInstruction: taskGroupInstruction ?? "",
    providerOnly,
    isHidden,
  };
};
