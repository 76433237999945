import { useCallback } from "react";
import { useTranslation } from "next-i18next";

import { Result } from "@pairtreefamily/utils";
import { Loading, Modal, TrashIcon } from "@pairtreefamily/ui";
import { Button, Link } from "@mui/material";

type FileType = { id: string; name: string };

export type UploadedFilesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  getFileUrl: (fileId: string) => Promise<Result<string, unknown>>;
  viewDocumentsLink: string | null;
  onDeleteFile?: (fileId: string, fileName: string) => void;
  filesData?: FileType[] | null;
  isLocked?: boolean;
};

export function UploadedFilesModal(props: UploadedFilesModalProps) {
  const {
    onDeleteFile,
    getFileUrl,
    isOpen,
    onClose,
    viewDocumentsLink,
    filesData,
    isLocked,
  } = props;

  const { t } = useTranslation("components");

  const handleFileNameClick = useCallback(
    async (file: FileType) => {
      const result = await getFileUrl(file.id ?? "");

      if (result.ok && result.content) {
        window.open(result.content, "_blank")?.focus();
      }
    },
    [getFileUrl]
  );

  return (
    <Modal
      title={t("uploadedFilesModal.title")}
      onClose={onClose}
      open={isOpen}
    >
      <div className="pl-4 pr-1 pb-1 pt-5">
        {!filesData && <Loading />}
        {filesData?.length === 0 && (
          <div className="text-body-3 text-center">
            {t("uploadedFilesModal.emptyFiles")}
          </div>
        )}
        {filesData &&
          filesData.map((file) => {
            if (!file.name) return null;

            return (
              <div key={file.id} className="flex items-center gap-5">
                {onDeleteFile &&
                  (!isLocked ? (
                    <TrashIcon
                      className="cursor-pointer"
                      onClick={async () => {
                        // TODO make async and handle errors/state on completion
                        onDeleteFile(file.id, file.name);
                      }}
                    />
                  ) : null)}

                <Link onClick={() => handleFileNameClick(file)}>
                  {file.name}
                </Link>
              </div>
            );
          })}

        {viewDocumentsLink && (
          <div className="flex justify-center pt-7">
            <div className="m-auto">
              <a target="_blank" rel="noreferrer" href={viewDocumentsLink}>
                <Button>{t("uploadedFilesModal.viewDocuments")}</Button>
              </a>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
