import { trpc } from "../trpc";
import { Result } from "@pairtreefamily/utils";

export const useGetDocumentHomeLink = (caseId: string) => {
  return trpc.homeStudy.getDocumentHomeLink.useQuery<
    void,
    Result<string, string>
  >({ caseId: caseId });
};

interface UseGetPrivateDocumentLinkProps {
  caseId: string;
  enabled: boolean;
}

export const useGetPrivateDocumentLink = ({
  caseId,
  enabled,
}: UseGetPrivateDocumentLinkProps) => {
  return trpc.homeStudy.getPrivateDocumentLink.useQuery<
    void,
    Result<string, string>
  >(
    { caseId: caseId },
    // Don't run this query unless you need to.
    { enabled }
  );
};
