import { useTranslation } from "next-i18next";

import { getClassName } from "@pairtreefamily/utils";
import { TextField } from "@pairtreefamily/ui";
import { ThreeStateField } from "./ThreeStateField";

import { fieldsGroupClassName } from "../styles";
import { TaskStatus } from "@_types/*";

type QuestionFieldProps = {
  isAdmin: boolean;
  status?: TaskStatus;
};

export function ConditionalQuestionFields(props: QuestionFieldProps) {
  const { isAdmin, status } = props;

  const { t } = useTranslation("components");

  const isFieldDisabled = !isAdmin && status === "completed";

  return (
    <div className={fieldsGroupClassName}>
      <div className={getClassName(fieldsGroupClassName)}>
        <TextField
          label={t("questionForm.fields.conditionalQuestion")}
          name="question"
          disabled={isFieldDisabled}
        />
      </div>

      <ThreeStateField
        disabled={isFieldDisabled}
        isAdmin={isAdmin}
        name="answer"
      />
    </div>
  );
}
