import { trpc } from "../trpc";
import { Result } from "@pairtreefamily/utils";
import { ProviderDashboardCase } from "./types";

export const useGetProviderDashboardCases = (active: boolean) => {
  return trpc.homeStudy.getProviderDashboardCases.useQuery<
    void,
    Result<ProviderDashboardCase[], string>
  >({ active });
};
