import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { Task } from "@_types/*";
import { Modal } from "@pairtreefamily/ui";
import Markdown from "./Markdown";
import { Link, Typography } from "@mui/material";

export function TaskDescription(props: { task: Task }) {
  const { task } = props;

  const { t } = useTranslation("components");

  const contentRef = useRef<HTMLDivElement>(null);
  const [isClamped, setClamped] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const observer = new ResizeObserver(() => {
      if (contentRef.current) {
        setClamped(
          contentRef.current.scrollHeight > contentRef.current.clientHeight
        );
      }
    });

    observer.observe(contentRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const description = task.description || "";

  return (
    <div className="text-body-3 overflow-hidden" style={{ width: "350px" }}>
      <div
        ref={contentRef}
        style={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
          overflow: "hidden",
        }}
      >
        <Markdown content={description} />
      </div>

      {isClamped && (
        <Link onClick={() => setExpanded(true)}>
          {t("taskDescription.readMore")}
        </Link>
      )}

      <Modal
        title={
          <Typography variant="head5">
            {t("taskDescription.taskLabel")} {task.title}
          </Typography>
        }
        open={isExpanded}
        onClose={() => setExpanded(false)}
      >
        <div className="text-body-3">
          <Markdown content={description} />
        </div>
      </Modal>
    </div>
  );
}
