import { Box, Drawer, IconButton, SxProps, Typography } from "@mui/material";
import { DrawerProps } from "@mui/material/Drawer/Drawer";
import { ReactNode, useMemo } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { styles } from "./styles";
import { Form, FormProps } from "../../form";

export type SidePanelProps<T extends Record<string, any>> = Omit<
  DrawerProps,
  "onClose" | "onSubmit"
> &
  Omit<FormProps<T>, "formHeight" | "onSubmit" | "formMethods"> & {
    children: ReactNode;
    onClose: (() => void) | ((args: any) => Promise<void>);
    title?: string;
    formMethods?: FormProps<T>["formMethods"];
    actions?: ReactNode;
    onSubmit?: FormProps<T>["onSubmit"];
    sxContentWrapper?: SxProps;
    sxHeaderWrapper?: SxProps;
  };

export const SidePanel = <T extends Record<string, any>>({
  actions,
  title,
  children,
  formMethods,
  sxContentWrapper,
  sxHeaderWrapper,
  onSubmit,
  ...props
}: SidePanelProps<T>) => {
  const contentWrapperStyles = useMemo(() => {
    return { ...styles.contentWrapper, ...sxContentWrapper };
  }, [sxContentWrapper]) as SxProps;

  const headerWrapperStyles = useMemo(() => {
    return { ...styles.header, ...sxHeaderWrapper };
  }, [sxHeaderWrapper]) as SxProps;

  const renderContents = () => (
    <Box sx={styles.wrapper}>
      <Box sx={contentWrapperStyles}>
        <Box sx={headerWrapperStyles}>
          <Typography variant="head4">{title}</Typography>

          <IconButton onClick={props.onClose} sx={styles.iconButton}>
            <CloseRoundedIcon sx={styles.icon} />
          </IconButton>
        </Box>

        {children}
      </Box>

      {actions && <Box sx={styles.actionsWrapper}>{actions}</Box>}
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      {...props}
      PaperProps={styles.PaperProps}
      slotProps={styles.slotProps}
    >
      {onSubmit && formMethods ? (
        <Form<T>
          shouldAskForUnsavedChanges
          formMethods={formMethods}
          onSubmit={onSubmit}
          formHeight="100%"
        >
          {renderContents()}
        </Form>
      ) : (
        <>{renderContents()}</>
      )}
    </Drawer>
  );
};
