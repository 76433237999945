import { SetStateAction, useEffect, useMemo, useState } from "react";
import { DreamcatcherStep } from ".";
import { ButtonLegacy, GoBackButton } from "../..";
import {
  BaseDreamcatcherInput,
  DreamcatcherCondition,
  DreamcatcherFlowProps,
  DreamcatcherStepProps,
  DreamcatcherValue,
  findNextStep,
  resolveAllConditions,
  resolveDreamcatcherCondition,
} from "../schemas";
import {
  DreamcatcherPathProvider,
  useDreamcatcherPathAt,
  useDreamcatcherValueAccessor,
} from "./DreamcatcherPathContext";

export type ExtraDreamcatcherFlowProps = {
  // ID of the step to start at. If not provided, the first step will be used.
  startingStep?: string;
  // Can specify to preserve the history of previous steps. Used in conjunction with startingStep to
  // allow the user to start from the end of a flow and go backwards.
  previousStepStack?: string[];
  enableBackButton?: boolean;
  // when there are no previous steps, this is called when the back button is pressed.
  backButtonFallbackBehavior?: () => void;
  onComplete?: (values: DreamcatcherValue[]) => void;

  onCurrentStepChanged?: (step: string | null) => void;
  onPreviousStepStackChanged?: (steps: string[] | null) => void;
};

export const useDreamcatcherFlow = ({
  id,
  steps,
  onComplete,
  backButtonFallbackBehavior,
  startingStep,
  previousStepStack: initialPreviousStepStack,
  onCurrentStepChanged,
  onPreviousStepStackChanged,
}: Pick<DreamcatcherFlowProps, "id" | "steps"> &
  ExtraDreamcatcherFlowProps) => {
  // TODO Validate that every nextStep exists before accepting a DreamcatcherFlow.

  const { values, path } = useDreamcatcherPathAt(id);

  const [currentStep, setCurrentStep] = useState<DreamcatcherStepProps | null>(
    startingStep
      ? steps.find((step) => step.id === startingStep) ?? null
      : steps?.[0] ?? null
  );

  const getValueAtPath = useDreamcatcherValueAccessor();

  const nextStep: DreamcatcherStepProps | null = useMemo(() => {
    if (currentStep === null) {
      return null;
    }

    const conditionResolver = (condition: DreamcatcherCondition) =>
      resolveDreamcatcherCondition(condition, getValueAtPath);
    const stepAccessor = (id: string): DreamcatcherStepProps | null =>
      steps.find((step) => step.id == id) ?? null;

    return findNextStep(currentStep, stepAccessor, conditionResolver);
  }, [currentStep, steps, values, getValueAtPath]);

  const [previousStepStack, _setPreviousSteps] = useState<
    DreamcatcherStepProps[]
  >(
    initialPreviousStepStack
      ? initialPreviousStepStack
          .map((stepId) => steps.find((step) => step.id == stepId))
          .filter((step): step is DreamcatcherStepProps => step !== undefined)
      : []
  );

  const addPreviousStep = (step: DreamcatcherStepProps) => {
    _setPreviousSteps((previousSteps) => {
      if (previousSteps) {
        return [...previousSteps, step];
      }
      return [step];
    });
  };

  const popPreviousStep = () => {
    _setPreviousSteps((previousSteps) => {
      if (previousSteps) {
        return previousSteps.slice(0, previousSteps.length - 1);
      }
      return [];
    });
  };
  const finishStep = () => {
    if (currentStep) {
      addPreviousStep(currentStep);
    }
    setCurrentStep(nextStep);
    if (!nextStep) {
      // Send the values to the parent.
      onComplete && onComplete(values);
    }
  };

  const backButtonDisabled =
    previousStepStack.length == 0 && !backButtonFallbackBehavior;

  const goBack = () => {
    if (previousStepStack.length > 0) {
      setCurrentStep(previousStepStack[previousStepStack.length - 1]);
      popPreviousStep();
    } else if (backButtonFallbackBehavior) {
      backButtonFallbackBehavior();
    }
  };

  useEffect(() => {
    if (currentStep) {
      onCurrentStepChanged && onCurrentStepChanged(currentStep.id);
    }
  }, [currentStep, onCurrentStepChanged]);

  useEffect(() => {
    onPreviousStepStackChanged &&
      onPreviousStepStackChanged(previousStepStack.map((step) => step.id));
  }, [previousStepStack, onPreviousStepStackChanged]);

  return {
    currentStep,
    nextStep,
    finishStep,
    backButtonDisabled,
    goBack,
  };
};

export const DreamcatcherFlow = ({
  id,
  steps,
  onComplete,
  enableBackButton = true,
  ...rest
}: DreamcatcherFlowProps & ExtraDreamcatcherFlowProps) => {
  const { currentStep, finishStep, backButtonDisabled, goBack } =
    useDreamcatcherFlow({
      id,
      steps,
      onComplete,
      ...rest,
    });
  const { values, path } = useDreamcatcherPathAt(id);
  const getValueAtPath = useDreamcatcherValueAccessor();

  if (!currentStep) {
    return <div>Thank you!</div>;
  }

  const isInputActive = (input: BaseDreamcatcherInput): boolean => {
    // if there's no conditional, the input is active
    if (!input.conditional) {
      return true;
    }
    // if there is a conditional, we need to check if it resolves based on
    // the current store values
    const conditionResolver = (condition: DreamcatcherCondition) =>
      resolveDreamcatcherCondition(condition, getValueAtPath);
    const conditionsMet = resolveAllConditions(
      input.conditional,
      conditionResolver
    );
    // the input is active if the conditions are all met
    return conditionsMet;
  };
  const hasInputBeenAnswered = (input: BaseDreamcatcherInput): boolean => {
    const valFromStore = values.find(
      (v) => v.path.join(".") == [...path, currentStep.id, input.id].join(".")
    );

    return (
      valFromStore !== undefined &&
      valFromStore.value.length > 0 &&
      !valFromStore.value.includes("")
    );
  };

  const disableNextStep = !isStepComplete({
    currentStep,
    hasInputBeenAnswered,
    isInputActive,
  });

  return (
    <DreamcatcherPathProvider path={id}>
      <div key={currentStep.id} className="max-w-[790px]">
        {enableBackButton && (
          <div className="flex justify-center">
            <GoBackButton disabled={backButtonDisabled} onClick={goBack} />
          </div>
        )}
        <DreamcatcherStep {...currentStep} />
        <div className="mt-12 mb-11 flex items-center justify-center">
          <ButtonLegacy
            style="primary"
            color="teal"
            disabled={disableNextStep}
            onClick={finishStep}
            data-test="confirm"
          >
            {currentStep.confirmText}
          </ButtonLegacy>
        </div>
      </div>
    </DreamcatcherPathProvider>
  );
};

function isStepComplete({
  currentStep,
  hasInputBeenAnswered,
  isInputActive,
}: {
  currentStep: DreamcatcherStepProps;
  isInputActive: (input: BaseDreamcatcherInput) => boolean;
  hasInputBeenAnswered: (input: BaseDreamcatcherInput) => boolean;
}): boolean {
  for (const input of currentStep.inputs) {
    if (isInputActive(input) && !hasInputBeenAnswered(input)) {
      // because there's an "active" input without an answer, we can't move
      // on to the next step, so we disable the button
      return false;
    }
  }
  // if we've gotten here without short-circuiting, then all active inputs
  // have been answered
  return true;
}
