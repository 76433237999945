import { TooltipProps, Tooltip as MuiTooltip } from "@mui/material";
import { memo } from "react";

export const Tooltip = memo((props: TooltipProps) => (
  <MuiTooltip {...props} placement="top">
    {props.children}
  </MuiTooltip>
));

Tooltip.displayName = "Tooltip";
