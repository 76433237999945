import {
  useCreateReferenceTask,
  useDeleteDocument,
  useGetAllFilesFromTaskGroup,
  useGetCaseProviders,
  useGetFileData,
  useGetFileUrl,
  useGetTaskGroups,
  useInvalidateTaskList,
  useSendReferenceRequest,
  useUpdateTaskQuestionAnswer,
  useUpdateTaskStatus,
  useUploadDocument,
} from "@api";
import { ExtendedTaskGroup, TaskAssignment } from "@_types/tasks";
import * as Sentry from "@sentry/nextjs";
import { useMemo } from "react";
import { TRPCClientErrorLike } from "@trpc/client";
import { useRefreshElectronicDocumentSession } from "src/api/trpc/electronicDocuments/refreshSession";

// unused because types are disjointed from server
// TODO define this interface
type _FamilyPageData = {};

export type UseFamilyPageArgs = {
  caseId: string;
};

export type FamilyTools = ReturnType<typeof useFamilyTools>;

export const useFamilyTools = ({ caseId }: UseFamilyPageArgs) => {
  const invalidateTaskList = useInvalidateTaskList({ caseId });

  // get task groups for family tasks, excluding pro-only tasks
  const { data: allTaskGroups } = useGetTaskGroups({ caseId });

  const taskGroups = useMemo(() => {
    return allTaskGroups?.filter(
      (taskGroup: ExtendedTaskGroup) => !taskGroup.providerOnly
    );
  }, [allTaskGroups]);

  const sendReferenceRequest = useSendReferenceRequest({
    caseId,
    onSuccess: invalidateTaskList,
    onError: (err: TRPCClientErrorLike<any>) => {
      const errorMessage = `The frontend attempted to call sendReferenceRequest procedure but encountered an error: ${JSON.stringify(
        err
      )}`;

      Sentry.captureException(errorMessage);
    },
  });

  const createReferenceTask = useCreateReferenceTask({
    caseId,
    onSuccess: invalidateTaskList,
  });

  const updateTaskStatus = useUpdateTaskStatus({
    onSuccess: invalidateTaskList,
  });

  const updateTaskQuestionAnswer = useUpdateTaskQuestionAnswer({
    onSuccess: invalidateTaskList,
  });

  const uploadDocument = useUploadDocument({ onSuccess: invalidateTaskList });
  const deleteDocument = useDeleteDocument({ onSuccess: invalidateTaskList });

  const getAllFilesFromTaskGroup = useGetAllFilesFromTaskGroup();

  const caseProviders = useGetCaseProviders({
    caseId: caseId,
    onlyProvidersWithContactInfo: true,
  });

  const getFileUrl = useGetFileUrl();
  const getFileData = useGetFileData();

  const refreshElectronicDocumentSession = useRefreshElectronicDocumentSession({
    caseId: caseId,
    onSuccess: invalidateTaskList,
  });

  return {
    taskGroups,
    caseProviders,
    sendReferenceRequest,
    createReferenceTask,
    updateTaskStatus,
    updateTaskQuestionAnswer,
    uploadDocument,
    deleteDocument,
    getFileUrl,
    getAllFilesFromTaskGroup,
    getFileData,
    refreshElectronicDocumentSession,
  };
};
