import { formControlLabelClasses } from "@mui/material";
import { typography } from "../typography";

export const MuiFormControlLabel = {
  styleOverrides: {
    root: {
      [`& .${formControlLabelClasses.asterisk}`]: {
        ...typography.body4,
      },
    },
  },
};
