import { Box } from "@mui/material";
import { styles } from "./styles";

import { ToggleViewButton, MenuList } from "./components";

type NavigationPanelProps = {
  onToggleViewButtonClick: () => void;
  isCollapsed: boolean;
};

export const NavigationPanel = ({
  onToggleViewButtonClick,
  isCollapsed,
}: NavigationPanelProps) => (
  <Box sx={styles.wrapper}>
    <Box sx={styles.iconWrapper}>
      <ToggleViewButton
        isCollapsed={isCollapsed}
        onClick={onToggleViewButtonClick}
      />
    </Box>

    <MenuList isCollapsed={isCollapsed} />
  </Box>
);
