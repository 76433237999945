import { CombinedUserRole } from "../../shared/components/user-provider/types";
import { Routes } from "./routes.enum";
import { RouteConfig, routesConfig } from "./routes-config";
import * as Sentry from "@sentry/nextjs";
import { UserDataWithProDetails } from "@shared/components";
import { OrganizationMembership } from "@api";

const proUserRoles = [
  CombinedUserRole.ProviderConnect,
  CombinedUserRole.ProviderHomeBaseConnect,
];

export function findFirstAllowedRoute(
  combinedUserRole?: CombinedUserRole
): Routes {
  if (!combinedUserRole) {
    return Routes.Home;
  }

  const routes = Object.keys(routesConfig) as Routes[];
  return routes.find((route) =>
    routesConfig[route].roles.includes(combinedUserRole)
  ) as Routes;
}

export function setSentryUser(isAuthenticated: boolean, email?: string | null) {
  if (isAuthenticated) {
    if (email) {
      Sentry.setUser({
        email,
      });
    }
  } else {
    Sentry.setUser(null);
  }
}

export function cannotAccessProLogin(
  combinedUserRole: CombinedUserRole | undefined,
  isAuthenticated: boolean,
  pathname: Routes,
  hasOrganizations: boolean
) {
  const proLoginRoutes = [Routes.ProAccountSignIn];
  const isOrganizationRequired = isOrganizationRequestRequired(
    isAuthenticated,
    combinedUserRole,
    hasOrganizations
  );
  return (
    combinedUserRole &&
    isAuthenticated &&
    proLoginRoutes.includes(pathname) &&
    (!proUserRoles.includes(combinedUserRole) ||
      (proUserRoles.includes(combinedUserRole) && !isOrganizationRequired))
  );
}

export function isRoleAllowed(
  combinedUserRole: CombinedUserRole | undefined,
  allowedRoles: CombinedUserRole[]
) {
  return (
    combinedUserRole &&
    !allowedRoles.includes(combinedUserRole) &&
    allowedRoles.length
  );
}

export function isOrganizationRequestRequired(
  isAuthenticated: boolean,
  combinedUserRole: CombinedUserRole | undefined,
  hasOrganizations: boolean
) {
  if (
    combinedUserRole === CombinedUserRole.ProviderHomeBase ||
    combinedUserRole === CombinedUserRole.ProviderHomeBaseConnect
  ) {
    return false;
  }

  if (
    combinedUserRole &&
    isAuthenticated &&
    proUserRoles.includes(combinedUserRole) &&
    !hasOrganizations
  ) {
    return true;
  }
}

export function hasAccessRoute(
  userData: UserDataWithProDetails | null,
  orgData: OrganizationMembership | null,
  routeConfig: RouteConfig
) {
  return routeConfig.hasAccess?.(userData, orgData) ?? true;
}
