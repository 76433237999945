import { Form, FormProps } from "../form";
import {
  FormModalActions,
  ForModalActionProps,
} from "./components/FormModalActions";
import { Modal, ModalProps } from "../mui";
import { useCallback, useState } from "react";
import { ErrorMessage } from "./components/ErrorMessage";

type FormModalProps<T extends Record<string, any>> = Omit<
  ModalProps,
  "submitButton" | "onSubmit"
> &
  FormProps<T> &
  Omit<ForModalActionProps, "onCancel" | "isLoading">;

export function FormModal<T extends Record<string, any>>({
  formMethods,
  onSubmit,
  children,
  submitLabel,
  isSubmitDisabled,
  ...rest
}: FormModalProps<T>) {
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(
    async (values: T) => {
      setError(null);

      try {
        await onSubmit(values);
      } catch (e) {
        setError("An error occurred while submitting the form.");
      }
    },
    [onSubmit]
  );

  return (
    <Modal {...rest}>
      <Form<T> formMethods={formMethods} onSubmit={handleSubmit}>
        {children}

        {error && <ErrorMessage error={error} />}

        <FormModalActions
          onCancel={(event) => rest?.onClose?.(event || {}, "escapeKeyDown")}
          isSubmitDisabled={isSubmitDisabled}
          isLoading={formMethods.formState.isSubmitting}
          submitLabel={submitLabel}
        />
      </Form>
    </Modal>
  );
}
