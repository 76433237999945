import { alpha, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { ReactElement, useState } from "react";
import { palette } from "@pairtreefamily/ui";

type RenderComponentProps = {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onFocus: () => void;
  onBlur: () => void;
  maxLength: number;
};

type TextAreaWithMaxCharactersProps = {
  value: string;
  renderComponent: (props: RenderComponentProps) => ReactElement;
  maxLength: number;
};

export const TextAreaWithMaxCharacters = ({
  value,
  renderComponent,
  maxLength,
}: TextAreaWithMaxCharactersProps) => {
  const { t } = useTranslation("components");

  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const onFocus = () => setIsFocused(true);

  const onBlur = () => setIsFocused(false);

  const getLabelColor = () => {
    if (charactersRemainingCount === 0) return palette.error.main;
    if (isFocused) return palette.primary.main;
    if (isHovered) return palette.text.primary;

    return `${alpha(palette.text.light, palette.action.focusOpacity)}`;
  };

  const charactersRemainingCount = maxLength - value.length;

  return (
    <>
      {renderComponent({
        onMouseEnter,
        onMouseLeave,
        onFocus,
        onBlur,
        maxLength,
      })}

      <Typography
        mt={1}
        component="p"
        variant="caption1"
        color={getLabelColor()}
      >
        {t("textArea.charactersRemaining", {
          count: charactersRemainingCount,
          maxLength,
        })}
      </Typography>
    </>
  );
};
