import { Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { formatDateToView } from "@shared/utils";
import React, { useCallback, useState } from "react";
import { UploadedFilesModal } from "../../../UploadFilesModal";
import { Result } from "@pairtreefamily/utils";
import { Task } from "@_types/*";
import { UseFileUpload } from "@shared/hooks";
import { useGetFilesData } from "./hooks";

export type TaskRefreshDateProps = {
  task: Task;
  viewDocumentsLink?: string | null;
  getFileUrl?: (fileId: string) => Promise<Result<string, unknown>>;
  shouldDisplayViewDocumentsLink?: boolean;
  getFileName?: UseFileUpload["getFileName"];
};

export const TaskRefreshDateAndFilesView = ({
  task,
  viewDocumentsLink,
  getFileUrl,
  getFileName,
  shouldDisplayViewDocumentsLink,
}: TaskRefreshDateProps) => {
  const { t } = useTranslation("components");
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);

  const files = useGetFilesData({ getFileName, taskId: task.id });

  const handleViewDocumentsClick = useCallback(() => {
    setIsFileModalOpen(true);
  }, []);

  const handleFilesModalClose = useCallback(() => {
    setIsFileModalOpen(false);
  }, []);

  const formattedDate = formatDateToView(task.createdAt);

  if (!formattedDate) {
    return null;
  }

  return (
    <>
      <Typography variant="body7" color="text.light">
        {t("refreshTask.taskRefreshedOn", {
          date: formattedDate,
        })}{" "}
        {shouldDisplayViewDocumentsLink && Boolean(files?.length) && (
          <Typography
            variant="body7"
            color="primary.main"
            sx={{ cursor: "pointer" }}
            onClick={handleViewDocumentsClick}
          >
            {t("refreshTask.viewDocuments")}
          </Typography>
        )}
      </Typography>

      {isFileModalOpen && getFileUrl && viewDocumentsLink && (
        <UploadedFilesModal
          isOpen={isFileModalOpen}
          onClose={handleFilesModalClose}
          filesData={files}
          getFileUrl={getFileUrl}
          viewDocumentsLink={viewDocumentsLink}
        />
      )}
    </>
  );
};
