import { add } from "date-fns";

export function isDateExpiring(expiringDays: number, date?: Date | null) {
  if (!date) return false;
  const today = new Date();
  const expirationDate = add(today, {
    days: expiringDays,
  });
  return date >= today && date <= expirationDate;
}

export function isDateExpired(date?: Date | null) {
  if (!date) return false;

  return date < new Date();
}

export const isDateExpiringOrExpired = (
  expiringDays: number,
  date?: Date | null
) => {
  if (!date) return false;
  return isDateExpiring(expiringDays, date) || isDateExpired(date);
};
