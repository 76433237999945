import { rebuildObjProperties } from "./rebuild-object-properties";
import { AxiosResponseTransformer } from "axios";
import { toCamelCase } from "./to-camel-case";

export const payloadResponseTransformer: AxiosResponseTransformer = (
  payload
) => {
  let parsedData;
  try {
    parsedData = JSON.parse(payload);
  } catch (error) {
    return payload;
  }

  return rebuildObjProperties(parsedData, toCamelCase);
};
