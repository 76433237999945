import { Theme } from "@mui/material";
import { RouteConfig } from "@features/routing";
import { palette } from "@pairtreefamily/ui";

const headerHeight = 88;
const screenViewPadding = 0;
const headerContentPadding = 0;
const sidenavContentPadding = 0;

const desktopSidenavWidth = 248;
const mobileSidenavWidth = 88;

export const styles = {
  wrapper: (isNavigationPanelCollapsed: boolean, routeConfig: RouteConfig) => {
    const gridTemplateColumns = routeConfig.hasNavigationPanel
      ? `${
          isNavigationPanelCollapsed ? mobileSidenavWidth : desktopSidenavWidth
        }px auto`
      : "0 auto";

    const gridTemplateRows = routeConfig.hasHeader
      ? `${headerHeight}px auto`
      : "0 auto";

    return {
      display: "grid",
      gridTemplateRows: gridTemplateRows,
      gridTemplateColumns: gridTemplateColumns,
      padding: screenViewPadding,
      height: "100%",
      gridTemplateAreas: `
    "header header"
    "navigationPanel content"
  `,
      columnGap: sidenavContentPadding,
      rowGap: headerContentPadding,
      transition: "grid-template-columns 0.5s",
    };
  },
  header: {
    gridArea: "header",
    position: "sticky",
    zIndex: 3,
    top: 0,
    left: 0,
  },
  content: () => {
    return {
      width: "100%",
      gridArea: "content",
      position: "relative",
      maxHeight: "100%",
      height: "100%",
      overflow: "auto",
      backgroundColor: palette.background.default,
      transition: "margin-left 400ms",
    };
  },
  navigationPanel: {
    gridArea: "navigationPanel",
    position: "sticky",
    top: (theme: Theme) =>
      `calc(${theme.spacing(screenViewPadding)} + ${theme.spacing(
        headerContentPadding
      )} + ${headerHeight}px)`,
    height: (theme: Theme) =>
      `calc(100vh - ${headerHeight}px - ${theme.spacing(
        screenViewPadding * 2
      )} - ${theme.spacing(headerContentPadding)})`,
    width: "100%",
    zIndex: 1000,
  },
};
