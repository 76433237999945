import { useCallback } from "react";
import * as Sentry from "@sentry/nextjs";
import { UseProfessionalPageTools } from "../../features/professional/hooks/useProfessionalTools";

interface Props {
  caseId: string;
  taskId: string;
  updateTaskQuestionAnswer: UseProfessionalPageTools["updateTaskQuestionAnswer"];
  setAnswer: (answer: boolean | null) => void;
}

export const useHandleSetAnswer = ({
  caseId,
  taskId,
  updateTaskQuestionAnswer,
  setAnswer,
}: Props) => {
  return useCallback(
    (e: boolean | null) => {
      console.log("answer", e);
      updateTaskQuestionAnswer({
        taskId,
        answer: e,
        caseId,
      }).catch((error) => {
        Sentry.captureException(
          `error while updating answer",  ${JSON.stringify(error)}`
        );
      });

      setAnswer(e);
    },
    [caseId, setAnswer, taskId, updateTaskQuestionAnswer]
  );
};
