import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { RadioListField } from "@pairtreefamily/ui";
import { Box } from "@mui/material";

type ThreeStateInputProps = {
  name: string;
  isAdmin: boolean;
  disabled?: boolean;
};

type FieldState = {
  value: boolean | null;
  label: string;
};

export function ThreeStateField({
  name,
  isAdmin,
  disabled,
}: ThreeStateInputProps) {
  const { t } = useTranslation("common");

  const fieldsStatesList = useMemo(() => {
    const fieldStates: FieldState[] = [
      { value: true, label: t("labels.yes") },
      { value: false, label: t("labels.no") },
    ];

    if (isAdmin) {
      fieldStates.push({
        value: null,
        label: t("labels.notSelected"),
      });
    }

    return fieldStates;
  }, [t, isAdmin]);

  return (
    <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
      <RadioListField
        name={name}
        options={fieldsStatesList}
        disabled={disabled}
      />
    </Box>
  );
}
