export function ApprovedBadgeIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.8763 14.8794C29.6503 15.6674 29.6389 16.9337 28.8509 17.7077L26.2875 20.2256V24.2864C26.2875 25.391 25.3921 26.2864 24.2875 26.2864H20.117L17.4812 28.8753C16.6932 29.6493 15.4269 29.638 14.6529 28.8499L12.1349 26.2864H8.35054C7.24597 26.2864 6.35054 25.391 6.35054 24.2864V20.3974L3.48527 17.4802C2.71126 16.6922 2.72261 15.4259 3.51063 14.6519L6.35054 11.8625V8.34947C6.35054 7.2449 7.24597 6.34947 8.35054 6.34947H11.9633L14.8803 3.4843C15.6684 2.71028 16.9346 2.72164 17.7087 3.50966L20.498 6.34947H24.2875C25.3921 6.34947 26.2875 7.2449 26.2875 8.34947V12.2437L28.8763 14.8794ZM21.4978 14.1826C22.2859 13.3818 21.7333 12.01 20.6267 12.0391C20.3082 12.0529 20.0178 12.1815 19.797 12.4249L15.443 16.9247C15.3185 17.0396 15.1259 17.0396 15.0148 16.9247L13.4252 15.2819C12.2919 14.054 10.5363 15.8683 11.7245 17.0396L14.5867 20.0114C14.9319 20.3681 15.5126 20.3681 15.8578 20.0114L21.4978 14.1826Z"
        fill="currentColor"
      />
    </svg>
  );
}
