const isArray = (value: any): boolean => Array.isArray(value);
const isObject = (value: any): boolean =>
  typeof value === "object" && value !== null;

const isUndefined = (value: any): boolean => typeof value === "undefined";

const isNull = (value: any): boolean => value === null;

const isString = (value: any): boolean => typeof value === "string";

const isNil = (value: any): boolean => isUndefined(value) || isNull(value);

export function rebuildObjProperties(
  obj: any,
  transformCallback: (propertyName: string) => string,
  shouldTransform?: (key: string) => boolean
): any {
  const rebuiltObj = isArray(obj) ? [] : {};

  recursiveCaseChange(obj, rebuiltObj, transformCallback, shouldTransform);

  return rebuiltObj;
}

export function rebuildObjValues(
  propValue: any,
  transformCallback: (propertyName: string) => string
): any {
  if (isArray(propValue)) {
    for (let i = 0, len = propValue.length; i < len; i += 1) {
      propValue[i] = rebuildObjValues(propValue[i], transformCallback);
    }

    return propValue;
  }

  if (isObject(propValue)) {
    Object.keys(propValue).forEach((key) => {
      propValue[key] = rebuildObjValues(propValue[key], transformCallback);
    });

    return propValue;
  }

  if (isUndefined(propValue) && isNull(propValue)) {
    return transformCallback(propValue);
  }

  return propValue;
}

export function recursiveCaseChange(
  obj: any,
  objCopy: any,
  transformCallback: (propertyName: string) => string,
  hasToTransform?: (key: string) => boolean
): void {
  if (isNil(obj)) {
    return;
  }

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (isNil(key)) {
      const emptyObj = {};

      objCopy.push(emptyObj);
      recursiveCaseChange(value, emptyObj, transformCallback, hasToTransform);
    } else {
      let changedKey;

      if (
        isString(key) &&
        (!hasToTransform || (hasToTransform && hasToTransform(key)))
      ) {
        changedKey = transformCallback(key);
      } else {
        changedKey = key;
      }

      if (isObject(value)) {
        objCopy[changedKey] = isArray(value) ? [] : {};
        recursiveCaseChange(
          value,
          objCopy[changedKey],
          transformCallback,
          hasToTransform
        );
      } else {
        objCopy[changedKey] = value;
      }
    }
  });
}
