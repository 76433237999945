export function debounce<T>(fn: (args: T) => void, ms = 200) {
  let timeout: ReturnType<typeof setTimeout>;

  const debouncedFn = (args: T): void => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(args);
    }, ms);
  };

  debouncedFn.cancel = () => clearTimeout(timeout);

  return debouncedFn;
}
