import { palette, theme } from "@pairtreefamily/ui";
import { alpha } from "@mui/material";

export const styles = {
  wrapper: {
    display: "flex",
    cursor: "pointer",
    paddingY: 1,
    gap: 4,
  },
  workspace: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    maxWidth: "320px",
  },
  workspaceLabel: {
    textTransform: "uppercase",
    color: alpha(palette.text.light, palette.action.focusOpacity),
    userSelect: "none",
  },
  workspaceValue: {
    color: palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  avatarWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  avatarContainer: {
    width: "48px",
    height: "48px",
    backgroundColor: palette.primary.main,
    gap: 1,
  },
  avatarArrow: (isToggled: boolean) => ({
    width: "24px",
    height: "24px",
    color: palette.success.light,
    transform: `scale(${isToggled ? -1 : 1})`,
  }),
};
