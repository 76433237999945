export function DisconnectLinkIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.83594 3.83594C6.17188 2.78125 8.09375 2.875 9.3125 4.09375C10.3203 5.10156 10.5547 6.625 9.99219 7.86719L11.0703 8.71094L13.2969 6.48438C14.1641 5.59375 14.1641 4.1875 13.2969 3.29688C12.4531 2.47656 11.1172 2.42969 10.2266 3.20312L10.0859 3.32031C9.85156 3.53125 9.5 3.50781 9.28906 3.27344C9.07812 3.03906 9.10156 2.6875 9.33594 2.47656L9.47656 2.35938C10.8125 1.1875 12.8281 1.25781 14.0938 2.5C15.4062 3.8125 15.4062 5.94531 14.0938 7.28125L11.9609 9.41406L15.2656 12.0156C15.5234 12.2031 15.5703 12.5547 15.3594 12.7891C15.1719 13.0469 14.8203 13.0938 14.5859 12.8828L0.710938 2.00781C0.453125 1.82031 0.40625 1.46875 0.617188 1.23438C0.804688 0.976562 1.15625 0.929688 1.39062 1.14062L4.83594 3.83594ZM5.77344 4.5625L9.07812 7.14062C9.3125 6.36719 9.125 5.5 8.51562 4.89062C7.76562 4.14062 6.61719 4.02344 5.77344 4.5625ZM6.66406 9.92969C5.96094 9.22656 5.63281 8.28906 5.67969 7.35156L6.94531 8.35938C7.0625 8.64062 7.22656 8.89844 7.46094 9.13281C7.8125 9.50781 8.28125 9.71875 8.75 9.76562L10.0156 10.7734C8.86719 11.125 7.57812 10.8438 6.66406 9.92969ZM3.21875 5.40625L4.10938 6.10938L2.67969 7.53906C1.8125 8.42969 1.8125 9.83594 2.67969 10.7266C3.52344 11.5469 4.85938 11.5938 5.75 10.8203L5.89062 10.7031C6.125 10.4922 6.47656 10.5156 6.6875 10.75C6.89844 10.9844 6.875 11.3359 6.64062 11.5469L6.5 11.6641C5.16406 12.8359 3.14844 12.7656 1.88281 11.5234C0.570312 10.2109 0.570312 8.05469 1.88281 6.74219L3.21875 5.40625Z"
        fill="currentColor"
      />
    </svg>
  );
}
