import { SVGProps } from "react";

export const DoneIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.36681 9.96435L11.9451 4.3861C12.097 4.23417 12.2773 4.1582 12.4858 4.1582C12.6944 4.1582 12.8747 4.23417 13.0266 4.3861C13.1785 4.53804 13.2545 4.71829 13.2545 4.92687C13.2545 5.13544 13.1785 5.31569 13.0266 5.46762L6.90122 11.593C6.74929 11.7449 6.57115 11.8209 6.36681 11.8209C6.16246 11.8209 5.98432 11.7449 5.83239 11.593L2.97369 8.73429C2.82176 8.58235 2.7479 8.4021 2.75212 8.19354C2.75636 7.98496 2.83444 7.8047 2.98637 7.65277C3.13831 7.50084 3.31856 7.42487 3.52714 7.42487C3.73571 7.42487 3.91596 7.50084 4.06789 7.65277L6.36681 9.96435Z"
        fill="currentColor"
      />
    </svg>
  );
};
