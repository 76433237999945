import { isDateExpiring } from "@shared/utils";
import { useTranslation } from "react-i18next";
import { appConfig } from "@lib";

export const useExpirationStatus = (expirationDate: Date | null) => {
  const { t } = useTranslation("components");

  if (!expirationDate) return null;

  if (
    isDateExpiring(appConfig.daysWhenTaskConsideredExpiring, expirationDate)
  ) {
    return t("statusElement.statuses.expiring");
  }

  if (expirationDate < new Date()) {
    return t("statusElement.statuses.expired");
  }

  return null;
};
