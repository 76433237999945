import { PendingInvitation } from "@api";
import { Box, Grid, Typography } from "@mui/material";
import { Button, palette } from "@pairtreefamily/ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export type PortalPendingInvitationsProps = {
  invitations?: PendingInvitation[];
  onResend: (invitation: PendingInvitation) => Promise<void>;
  onRevoke: (invitation: PendingInvitation) => Promise<void>;
  resendingInviteUuid: string | null;
  revokingInviteUuid: string | null;
};

const customBgButton = {
  "&:hover": {
    backgroundColor: palette.primary.backgroundDark,
  },
};

export function PortalPendingInvitations({
  invitations,
  onResend,
  onRevoke,
  resendingInviteUuid,
  revokingInviteUuid,
}: PortalPendingInvitationsProps) {
  const { t } = useTranslation("components");

  const isFamilyInvite = useMemo(() => {
    return invitations?.some(
      (invitation) => invitation.firstName && invitation.lastName
    );
  }, [invitations]);

  return (
    <Box mt={3}>
      {invitations?.map((invitation) => (
        <Box
          key={invitation.uuid}
          display="flex"
          justifyContent="space-between"
          bgcolor="primary.backgroundLight"
          px={2}
          border={`1px solid ${palette.divider}`}
          borderRight={0}
          borderLeft={0}
        >
          <Grid container spacing={2}>
            {!isFamilyInvite ? (
              <Grid item xs={2} display="flex">
                <Typography my="auto" variant="body4">
                  {t("portalPendingInvitations.email")}
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={2} display="flex">
                  <Typography my="auto" variant="body4">
                    {invitation.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={2} display="flex">
                  <Typography my="auto" variant="body4">
                    {invitation.lastName}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={isFamilyInvite ? 8 : 10} display="flex">
              <Typography my="auto" variant="body6">
                {invitation.emailAddress}
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" gap={3} py={3}>
            <Button
              sx={customBgButton}
              variant="text"
              onClick={() => onResend(invitation)}
              isLoading={resendingInviteUuid === invitation.uuid}
            >
              {t("portalPendingInvitations.resend")}
            </Button>

            <Button
              sx={customBgButton}
              variant="text"
              onClick={() => onRevoke(invitation)}
              isLoading={revokingInviteUuid === invitation.uuid}
            >
              {t("portalPendingInvitations.revoke")}
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
