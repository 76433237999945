import { FC, ReactNode, useMemo } from "react";

import { useGetUserOrganizationsList } from "./hooks";

import { OrganizationsContext } from "./context";

export const OrganizationsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const {
    data: organizationsList,
    isLoading: isOrganizationsListLoading,
    activeOrganizationId,
    setActiveOrganizationId,
  } = useGetUserOrganizationsList();

  const activeOrganization = useMemo(() => {
    return (
      organizationsList?.find((org) => org.id === activeOrganizationId) ?? null
    );
  }, [activeOrganizationId, organizationsList]);

  const providerValue = useMemo(
    () => ({
      organizationsListData: organizationsList,
      isOrganizationsListLoading,
      userOrganizationData: activeOrganization,
      isUserOrganizationDataLoading: isOrganizationsListLoading,
      setActiveOrganizationId,
    }),
    [
      isOrganizationsListLoading,
      organizationsList,
      setActiveOrganizationId,
      activeOrganization,
    ]
  );

  return (
    <OrganizationsContext.Provider value={providerValue}>
      {children}
    </OrganizationsContext.Provider>
  );
};
