import { ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "next-i18next";
import {
  AccordionContainer,
  AccordionDetails,
  AccordionSummary,
  BellIcon,
  SettingsGearIcon,
  XMarkIcon,
  Tooltip,
  Button,
} from "@pairtreefamily/ui";
import { ExtendedTaskGroup, Task, TaskGroup } from "@_types/*";
import { TaskTable } from "./TaskTable";
import { TaskGroupHeader } from "@shared/components";
import { LinearProgressBar } from "../../progress-bar";
import { isDateExpiring } from "@shared/utils";
import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import { appConfig } from "@lib";
import { getTaskGroupCompletedPercent } from "./utils";
import Markdown from "../../Markdown";
import { TruncableText } from "@shared/components";

export type TaskGroupAccordionProps = {
  taskGroup: ExtendedTaskGroup;
  row?: (
    task: Task,
    rowNum: number
  ) => {
    column1?: ReactNode;
    column2?: ReactNode;
    column3?: ReactNode;
    column4?: ReactNode;
  };
  deleteTaskGroup?: (taskGroup: TaskGroup) => void;
  createReferenceTask?: (taskGroupId: string) => void;
  updatedTasksOrder?: (newOrder: string[], taskGroup: TaskGroup) => void;
  allowReorder?: boolean;
  openTaskManagerModal?: (taskGroup: TaskGroup) => void;
  openTaskGroupModal?: (open: boolean, taskGroup: TaskGroup) => void;
};

const referencesTaskGroupRgx = /.*personal references.*/i;

export function TaskGroupAccordion(props: TaskGroupAccordionProps) {
  const {
    taskGroup,
    deleteTaskGroup,
    createReferenceTask,
    row,
    updatedTasksOrder,
    allowReorder,
    openTaskManagerModal,
    openTaskGroupModal,
  } = props;

  const { t } = useTranslation("components");

  const handleOpenTaskGroupModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      openTaskGroupModal?.(true, taskGroup);
    },
    [openTaskGroupModal, taskGroup]
  );

  const getExpiringAndExpiredTasks = useMemo(() => {
    return taskGroup.tasks.filter(
      (item) =>
        (item.expirationDate && item.expirationDate < new Date()) ||
        isDateExpiring(
          appConfig.daysWhenTaskConsideredExpiring,
          item.expirationDate
        )
    ).length;
  }, [taskGroup]);

  return (
    <AccordionContainer>
      <AccordionSummary>
        <div className="flex w-full flex-row items-center">
          <div className="flex w-full grow items-center pr-2">
            <TaskGroupHeader>
              <Box display="flex" gap={2}>
                <Typography
                  component="h2"
                  variant="head5"
                  color={taskGroup.isHidden ? "text.disabled" : "text.primary"}
                >
                  {taskGroup.title}
                </Typography>
              </Box>

              <Box sx={styles.taskGroupBellContainer}>
                {getExpiringAndExpiredTasks > 0 && (
                  <Box>
                    <Typography variant="alerts">
                      <Box sx={styles.counter}>
                        {getExpiringAndExpiredTasks}
                      </Box>
                    </Typography>

                    <BellIcon />
                  </Box>
                )}

                <Box sx={styles.progressBar}>
                  <LinearProgressBar
                    percentComplete={getTaskGroupCompletedPercent(taskGroup)}
                  />
                </Box>
              </Box>
            </TaskGroupHeader>
          </div>
          {openTaskGroupModal && (
            <Tooltip title={t("taskGroupAccordion.tooltipTaskGroupSettings")}>
              <button
                className="mr-4 grow-0 text-onyx-light"
                onClick={handleOpenTaskGroupModal}
              >
                <SettingsGearIcon />
              </button>
            </Tooltip>
          )}

          {deleteTaskGroup && (
            <button onClick={() => deleteTaskGroup(taskGroup)}>
              <XMarkIcon className="h-6 text-rust hover:scale-110" />
            </button>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box mt={2}>
          <TruncableText>
            <Typography variant="body2">
              <Markdown content={taskGroup.taskGroupInstruction ?? ""} />
            </Typography>
          </TruncableText>

          {Boolean(taskGroup.tasks.length) && (
            <>
              <TaskTable
                allowReorder={allowReorder}
                taskGroup={taskGroup}
                row={row}
                updatedTasksOrder={(newOrder) =>
                  updatedTasksOrder && updatedTasksOrder(newOrder, taskGroup)
                }
              />

              {createReferenceTask &&
              referencesTaskGroupRgx.test(taskGroup.title) ? (
                <div className="flex justify-center">
                  <Button
                    onClick={() => createReferenceTask(taskGroup.id)}
                    color="error"
                    variant="outlined"
                  >
                    {t("taskGroupView.accordion.addReference")}
                  </Button>
                </div>
              ) : (
                openTaskManagerModal && (
                  <button
                    className="text-green"
                    onClick={() => {
                      openTaskManagerModal && openTaskManagerModal(taskGroup);
                    }}
                  >
                    <strong>{t("taskGroupView.accordion.addTask")}</strong>
                  </button>
                )
              )}
            </>
          )}
        </Box>

        {!Boolean(taskGroup.tasks.length) && (
          <Box mt={4}>
            <Typography variant="body4">
              {t("taskGroupView.noTasks")}
            </Typography>
          </Box>
        )}
      </AccordionDetails>
    </AccordionContainer>
  );
}

export default TaskGroupAccordion;
