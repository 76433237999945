import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactNode } from "react";

export type DragAndDropList = { id: string; element: ReactNode };

export type DragAndDropProps = {
  onSort: (newOrder: string[]) => void;
  className?: string;
  itemsList: Array<DragAndDropList>;
};

export function DragAndDrop(props: DragAndDropProps) {
  const items = props.itemsList;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over?.id);
      const newArray = arrayMove(items, oldIndex, newIndex);
      props.onSort(newArray.map((item) => item.id));
      return newArray;
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext strategy={verticalListSortingStrategy} items={items}>
        {items.map((item) => item.element)}
      </SortableContext>
    </DndContext>
  );
}

export type SortableItemProps = {
  key: string;
  id: string;
  className?: string;
  children: (props: {
    style: { transform?: string; transition?: string };
    attributes: any;
    listeners: any;
    setNodeRef: (node: HTMLElement | null) => void;
  }) => ReactNode;
};

export function SortableItem(props: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return <>{props.children({ setNodeRef, listeners, attributes, style })}</>;
}

export function Handle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
      />
    </svg>
  );
}
