import { useMemo } from "react";
import { StatusIcon, StatusType } from "@pairtreefamily/ui";

import { ErrorStatus } from "./components/ErrorStatus";
import { ExpiredElement } from "./components/ExpiredElement";
import { useCurrentStatus } from "./hooks/useCurrentStatus";

import type { Task } from "@_types/tasks";
import { useExpirationStatus } from "./hooks/useExpirationStatus";

export type TaskStatusElementProps = {
  task: Task;
  isFamily?: boolean;
  shouldDisplayExpiration?: boolean;
};

const className = "flex items-center justify-start text-body-4 font-bold";

export function TaskStatusElement({
  task,
  isFamily,
  shouldDisplayExpiration = true,
}: TaskStatusElementProps) {
  const expiredStatus = useExpirationStatus(task.expirationDate);
  const statusConfig = useCurrentStatus(task.status, isFamily);

  const { expiredIconType, isExpiredElementDisplayed } = useMemo(() => {
    const expiredIconType: StatusType =
      expiredStatus === "Expired"
        ? "error"
        : expiredStatus === "Expiring"
        ? "warning"
        : "neutral";

    const isExpiredElementDisplayed =
      shouldDisplayExpiration &&
      (["completed", "needsReview"].includes(task.status) || expiredStatus);

    return { expiredIconType, isExpiredElementDisplayed };
  }, [task.status, shouldDisplayExpiration, expiredStatus]);

  if (task?.documentIssueStatus === "error") {
    return (
      <>
        <ErrorStatus error={task.documentIssueError} />

        {isExpiredElementDisplayed && (
          <ExpiredElement
            expirationDateRequired={task.expirationDateRequired}
            expirationDate={task.expirationDate}
          />
        )}
      </>
    );
  }

  return (
    <>
      {statusConfig && (
        <div className={className}>
          <StatusIcon
            status={
              shouldDisplayExpiration && expiredStatus
                ? expiredIconType
                : statusConfig.iconType
            }
            filled={
              (shouldDisplayExpiration && expiredStatus !== null) ||
              task.status !== "notStarted"
            }
          />
          {shouldDisplayExpiration && expiredStatus
            ? expiredStatus
            : statusConfig.text}
        </div>
      )}

      {isExpiredElementDisplayed && (
        <ExpiredElement
          expirationDateRequired={task.expirationDateRequired}
          expirationDate={task.expirationDate}
        />
      )}
    </>
  );
}
