import { useCallback, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Box } from "@mui/material";
import { Button, SidePanel } from "@pairtreefamily/ui";
import { Separator } from "../Separator";
import {
  ElectronicDocumentFields,
  ReferenceTaskFields,
  FileUploadTaskFields,
  CommonTaskFields,
  CompletionNotificationsFields,
  ConditionalQuestionFields,
  TaskStatus,
} from "./components";

import { defaultTaskValues } from "./constants";
import { updateFormValues, updateShouldBeSentToPrivateFolder } from "./utils";
import { useOrderNumber } from "./hooks";
import {
  validationSchema,
  validationSchemaWithExpirationDateRequired,
} from "./validation-schema";

import type { TaskType } from "@_types/*";
import type { TaskManagementSidePanelProps } from "./types";
import type { FormValues } from "./validation-schema";

export function TaskManagementSidePanel(props: TaskManagementSidePanelProps) {
  const {
    task,
    taskGroup,
    allTaskGroups,
    templatesList,
    caseLoginUsers,
    isAdmin,
    isOpen: isModalOpened,
    createUpdateTask,
    onClose,
  } = props;

  const { t } = useTranslation(["common", "components"]);

  const formMethods = useForm<FormValues>({
    mode: "onChange",
    defaultValues: defaultTaskValues,
    resolver: (data, ...args) => {
      if (data.expirationDateRequired) {
        return zodResolver(validationSchemaWithExpirationDateRequired)(
          data,
          ...args
        );
      }

      return zodResolver(validationSchema)(data, ...args);
    },
  });

  const {
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = formMethods;

  const [
    taskGroupId,
    taskType,
    templateUuid,
    expirationDate,
    areNotificationsEnabled,
  ] = watch([
    "taskGroupId",
    "taskType",
    "templateUuid",
    "expirationDate",
    "areNotificationsEnabled",
  ]);

  const orderNumber = useOrderNumber({ task, taskGroupId, allTaskGroups });

  useEffect(() => {
    if (expirationDate?.getDate && !errors.expirationDate) {
      const currentStatus = getValues("status");
      if (currentStatus === "needsReview") {
        setValue("status", "completed");
      }
    }
  }, [expirationDate, errors.expirationDate, setValue, getValues]);

  useEffect(() => {
    const updatedValues = updateFormValues(task, taskGroup);
    reset(updatedValues);
  }, [task, taskGroup, reset]);

  const handleTaskTypeChange = useCallback(
    (nextTaskType: TaskType) => {
      const isPrivate = updateShouldBeSentToPrivateFolder(
        nextTaskType,
        taskGroup
      );
      setValue("isPrivate", isPrivate);
    },
    [taskGroup, setValue]
  );

  const handleModalClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const handleSubmitForm = useCallback(
    async (values: FormValues) => {
      await createUpdateTask({
        ...values,
        orderNumber,
        taskId: task?.id,
        isLocked: task?.isLocked ?? false,
      });

      handleModalClose();
    },
    [orderNumber, task?.id, createUpdateTask, handleModalClose, task?.isLocked]
  );

  const modalTitle = task
    ? task.title
    : t("components:taskManagerModal.titles.createTask");

  return (
    <SidePanel<FormValues>
      open={isModalOpened}
      title={modalTitle}
      onClose={handleModalClose}
      formMethods={formMethods}
      onSubmit={handleSubmitForm}
      actions={
        <>
          <Button variant="outlined" type="button" onClick={handleModalClose}>
            {t("common:actions.cancel")}
          </Button>

          <Button
            type="submit"
            isLoading={formMethods.formState.isSubmitting}
            disabled={
              !formMethods.formState.isDirty ||
              formMethods.formState.isSubmitting
            }
          >
            {t("common:actions.save")}
          </Button>
        </>
      }
    >
      {task && <TaskStatus task={task} />}

      <Box sx={{ pb: 8 }}>
        <CommonTaskFields
          task={task}
          taskType={taskType}
          taskGroup={task?.TaskGroup ?? taskGroup}
          allTaskGroups={allTaskGroups}
          isAdmin={isAdmin}
          onTaskTypeChange={handleTaskTypeChange}
        />

        {taskType === "electronicDocument" && (
          <>
            <Separator className="mt-8 mb-8" />

            <ElectronicDocumentFields
              task={task}
              templatesList={templatesList}
              templateUuid={templateUuid}
              isAdmin={isAdmin}
            />
          </>
        )}

        {taskType === "fileUpload" && <FileUploadTaskFields />}

        {taskType === "reference" && (
          <ReferenceTaskFields task={task} isAdmin={props.isAdmin} />
        )}

        <Separator className="mt-8 mb-8" />

        <CompletionNotificationsFields
          task={task}
          enabled={areNotificationsEnabled}
          caseLoginUsers={caseLoginUsers}
        />

        <Separator className="mt-8 mb-4" />

        <ConditionalQuestionFields
          status={task?.status}
          isAdmin={props.isAdmin}
        />
      </Box>
    </SidePanel>
  );
}
