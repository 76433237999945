import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "next-i18next";
import {
  Modal,
  InfoIconFilled,
  Button,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { TaskTitle } from "./TaskTitle";
import { TaskDescription } from "./TaskDescription";
import { ElectronicDocumentTask, Task, TaskAssignment } from "@_types/*";
import { TaskStatusElement } from "./task-status-element";
import { TaskQuestionStatus } from "./TaskQuestionStatus";
import { PandaDocIframe } from "./PandaDocEmbed";
import { appConfig } from "@lib";
import { RefreshTask } from "./refresh-task";
import { UseProfessionalPageTools } from "../../features/professional/hooks/useProfessionalTools";
import { Result } from "@pairtreefamily/utils";
import { UseFileUpload } from "@shared/hooks";
import { Box, Typography } from "@mui/material";

export type IssueeElectronicDocumentRowProps = {
  task: ElectronicDocumentTask;
  answer: boolean | null;
  handleSetAnswer: (e: boolean | null) => void;
  editTask?: (task: Task) => void;
  onSave: () => Promise<void>;
  refreshElectronicDocumentSession: (
    assignment: TaskAssignment
  ) => Promise<Result<TaskAssignment, unknown>>;
};

export function IssueeElectronicDocumentRow(
  props: IssueeElectronicDocumentRowProps
) {
  const {
    task,
    answer,
    handleSetAnswer,
    editTask,
    onSave,
    refreshElectronicDocumentSession,
  } = props;
  const { taskAssignments } = task;

  const { t } = useTranslation("components");

  useState<boolean>(false);
  const [currentTaskAssignment, setCurrentTaskAssignment] =
    useState<TaskAssignment | null>(null);
  const [pdfTitle, setPdfTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTaskAssignmentButtonLoading, setIsTaskAssignmentButtonLoading] =
    useState(false);
  const { callSnackbar } = useSnackbarNotification();

  const handleOpenPdfModal = useCallback(
    (args: { title: string; taskAssignment: TaskAssignment }) => {
      setPdfTitle(args.title);
      setIsModalOpen(true);
      setCurrentTaskAssignment(args.taskAssignment);
    },
    []
  );

  const onTaskAssignmentButtonClick = useCallback(
    (args: { title: string; taskAssignment: TaskAssignment }) => {
      // if the session is valid (not expired) we can open the window
      if (isSessionValid(args.taskAssignment.pandadocSession)) {
        handleOpenPdfModal(args);
        return;
      }

      setIsTaskAssignmentButtonLoading(true);
      refreshElectronicDocumentSession(args.taskAssignment).then((res) => {
        setIsTaskAssignmentButtonLoading(false);
        if (!res.ok) {
          callSnackbar({
            message: t("issuerElectronicDocumentRow.buttons.edit"),
            type: "error",
          });
          return;
        }
        const ta = res.content;
        handleOpenPdfModal({ title: args.title, taskAssignment: ta });
      });
    },
    [handleOpenPdfModal, refreshElectronicDocumentSession, callSnackbar, t]
  );

  return {
    column1: (
      <TaskTitle task={task} answer={answer} onAnswer={handleSetAnswer} />
    ),
    column2: <TaskDescription task={task} />,
    column3: task.question ? (
      <TaskQuestionStatus task={task} isFamily />
    ) : (
      <TaskStatusElement task={task} isFamily />
    ),
    column4: (
      <>
        {task.documentIssueStatus !== "error" && (
          <div className="flex flex-col gap-4">
            {task.TaskGroup?.providerOnly && (
              <Button
                color="error"
                variant="outlined"
                onClick={() => editTask && editTask(task)}
              >
                {t("issuerElectronicDocumentRow.buttons.edit")}
              </Button>
            )}
            {taskAssignments.map((assignment, i) => {
              let label;
              let disabled = task?.isLocked;
              if (task.status === "completed") {
                if (task.answer === false) {
                  //if the question was answered as NO, then the family doesn't need to fill anything, but we still need to show the label fill out (but with the button disabled)
                  label = t("issueeElectronicDocumentRow.labels.fillOut");
                } else {
                  //if the answer is not NO, that means, true or undefined. So if its true, and the task is compled, then show as View. And if the anwer is undefined, that means that there is no question for this task
                  label = t("issueeElectronicDocumentRow.labels.view");
                  disabled = false;
                }
              } else {
                // if there's more than 1 person assigned, be specific about who should fill this out
                if (taskAssignments.length > 1) {
                  label = t("issueeElectronicDocumentRow.labels.fillOutFor", {
                    firstName: assignment.assignee.firstName,
                  });
                } else {
                  label = t(
                    `issueeElectronicDocumentRow.labels.${
                      task.status === "needsReview" ? "view" : "fillOut"
                    }`
                  );
                  disabled = task.isLocked && task.status !== "needsReview";
                }
              }

              const assigneeSessionId = assignment.pandadocSession?.sessionId;

              if (!assigneeSessionId) {
                // TODO set error state to true, then render this message if
                // the error state is true. This is blocked by this not being
                // it's own component - an easy refactor, but out of scope
                // for what I'm doing right now.
                return null;
              }
              //since the view button opens the same document
              // when is 2 signers and
              // the document is complete
              // we are displaying the button only for the first assignee
              if (i > 0 && task.status === "completed") {
                return null;
              }
              return (
                <Fragment key={assignment.id}>
                  <Button
                    variant="outlined"
                    isLoading={isTaskAssignmentButtonLoading}
                    onClick={() => {
                      onTaskAssignmentButtonClick({
                        title: task.title,
                        taskAssignment: assignment,
                      });
                    }}
                    disabled={false}
                  >
                    {label}
                  </Button>

                  {isModalOpen && (
                    <Modal
                      title={pdfTitle}
                      // TODO componentize assignment render and include modal/state there
                      open={
                        isModalOpen &&
                        currentTaskAssignment !== null &&
                        assignment.id === currentTaskAssignment.id
                      }
                      submitButton={
                        currentTaskAssignment?.status !== "completed"
                          ? {
                              label: t(
                                "issueeElectronicDocumentRow.labels.saveProgress"
                              ),
                              onClick: async () => {
                                await onSave();
                                setIsModalOpen(false);
                              },
                            }
                          : undefined
                      }
                      onClose={() => {
                        setIsModalOpen(false);
                      }}
                    >
                      <>
                        {assigneeSessionId && (
                          <PandaDocIframe
                            sessionUuid={assigneeSessionId}
                            onDocumentCompleted={() => {
                              setCurrentTaskAssignment(null);
                            }}
                          />
                        )}
                        {currentTaskAssignment?.status === "completed" && (
                          <div className="flex flex-col content-center">
                            <p className="my-8">
                              {task?.taskAssignments.some(
                                (item) => item.status !== "completed"
                              )
                                ? t(
                                    "issueeElectronicDocumentRow.documentStatus.yourPortionCompleted"
                                  )
                                : t(
                                    "issueeElectronicDocumentRow.documentStatus.allCompleted"
                                  )}
                            </p>
                            <Button
                              onClick={() => {
                                setIsModalOpen(false);
                              }}
                            >
                              {t("issueeElectronicDocumentRow.closeButton")}
                            </Button>
                          </div>
                        )}
                      </>
                    </Modal>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
        {task.documentIssueStatus === "error" && (
          <div>
            {t("issueeElectronicDocumentRow.errors.documentIssue")}
            <a href={`mailto:${appConfig.companyContactEmail}`}>
              {appConfig.companyContactEmail}
            </a>
          </div>
        )}
      </>
    ),
  };
}

export type IssuerElectronicDocumentRowProps = {
  task: ElectronicDocumentTask;
  setTaskManagerModalOpen: (open: boolean) => void;
  setCurrentTaskBeingManaged: (task: ElectronicDocumentTask) => void;
  issueDocument: (task: Task) => Promise<unknown>; //TODO there's a real type, it's just not needed at the moment
  refreshTask: UseProfessionalPageTools["refreshTask"];
  viewDocumentsLink?: string | null;
  getFileUrl?: (fileId: string) => Promise<Result<string, unknown>>;
  getFileName?: UseFileUpload["getFileName"];
  refreshElectronicDocumentSession: (
    assignment: TaskAssignment
  ) => Promise<Result<TaskAssignment, unknown>>;
};

export function IssuerElectronicDocumentRow(
  props: IssuerElectronicDocumentRowProps
) {
  const {
    task,
    setTaskManagerModalOpen,
    setCurrentTaskBeingManaged,
    issueDocument,
    refreshTask,
    viewDocumentsLink,
    getFileUrl,
    getFileName,
    refreshElectronicDocumentSession,
  } = props;

  const { t } = useTranslation("components");

  const { status, taskAssignments } = task;
  const [pdfTitle, setPdfTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // if assignees get their own component, this should be moved as well
  const [confirmTaskReissueOpened, setConfirmTaskReissueOpened] =
    useState<Task | null>(null);
  const [isTaskAssignmentButtonLoading, setIsTaskAssignmentButtonLoading] =
    useState(false);
  const { callSnackbar } = useSnackbarNotification();

  const handleOpenPdfModal = useCallback((args: { title: string }) => {
    setPdfTitle(args.title);
    setIsModalOpen(true);
  }, []);

  const onTaskAssignmentButtonClick = useCallback(
    (args: { title: string; taskAssignment: TaskAssignment }) => {
      // if the session is valid (not expired) we can open the window
      if (isSessionValid(args.taskAssignment.pandadocSession)) {
        handleOpenPdfModal(args);
        return;
      }

      setIsTaskAssignmentButtonLoading(true);
      refreshElectronicDocumentSession(args.taskAssignment).then((res) => {
        setIsTaskAssignmentButtonLoading(false);
        if (!res.ok) {
          callSnackbar({
            message: t("issuerElectronicDocumentRow.buttons.edit"),
            type: "error",
          });
          return;
        }

        const ta = res.content;
        handleOpenPdfModal({ title: args.title });
      });
    },
    [handleOpenPdfModal, refreshElectronicDocumentSession, callSnackbar, t]
  );

  // sorting by oldest expiration date first because this (issuer) modal only
  // needs an arbitrary session, not a specific one. TODO consolidate the two
  // ElectronicDocumentRows to use the same flow for rendering pandadoc session
  // modal
  const taskAssignmentWithSession = taskAssignments
    .sort(
      (a, b) =>
        (b.pandadocSession?.expiresAt.getTime() ?? 0) -
        (a.pandadocSession?.expiresAt.getTime() ?? 0)
    )
    .reduce((acc: TaskAssignment | null, cur: TaskAssignment) => {
      if (acc !== null) {
        return acc;
      }

      if (cur.pandadocSession !== null) {
        return cur;
      }

      return acc;
    }, null);

  const hasExistingSession = !!taskAssignmentWithSession;

  const hasBeenIssued =
    hasExistingSession || status == "completed" || status === "sent";

  return {
    column1: (
      <TaskTitle
        getFileName={getFileName}
        viewDocumentsLink={viewDocumentsLink}
        getFileUrl={getFileUrl}
        task={task}
        shouldDisplayViewDocumentsLink={true}
      />
    ),
    column2: <TaskDescription task={task} />,
    column3: task.question ? (
      <TaskQuestionStatus task={task} />
    ) : (
      <TaskStatusElement task={task} />
    ),
    column4: (
      <div className="align-center flex flex-col justify-center gap-4">
        {hasExistingSession ? (
          <Button
            isLoading={isTaskAssignmentButtonLoading}
            onClick={() =>
              onTaskAssignmentButtonClick({
                title: task.title,
                taskAssignment: taskAssignmentWithSession,
              })
            }
          >
            {t("issuerElectronicDocumentRow.labels.view")}
          </Button>
        ) : (
          <p>{t("buttonView.noSessionIdLabel")}</p>
        )}

        {isModalOpen && (
          <Modal
            title={pdfTitle}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            <>
              <PandaDocIframe
                sessionUuid={
                  taskAssignmentWithSession?.pandadocSession?.sessionId ?? ""
                }
              />
            </>
          </Modal>
        )}
        {task.templateUuid && !hasBeenIssued && (
          <Button
            variant="outlined"
            onClick={() => {
              issueDocument(task);
            }}
          >
            {t("issuerElectronicDocumentRow.buttons.send")}
          </Button>
        )}
        {task.templateUuid && hasBeenIssued && (
          <Button
            variant="outlined"
            onClick={() => {
              setConfirmTaskReissueOpened(task);
            }}
          >
            {t("issuerElectronicDocumentRow.buttons.reissue")}
          </Button>
        )}
        <Button
          color="error"
          variant="outlined"
          onClick={async () => {
            setTaskManagerModalOpen(true);
            setCurrentTaskBeingManaged(task);
          }}
        >
          {t("issuerElectronicDocumentRow.buttons.edit")}
        </Button>
        <RefreshTask
          task={task}
          refreshTask={refreshTask}
          expirationDate={task.expirationDate}
        />
        <Modal
          title={t(
            "issuerElectronicDocumentRow.labels.reissueDocumentModalTitle"
          )}
          open={!!confirmTaskReissueOpened}
          onClose={() => {
            setConfirmTaskReissueOpened(null);
          }}
          submitButton={{
            label: t("issuerElectronicDocumentRow.labels.proceed"),
            onClick: async () => {
              confirmTaskReissueOpened &&
                issueDocument(confirmTaskReissueOpened);
              setConfirmTaskReissueOpened(null);
              return null;
            },
          }}
        >
          <Box pt={3} sx={{ display: "flex", gap: 3 }}>
            <Box color="secondary.main">
              <InfoIconFilled />
            </Box>

            <Typography maxWidth="650px" variant="input" component="div">
              {t("issuerElectronicDocumentRow.reissueQuestion")}
            </Typography>
          </Box>
        </Modal>
      </div>
    ),
  };
}

function isSessionValid(pandadocSession?: { expiresAt: Date } | null): boolean {
  if (!pandadocSession) {
    return false;
  }

  return pandadocSession.expiresAt.getTime() >= new Date().getTime();
}
