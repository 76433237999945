import { useCallback, useContext, useState } from "react";
import { InviteFamilySidePanel } from "../invite-family-side-panel";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { ContentWrapper, PortalPendingInvitations } from "@shared/components";
import {
  PendingInvitation,
  useResendOrganizationInvite,
  useRevokeInvite,
} from "@api";
import { FamilyPendingInvitationsContext } from "./FamilyPendingInvitationsProvider";
import { useSnackbarNotification } from "@pairtreefamily/ui";

export function PendingInvitations() {
  const [isInviteFamilySideOpened, setIsInviteFamilySideOpened] =
    useState(false);
  const { t } = useTranslation("connect");

  const { familyInvitations } = useContext(FamilyPendingInvitationsContext);

  const revokeInvite = useRevokeInvite();
  const resendInvite = useResendOrganizationInvite();
  const { callSnackbar } = useSnackbarNotification();

  const [resendingInviteUuid, setResendingInviteUuid] = useState<string | null>(
    null
  );

  const [revokingInviteUuid, setRevokingInviteUuid] = useState<string | null>(
    null
  );

  const handleResend = useCallback(
    async (invitation: PendingInvitation) => {
      try {
        setResendingInviteUuid(invitation.uuid);
        await resendInvite({ inviteUuid: invitation.uuid });

        callSnackbar({
          message: t("familyListing.inviteFamily.famSuccessAssociated", {
            email: invitation.emailAddress,
          }),
        });
        setResendingInviteUuid(null);
      } catch (error) {
        callSnackbar({
          message: t("familyListing.inviteFamily.famErrorAssociated", {
            email: invitation.emailAddress,
          }),
          type: "error",
        });
      }
    },
    [callSnackbar, resendInvite, t]
  );

  const handleRevoke = useCallback(
    async (invitation: PendingInvitation) => {
      try {
        setRevokingInviteUuid(invitation.uuid);
        await revokeInvite({ inviteUuid: invitation.uuid });
        callSnackbar({
          message: t("familyListing.inviteFamily.famSuccessRevoke", {
            email: invitation.emailAddress,
          }),
          type: "info",
        });
        setRevokingInviteUuid(null);
      } catch (error) {
        callSnackbar({
          message: t("familyListing.inviteFamily.famErrorRevoke", {
            email: invitation.emailAddress,
          }),
          type: "error",
        });
      }
    },
    [callSnackbar, revokeInvite, t]
  );
  return (
    <ContentWrapper>
      <Box display="flex" justifyContent="flex-end">
        <Button
          startIcon={<AddIcon />}
          onClick={() => setIsInviteFamilySideOpened(true)}
        >
          {t("familyListing.heading.inviteFamilyBtn")}
        </Button>
      </Box>

      <PortalPendingInvitations
        invitations={familyInvitations}
        onResend={handleResend}
        onRevoke={handleRevoke}
        resendingInviteUuid={resendingInviteUuid}
        revokingInviteUuid={revokingInviteUuid}
      />

      <InviteFamilySidePanel
        isOpen={isInviteFamilySideOpened}
        onClose={() => setIsInviteFamilySideOpened(false)}
      />
    </ContentWrapper>
  );
}
