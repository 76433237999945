import { useTranslation } from "next-i18next";
import { StatusIcon } from "@pairtreefamily/ui";

import { TaskStatusElement } from "@shared/components";

import type {
  ElectronicDocumentTask,
  FileUploadTask,
  ReferenceTask,
} from "@_types/tasks";

type QuestionStatusProps = {
  task: ElectronicDocumentTask | FileUploadTask | ReferenceTask;
  isFamily?: boolean;
};

const className =
  "flex items-center justify-start text-lightgray text-body-4 font-bold";

export function TaskQuestionStatus({ task, isFamily }: QuestionStatusProps) {
  const { t } = useTranslation("components");

  if (task.answer === false) {
    // Explicit false implies FAM has answered 'no'
    return (
      <div className={className}>
        <StatusIcon status="neutral" />
        {t("questionStatus.notApplicable")}
      </div>
    );
  }
  // This covers the cases where:
  // FAM has answered 'yes' (task.status === True)
  // FAM has not yet answered 'yes' or 'no' (task.status === null)
  return <TaskStatusElement task={task} isFamily={isFamily} />;
}
