import type { SVGProps } from "react";

export const ProfileLiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.925 11.225L9.5 9.8C9.4 9.7 9.29167 9.625 9.175 9.575C9.05833 9.525 8.9375 9.5 8.8125 9.5C8.6875 9.5 8.5625 9.525 8.4375 9.575C8.3125 9.625 8.2 9.7 8.1 9.8C7.91667 9.98333 7.825 10.2167 7.825 10.5C7.825 10.7833 7.91667 11.0167 8.1 11.2L10.225 13.35C10.425 13.55 10.6583 13.65 10.925 13.65C11.1917 13.65 11.425 13.55 11.625 13.35L16.275 9.1C16.275 8.83333 16.2583 8.575 16.225 8.325C16.1917 8.075 16.0833 7.85833 15.9 7.675C15.7 7.475 15.4625 7.375 15.1875 7.375C14.9125 7.375 14.675 7.475 14.475 7.675L10.925 11.225ZM1 21V19H22C22.2833 19 22.5208 19.0958 22.7125 19.2875C22.9042 19.4792 23 19.7167 23 20C23 20.2833 22.9042 20.5208 22.7125 20.7125C22.5208 20.9042 22.2833 21 22 21H1ZM4 18C3.45 18 2.97917 17.8042 2.5875 17.4125C2.19583 17.0208 2 16.55 2 16V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H4ZM4 16H20V5H4V16Z"
      fill="currentColor"
    />
  </svg>
);
