export const CheckedIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#44978D" />
      <path
        d="M7.84753 11.6457L14.1973 5.29596C14.3946 5.09865 14.6457 5 14.9507 5C15.2556 5 15.5067 5.09865 15.704 5.29596C15.9013 5.49327 16 5.74439 16 6.04933C16 6.35426 15.9013 6.60538 15.704 6.80269L8.6009 13.9058C8.38565 14.1211 8.13453 14.2287 7.84753 14.2287C7.56054 14.2287 7.30942 14.1211 7.09417 13.9058L4.29596 11.1076C4.09865 10.9103 4 10.6592 4 10.3543C4 10.0493 4.09865 9.79821 4.29596 9.6009C4.49327 9.40359 4.74439 9.30493 5.04933 9.30493C5.35426 9.30493 5.60538 9.40359 5.80269 9.6009L7.84753 11.6457Z"
        fill="white"
      />
    </svg>
  );
};

export const Icon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#44978D" />
    </svg>
  );
};
