import { trpc, useCoreClient } from "@api";
import { useMutation, useQuery } from "react-query";
import { Organization, OrganizationMember } from "../shared/types";
import { useOrganizationsData, useUserData } from "@shared/components";

export const useGetOrganization = () => {
  const { CoreClient } = useCoreClient();
  const { userOrganizationData } = useOrganizationsData();
  const { userData } = useUserData();

  return useQuery(
    `get-organization-${userOrganizationData?.id}`,
    () => CoreClient.getOrganization(userOrganizationData?.id),
    {
      enabled: !!userOrganizationData?.id && !!userData,
    }
  );
};

export const useGetOrganizationMembersList = () => {
  const { CoreClient } = useCoreClient();
  const { userOrganizationData } = useOrganizationsData();

  return useQuery(
    `get-organization-members-list-${userOrganizationData?.id}`,
    () => CoreClient.getOrganizationMembersList(userOrganizationData?.id),
    {
      enabled: !!userOrganizationData?.id,
    }
  );
};

export const useUpdateOrganizationPublishStatus = () => {
  const { CoreClient } = useCoreClient();
  const { data } = useGetOrganization();

  const { mutateAsync } = useMutation((args: { isPublished: boolean }) =>
    CoreClient.updateOrganization({ ...(data as Organization), ...args })
  );

  return mutateAsync;
};

export const useUpdateOrganization = () => {
  const { CoreClient } = useCoreClient();
  const { data, refetch } = useGetOrganization();
  const { homeStudy } = trpc.useContext();

  const { mutateAsync } = useMutation(
    (args: Organization) =>
      CoreClient.updateOrganization({ ...args, uuid: data?.uuid ?? "" }),
    {
      onSuccess: () => {
        refetch();
        homeStudy.getMyProDetails.invalidate();
      },
    }
  );
  return mutateAsync;
};
export const useGetPendingInvites = () => {
  const { CoreClient } = useCoreClient();
  const { data } = useGetOrganization();
  const uuid = data?.uuid ?? "";

  return useQuery(
    `get-pending-invites-${data?.uuid}`,
    () => CoreClient.getPendingInvites({ uuid }),
    {
      enabled: !!uuid,
    }
  );
};

export const useGetFamPendingInvites = () => {
  const { CoreClient } = useCoreClient();
  const { data } = useGetOrganization();
  const { userData } = useUserData();

  const uuid = data?.uuid ?? "";

  return useQuery(
    `get-fam-pending-invites-${uuid}`,
    () => CoreClient.getFamPendingInvites({ uuid }),
    {
      enabled: !!uuid && !!userData,
    }
  );
};
export const useSendOrganizationInvite = () => {
  const { CoreClient } = useCoreClient();
  const { data } = useGetOrganization();
  const { refetch: refetchInvitationsList } = useGetPendingInvites();
  const { refetch: refetchMembersList } = useGetOrganizationMembersList();
  const { refetch: refetchFamIvitationsList } = useGetFamPendingInvites();

  const { mutateAsync } = useMutation(
    (args: {
      email: string;
      firstName?: string;
      lastName?: string;
      isFamily: boolean;
      isAdmin: boolean;
    }) =>
      CoreClient.sendOrganizationInvite({
        uuid: data?.uuid ?? "",
        ...args,
      }),
    {
      onSuccess: () => {
        refetchFamIvitationsList();
        refetchInvitationsList();
        refetchMembersList();
      },
    }
  );

  return mutateAsync;
};

export const useRevokeInvite = () => {
  const { CoreClient } = useCoreClient();
  const { data } = useGetOrganization();
  const { refetch: refetchInvitationsList } = useGetPendingInvites();
  const { refetch: refetchFamInvitationsList } = useGetFamPendingInvites();

  const { mutateAsync } = useMutation(
    (args: { inviteUuid: string }) =>
      CoreClient.revokeOrganizationInvite({
        organizationUuid: data?.uuid ?? "",
        ...args,
      }),
    {
      onSuccess: () => {
        refetchInvitationsList();
        refetchFamInvitationsList();
      },
    }
  );

  return mutateAsync;
};

export const useResendOrganizationInvite = () => {
  const { CoreClient } = useCoreClient();
  const { data } = useGetOrganization();

  const { mutateAsync } = useMutation((args: { inviteUuid: string }) =>
    CoreClient.resendOrganizationInvite({
      organizationUuid: data?.uuid ?? "",
      ...args,
    })
  );

  return mutateAsync;
};

export const useUpdateOrganizationMember = () => {
  const { CoreClient } = useCoreClient();
  const { refetch } = useGetOrganizationMembersList();

  const { mutateAsync } = useMutation(
    (args: Omit<OrganizationMember, "orgName">) =>
      CoreClient.updateOrganizationMember(args),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  return mutateAsync;
};

export const useDeleteOrganizationMember = () => {
  const { CoreClient } = useCoreClient();
  const { refetch } = useGetOrganizationMembersList();

  const { mutateAsync } = useMutation(
    (args: { proUuid: string; orgUuid: string }) =>
      CoreClient.deleteOrganizationMember({
        proUuid: args.proUuid,
        orgUuid: args.orgUuid,
      }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  return mutateAsync;
};

export const useGetOrgsFromFam = () => {
  const { CoreClient } = useCoreClient();

  const { mutateAsync } = useMutation((args: { famEmail: string }) =>
    CoreClient.getOrgsFromFam({ famEmail: args.famEmail })
  );

  return mutateAsync;
};
