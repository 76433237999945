import { trpc } from "@api";
import { useCallback } from "react";

type ProRequestOrganizationPayload = {
  orgName: string;
  website: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  orgType: string;
};

export const useRegisterProToken = () => {
  const { mutateAsync: registerToken } =
    trpc.homeStudy.registerToken.useMutation();

  return useCallback(
    async (args: { token: string }) => {
      return await registerToken({ token: args.token, account_group: "pro" });
    },
    [registerToken]
  );
};

export const useProRequestOrganization = () => {
  const { mutateAsync: requestOrganization } =
    trpc.homeStudy.requestOrganization.useMutation<ProRequestOrganizationPayload>();

  return requestOrganization;
};

export const useGetMyProDetails = (options?: { enabled: boolean }) => {
  return trpc.homeStudy.getMyProDetails.useQuery(undefined, options);
};
