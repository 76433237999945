import { createTheme, ThemeOptions } from "@mui/material";
import * as components from "./components";
import { ThemeProvider as MuiThemeProvider } from "@emotion/react";
import { ReactNode } from "react";
import { typography } from "./typography";
import { palette } from "./palette";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const themeConfig: Partial<ThemeOptions> = {
  components,
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
};

export const theme = createTheme(themeConfig);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    // LocalizationProvider is required for MUI Datepicker
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </LocalizationProvider>
  );
};

export * from "./typography";
export * from "./palette";
export * from "./custom-scroll";
