import { palette } from "../palette";
import { typography } from "../typography";
import { buttonClasses } from "@mui/material";

export const MuiButton = {
  defaultProps: {
    variant: "contained" as const,
    color: "primary" as const,
    size: "medium" as const,
  },
  styleOverrides: {
    sizeSmall: {
      height: "36px",
      padding: "0 32px",
      borderRadius: "20px",
    },
    sizeMedium: {
      height: "48px",
      padding: "0 32px",
      borderRadius: "24px",
    },
    root: {
      textTransform: "none" as const,
      boxShadow: "none",
      ...typography.button,

      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: palette.text.disabled,
        color: palette.background.default,
      },

      "&:hover": {
        boxShadow: "none",
      },
    },
    containedPrimary: {
      color: palette.primary.contrastText,

      "&:hover": {
        backgroundColor: palette.primary.light,
      },

      "&:active": {
        backgroundColor: palette.primary.dark,
      },
    },
    outlinedPrimary: {
      color: palette.text.primary,

      "&:hover": {
        backgroundColor: palette.primary.light,
        color: palette.primary.contrastText,
      },

      "&:active": {
        backgroundColor: palette.primary.dark,
      },

      [`&.${buttonClasses.disabled}`]: {
        borderColor: palette.text.disabled,
      },
      // There are some redundant styles are required in theme types like: "styles", "anim", "name"
    } as any,
    containedSecondary: {
      color: palette.secondary.contrastText,
      backgroundColor: palette.secondary.main,

      "&:hover": {
        backgroundColor: palette.secondary.light,
      },

      "&:active": {
        backgroundColor: palette.secondary.dark,
      },
    },
    outlinedSecondary: {
      color: palette.text.secondary,

      "&:hover": {
        backgroundColor: palette.secondary.light,
        color: palette.secondary.contrastText,
      },

      "&:active": {
        backgroundColor: palette.secondary.dark,
      },

      [`&.${buttonClasses.disabled}`]: {
        borderColor: palette.text.disabled,
      },
    },
    containedWarning: {
      color: palette.warning.contrastText,

      "&:hover": {
        backgroundColor: palette.warning.light,
      },

      "&:active": {
        backgroundColor: palette.warning.dark,
      },
    },
    outlinedWarning: {
      color: palette.text.primary,

      "&:hover": {
        backgroundColor: palette.warning.light,
        color: palette.warning.contrastText,
      },

      "&:active": {
        backgroundColor: palette.warning.dark,
      },

      [`&.${buttonClasses.disabled}`]: {
        borderColor: palette.text.disabled,
      },
    },
    containedError: {
      color: palette.error.contrastText,

      "&:hover": {
        backgroundColor: palette.error.light,
      },

      "&:active": {
        backgroundColor: palette.error.dark,
      },
    },
    outlinedError: {
      color: palette.text.primary,

      "&:hover": {
        backgroundColor: palette.error.light,
        color: palette.error.contrastText,
      },

      "&:active": {
        backgroundColor: palette.error.dark,
      },

      [`&.${buttonClasses.disabled}`]: {
        borderColor: palette.text.disabled,
      },
    },
    textPrimary: {
      color: palette.primary.main,

      "&:hover": {
        color: palette.primary.light,
        backgroundColor: palette.primary.background,
      },

      "&:active": {
        color: palette.primary.dark,
        backgroundColor: palette.primary.backgroundDark,
      },

      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: "transparent",
        color: palette.text.disabled,
      },
    },
  },
  variants: [
    {
      props: { variant: "contained" as const, color: "proBlue" as const },
      style: {
        "&:hover": {
          backgroundColor: palette.proBlue.light,
        },

        "&:active": {
          backgroundColor: palette.proBlue.dark,
        },
      },
    },
    {
      props: { variant: "outlined" as const, color: "proBlue" as const },
      style: {
        color: palette.text.primary,

        "&:hover": {
          backgroundColor: palette.proBlue.light,
          color: palette.proBlue.contrastText,
        },

        "&:active": {
          backgroundColor: palette.proBlue.dark,
        },
      },
    },
    {
      props: { variant: "iconVertical" as const },
      style: {
        color: palette.text.primary,
        borderRadius: "16px",
        flexDirection: "column",
        padding: "8px 24px",
        height: "64px",
        whiteSpace: "nowrap",

        [`& .${buttonClasses.startIcon}`]: {
          marginRight: 0,
          marginBottom: "8px",
          marginLeft: 0,
        },

        "&:hover": {
          backgroundColor: palette.primary.backgroundLight,

          [`& .${buttonClasses.startIcon}`]: {
            color: palette.primary.main,
          },
        },

        "&:active": {
          backgroundColor: palette.primary.backgroundDark,
        },

        [`&.${buttonClasses.disabled}`]: {
          backgroundColor: "transparent",
          color: palette.text.disabled,
        },
      },
    },
    // There are some redundant styles are required in theme types like: "styles", "anim", "name"
  ] as any,
};

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    proBlue: true;
  }

  interface ButtonPropsVariantOverrides {
    iconVertical: true;
  }
}
