import { Button, InfoIconFilled, Modal } from "@pairtreefamily/ui";
import { useCallback, useMemo, useState } from "react";
import { isDateExpiringOrExpired } from "@shared/utils";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { appConfig } from "@lib";
import { UseProfessionalPageTools } from "@features";
import { styles } from "./styles";
import { Task } from "@_types/*";

type RefreshTaskProps = {
  expirationDate: Date | null;
  refreshTask: UseProfessionalPageTools["refreshTask"];
  task: Task;
};

export const RefreshTask = ({
  expirationDate,
  refreshTask,
  task,
}: RefreshTaskProps) => {
  const { t } = useTranslation(["components", "common"]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleStartRefresh = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const submitButton = useMemo(() => {
    return {
      label: t("components:refreshTask.modalSubmit"),
      onClick: async () => {
        const response = await refreshTask({ taskId: task.id });
        handleModalClose();

        if (response.ok) {
          return;
        }

        return t("components:refreshTask.errors.refreshTaskFailed");
      },
    };
  }, [handleModalClose, refreshTask, t, task.id]);

  const isProviderTask = task?.TaskGroup?.providerOnly;

  if (
    !isDateExpiringOrExpired(
      appConfig.daysWhenTaskConsideredExpiring,
      expirationDate
    ) ||
    isProviderTask
  ) {
    return null;
  }

  return (
    <>
      <Button onClick={handleStartRefresh}>
        {t("components:refreshTask.startRefresh")}
      </Button>

      {isModalOpen && (
        <Modal
          title={`${t("components:refreshTask.modalTitle")} ${task?.title}`}
          open={isModalOpen}
          onClose={handleModalClose}
          submitButton={submitButton}
        >
          <Box pt={3} sx={styles.modalContentWrapper}>
            <Box color="secondary.main">
              <InfoIconFilled />
            </Box>

            <Typography maxWidth="650px" variant="input" component="div">
              {t(`components:refreshTask.modalContent`)}
            </Typography>
          </Box>
        </Modal>
      )}
    </>
  );
};
