import {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { SnackbarNotification } from "../SnackbarNotification";
import { autoHideDuration } from "../constants";
import { Box } from "@mui/material";
import { styles } from "./styles";
import { generateUniqueID, removeSnackbarById } from "../utils";
import type { SnackbarEvent, SnackbarStackElement } from "../types";

const SnackbarNotificationContext = createContext<{
  callSnackbar: ({ message, duration, type }: SnackbarEvent) => void;
}>({
  callSnackbar: () => {},
});

export const SnackbarNotificationProvider: FC<{ children: ReactNode }> = memo(
  ({ children }) => {
    const [snackbarStack, setSnackbarStack] = useState<SnackbarStackElement[]>(
      []
    );

    const callSnackbar = useCallback(
      ({ message, type, duration = autoHideDuration }: SnackbarEvent) => {
        const id = generateUniqueID();

        setSnackbarStack((prev) => [...prev, { message, id, duration, type }]);

        setTimeout(() => {
          setSnackbarStack((prev) => {
            return removeSnackbarById(prev)(id);
          });
        }, duration);
      },
      []
    );

    const value = useMemo(() => {
      return { callSnackbar };
    }, [callSnackbar]);

    return (
      <SnackbarNotificationContext.Provider value={value}>
        {children}

        <Box sx={styles.wrapper}>
          {snackbarStack.map(({ message, id, duration, type }) => {
            return (
              <SnackbarNotification
                key={id}
                message={message}
                open={true}
                type={type}
                sx={styles.snackbarComponent}
                autoHideDuration={duration}
                onClose={(ev, snackbarStack) => {
                  if (snackbarStack === "clickaway") {
                    return;
                  }

                  setSnackbarStack((prev) => {
                    return removeSnackbarById(prev)(id);
                  });
                }}
              />
            );
          })}
        </Box>
      </SnackbarNotificationContext.Provider>
    );
  }
);

SnackbarNotificationProvider.displayName = "SnackbarNotificationProvider";

export const useSnackbarNotification = () =>
  useContext(SnackbarNotificationContext);
