import { createContext } from "react";

import type { OrganizationMembership } from "@api";

export const OrganizationsContext = createContext<{
  organizationsListData: OrganizationMembership[] | null;
  isOrganizationsListLoading: boolean;
  userOrganizationData: OrganizationMembership | null;
  isUserOrganizationDataLoading: boolean;
  setActiveOrganizationId: (org: string | null) => void;
}>({
  organizationsListData: null,
  isOrganizationsListLoading: false,
  userOrganizationData: null,
  isUserOrganizationDataLoading: false,
  setActiveOrganizationId: (o) => {},
});
