import { TaskStatusElement } from "@shared/components";
import { isDateExpired, isDateExpiring } from "@shared/utils";
import { Box, Chip } from "@mui/material";
import { Task } from "@_types/*";
import { styles } from "./styles";
import { appConfig } from "@lib";
import { useTranslation } from "next-i18next";

export type TaskStatusProps = {
  task: Task;
};
export const TaskStatus = ({ task }: TaskStatusProps) => {
  const { t } = useTranslation("components");

  return (
    <Box display="flex" gap={1}>
      <TaskStatusElement task={task} shouldDisplayExpiration={false} />

      {isDateExpired(task.expirationDate) && (
        <Chip
          size="small"
          sx={styles.errorChip}
          label={t("statusElement.statuses.expired")}
          color="error"
        />
      )}

      {isDateExpiring(
        appConfig.daysWhenTaskConsideredExpiring,
        task.expirationDate
      ) && (
        <Chip
          size="small"
          sx={styles.warningChip}
          label={t("statusElement.statuses.expiring")}
          color="warning"
        />
      )}
    </Box>
  );
};
