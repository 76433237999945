import { maxContentWidth } from "@shared/configs";

export const styles = {
  backgroundWrapper: (background: string) => {
    return {
      background,
    };
  },
  wrapper: { px: 5, display: "flex", justifyContent: "center" },
  contentWidth: { maxWidth: maxContentWidth, width: "100%" },
};
