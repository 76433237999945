import { useTranslation } from "next-i18next";
import { Box, Pagination, Typography } from "@mui/material";
import { styles } from "./styles";

interface TablePaginationProps {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  onPageChange: (page: number) => void;
}

export const TablePagination = (props: TablePaginationProps) => {
  const { pageIndex, pageSize, totalCount, onPageChange } = props;

  const { t } = useTranslation("components");

  const pageCount = Math.ceil(totalCount / pageSize);

  return (
    <Box sx={styles.wrapper}>
      <Typography
        variant="body8"
        sx={{
          paddingX: 2,
        }}
      >
        {t("tablePagination.range", {
          start: pageIndex * pageSize + 1,
          end: Math.min((pageIndex + 1) * pageSize, totalCount),
          amount: totalCount,
        })}
      </Typography>

      <Pagination
        page={pageIndex + 1}
        count={pageCount}
        onChange={(_, page) => onPageChange(page - 1)}
      />
    </Box>
  );
};
