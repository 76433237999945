import { trpc } from "../trpc";
import { ExtendedTaskGroup, Task, TaskStatus } from "@_types/*";
import { useCallback, useMemo } from "react";
import { ExpiringTask } from "./types";
import { Result } from "@pairtreefamily/utils";

export const useGetTaskGroups = ({ caseId }: { caseId: string }) => {
  return trpc.homeStudy.getTasksForCase.useQuery<void, ExtendedTaskGroup[]>({
    caseId: caseId,
  });
};

export const useInvalidateTaskList = ({ caseId }: { caseId: string }) => {
  const { homeStudy } = trpc.useContext();

  return useCallback(() => {
    homeStudy.getTasksForCase.invalidate({ caseId: caseId });
  }, [caseId, homeStudy.getTasksForCase]);
};

export const useSetTaskAssignment = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { mutateAsync: setTaskAssignment, status: taskAssignmentStatus } =
    trpc.homeStudy.setTaskAssignment.useMutation({
      onSuccess,
    });

  return useMemo(() => {
    return {
      setTaskAssignment,
      taskAssignmentStatus,
    };
  }, [setTaskAssignment, taskAssignmentStatus]);
};

export const useDeleteTaskAssignment = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: deleteTaskAssignment } =
    trpc.homeStudy.deleteTaskAssignment.useMutation({
      onSuccess,
    });

  return useCallback(
    async ({ taskAssignmentId }: { taskAssignmentId: string }) => {
      await deleteTaskAssignment({ caseId, taskAssignmentId });
    },
    [caseId, deleteTaskAssignment]
  );
};

export const useUpsertTaskGroup = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: upsertTaskGroup } =
    trpc.homeStudy.upsertTaskGroup.useMutation({
      onSuccess,
    });

  return useCallback(
    async (args: {
      id: string;
      title: string;
      taskGroupInstruction: string;
      percentComplete: number;
      isHidden: boolean;
      providerOnly: boolean;
    }) => {
      return await upsertTaskGroup({
        caseId: caseId,
        ...args,
      });
    },
    [upsertTaskGroup, caseId]
  );
};

export const useDeleteTaskGroup = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: deleteTaskGroup } =
    trpc.homeStudy.deleteTaskGroup.useMutation({
      onSuccess,
    });

  return useCallback(
    async (group: ExtendedTaskGroup) => {
      return deleteTaskGroup({
        caseId: caseId,
        taskGroupId: group.id,
      });
    },
    [deleteTaskGroup, caseId]
  );
};

export const useDeleteTask = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: deleteTask } = trpc.homeStudy.deleteTask.useMutation({
    onSuccess,
  });

  return useCallback(
    async (task: Task) => {
      return deleteTask({
        caseId: caseId,
        taskId: task.id,
      });
    },
    [deleteTask, caseId]
  );
};

export const useUpdateTaskGroupOrder = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { mutateAsync: updateTaskGroupOrder } =
    trpc.homeStudy.updateTaskGroupsOrder.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: {
      taskGroups: Array<{ taskGroupId: string; order: number }>;
      caseId: string;
    }) =>
      updateTaskGroupOrder({
        taskGroups: args.taskGroups,
        caseId: args.caseId,
      }),
    [updateTaskGroupOrder]
  );
};

export const useUpdateTasksOrder = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { mutateAsync: updateTasksOrder } =
    trpc.homeStudy.updateTasksOrder.useMutation({
      onSuccess,
    });
  return useCallback(
    (args: {
      tasks: Array<{ taskId: string; order: number }>;
      caseId: string;
    }) =>
      updateTasksOrder({
        tasks: args.tasks,
        caseId: args.caseId,
      }),
    [updateTasksOrder]
  );
};

export const useIssueDocumentForTask = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: _issueDocument } =
    trpc.homeStudy.issueDocumentForTask.useMutation({
      onSuccess,
    });

  return useCallback(
    async (args: { task: Task }) => {
      const { task } = args;

      return _issueDocument({
        caseId: caseId,
        taskId: task.id,
      });
    },
    [_issueDocument, caseId]
  );
};

export const useUpdateTaskQuestionAnswer = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  // update task question answer
  const { mutateAsync: updateTaskQuestionAnswer } =
    trpc.homeStudy.updateTaskQuestionAnswer.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: { taskId: string; answer: boolean | null; caseId: string }) =>
      updateTaskQuestionAnswer({
        taskId: args.taskId,
        answer: args.answer,
        caseId: args.caseId,
      }),
    [updateTaskQuestionAnswer]
  );
};

export const useUpdateTaskStatus = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { mutateAsync: updateTaskStatus } =
    trpc.homeStudy.updateTaskStatus.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: {
      status: TaskStatus;
      taskId: string;
      taskAssignmentId: string;
      caseId: string;
    }) =>
      updateTaskStatus({
        ...args,
      }),
    [updateTaskStatus]
  );
};

export const useGetAllFilesFromTaskGroup = () => {
  const { mutateAsync: getAllFilesFromTaskGroup } =
    trpc.homeStudy.getFilesFromTaskGroup.useMutation();

  return useCallback(
    (args: { taskGroupId: string; caseId: string }) =>
      getAllFilesFromTaskGroup({
        ...args,
      }),
    [getAllFilesFromTaskGroup]
  );
};

export const useGetExpiringTasks = (cutoffDate: Date, active: boolean) => {
  return trpc.homeStudy.getExpiredTasks.useQuery<
    void,
    Result<ExpiringTask[], string>
  >({
    cutoffDate,
    active,
  });
};

export const useRefreshTask = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: refreshTask } = trpc.homeStudy.refreshTask.useMutation({
    onSuccess,
  });

  return useCallback(
    ({ taskId }: { taskId: string }) => {
      return refreshTask({ taskId, caseId });
    },
    [refreshTask, caseId]
  );
};

export const useGetArchivedTaskFiles = () => {
  const { mutateAsync: getArchivedTaskFiles } =
    trpc.homeStudy.getArchivedTaskFiles.useMutation();

  return useCallback(
    ({ taskId }: { taskId: string }) => {
      return getArchivedTaskFiles({ taskId });
    },
    [getArchivedTaskFiles]
  );
};

export const useUpdateCaseNote = ({ caseId }: { caseId: string }) => {
  const { mutateAsync: _updateCaseNote } =
    trpc.homeStudy.updateQuickNote.useMutation();

  return useCallback(
    (quickNote: string) => _updateCaseNote({ caseId, quickNote }),
    [_updateCaseNote, caseId]
  );
};
