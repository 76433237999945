import { Err, Ok, Result } from "@pairtreefamily/utils";

// takes a File or Blob and returns a base64 encoded string
export async function encodeFile(
  file: File | Blob
): Promise<Result<string, string>> {
  const reader = new FileReader();

  return new Promise((res, _rej) => {
    reader.readAsDataURL(file);

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        // if readAsDataURL has completed, this is guaranteed to be a
        // string
        const encodedFile = reader.result as string;
        // trim the header off of the resultant string
        // see https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
        const trimmedEncoding = encodedFile.slice(
          encodedFile.indexOf(";base64,") + 8
        );
        res(Ok(trimmedEncoding));
      },
      false
    );

    reader.addEventListener("error", (event) =>
      res(Err(`Unknown error: ${event}`))
    );

    reader.addEventListener("abort", (event) =>
      res(Err(`File read aborted: ${event}`))
    );

    // TODO arbitrary default time limit
    setTimeout(() => res(Err("Timed out when encoding file")), 10 * 1000);
  });
}
