import { z } from "zod";

export const requiredListValidation = z.array(z.string().optional()).refine(
  (value) => {
    return value.length > 0;
  },
  {
    params: {
      i18n: "custom.oneSelectionRequired",
    },
  }
);
