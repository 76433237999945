import { typography } from "../../typography";
import { palette } from "../../palette";

export const MuiTableCell = {
  styleOverrides: {
    root: {
      padding: "0px 16px",
      textAlign: "left" as const,
      height: "80px",
      borderBottom: `1px solid ${palette.divider}`,
      ...typography.body6,
    },
  },
};
