import { TextInput, TextInputProps } from "../text-input";

export type TextAreaProps = Omit<TextInputProps, "multiline"> & {
  maxLength?: number;
};

export const TextArea = (props: TextAreaProps) => {
  return (
    <>
      <TextInput
        multiline
        {...props}
        inputProps={{ maxLength: props.maxLength, ...props.inputProps }}
      />
    </>
  );
};
