import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { ErrorMessage } from "../error-message";
import { RadioInput, RadioInputNewProps } from "../../mui";

export interface RadioListFieldProps
  extends Omit<
    RadioInputNewProps,
    "onChange" | "value" | "checked" | "groupName" | "label"
  > {
  name: string;
  options: { value: string | boolean | null; label: string }[];
}

export const RadioListField = ({
  options,
  name,
  ...rest
}: RadioListFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <>
          {options.map(({ value, label }) => {
            return (
              <RadioInput
                key={`${name}-${value}`}
                label={label}
                checked={field.value === value}
                name={name}
                onChange={field.onChange}
                value={value}
                {...rest}
              />
            );
          })}

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </>
      );
    },
    [name, options, rest]
  );

  return <Controller name={name} render={render} />;
};
