import { Task } from "@_types/*";
import React, { ReactNode } from "react";
import { TableCell, Typography } from "@mui/material";

export function TaskItem(props: {
  task: Task;
  idx: number;
  row?: (
    task: Task,
    rowNum: number
  ) => {
    column1?: ReactNode;
    column2?: ReactNode;
    column3?: ReactNode;
    column4?: ReactNode;
  };
}) {
  const { task, idx, row } = props;

  const element = row !== undefined ? row(task, idx) : null;
  return (
    <>
      <TableCell sx={{ cursor: "default", width: "250px", p: 2 }}>
        <Typography variant="body3">{element?.column1}</Typography>
      </TableCell>

      <TableCell sx={{ cursor: "default", width: "350px", p: 2 }}>
        <Typography variant="body6">{element?.column2}</Typography>
      </TableCell>

      <TableCell sx={{ cursor: "default", width: "170px", p: 2 }}>
        <Typography variant="body6">{element?.column3}</Typography>
      </TableCell>

      <TableCell sx={{ cursor: "default", width: "170px", p: 2 }}>
        <Typography variant="body6">{element?.column4}</Typography>
      </TableCell>
    </>
  );
}
