import { useCallback } from "react";
import { trpc } from "../trpc";

type UseIncrementSaveCountArgs = {
  caseId: string;
};

export const useIncrementSaveCount = ({
  caseId,
}: UseIncrementSaveCountArgs) => {
  const { mutateAsync: incrementSaveCount } =
    trpc.homeStudy.incrementSaveCount.useMutation();

  return useCallback(
    async (args: { taskId: string }) => {
      return await incrementSaveCount({ taskId: args.taskId, caseId });
    },
    [incrementSaveCount, caseId]
  );
};
