import { PendingInvitation, useGetFamPendingInvites } from "@api";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

export const FamilyPendingInvitationsContext = createContext({
  familyInvitations: [] as PendingInvitation[],
});
export const FamilyPendingInvitationsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [familyInvitations, setFamilyInvitations] = useState(
    [] as PendingInvitation[]
  );

  const { data, error } = useGetFamPendingInvites();

  useEffect(() => {
    if (data && !error) {
      setFamilyInvitations(data);
    }
  }, [data, error]);

  return (
    <FamilyPendingInvitationsContext.Provider value={{ familyInvitations }}>
      {children}
    </FamilyPendingInvitationsContext.Provider>
  );
};

FamilyPendingInvitationsProvider.displayName =
  "FamilyPendingInvitationsProvider";

export const useFamilyPendingInvitations = () =>
  useContext(FamilyPendingInvitationsContext);
