import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from "@mui/material";
import { palette, typography } from "../../theme";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { SelectOption } from "../../../types";
import { memo } from "react";

const styles = {
  formControl: { marginTop: typography.body4.lineHeight },
  inputLabel: {
    ...typography.body4,
    transform: "translate(0px, calc(-100% - 5px))",
  },
  placeholder: {
    color: palette.text.light50,
    ...typography.input,
    transform: "translate(17px, calc(100% - 12px))",
  },
};

export type SelectNewProps = {
  label?: string;
  options: SelectOption[];
} & Omit<MuiSelectProps, "label" | "IconComponent">;

export const Select = memo(
  ({ label, placeholder, options, required, ...rest }: SelectNewProps) => {
    return (
      <FormControl sx={styles.formControl} fullWidth>
        <InputLabel
          required={required}
          focused={false}
          sx={styles.inputLabel}
          shrink={true}
        >
          {label}
        </InputLabel>

        {!rest.value && (
          <InputLabel focused={false} sx={styles.placeholder} shrink={false}>
            {placeholder}
          </InputLabel>
        )}

        <MuiSelect {...rest} IconComponent={KeyboardArrowDownRoundedIcon}>
          {options.map((option) => {
            return (
              <MenuItem key={`${option.id}-${option.label}`} value={option.id}>
                {option.label}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </FormControl>
    );
  }
);

Select.displayName = "Select";
