import {
  switchClasses,
  buttonBaseClasses,
  formControlLabelClasses,
} from "@mui/material";
import { palette } from "../palette";

export const MuiSwitch = {
  styleOverrides: {
    root: {
      width: 36,
      height: 23,
      padding: 0,
      display: "flex",
      overflow: "visible",
      margin: "0",
      [`& .${switchClasses.switchBase}`]: {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        [`&.${switchClasses.checked}`]: {
          transform: "translateX(22px)",
          color: palette.primary.main,
        },
        [`&:not(.${switchClasses.checked}) .${switchClasses.thumb}`]: {
          backgroundColor: palette.text.disabled,
        },
        [`&.${buttonBaseClasses.focusVisible} .${switchClasses.thumb}`]: {
          color: palette.primary.main,
          border: `6px solid ${palette.primary.contrastText}`,
        },
      },
      [`.${switchClasses.track}`]: {
        backgroundColor: palette.divider + "!important",
        opacity: 1,
        border: 0,
      },
      [`& .${switchClasses.thumb}`]: {
        boxSizing: "border-box",
        width: 20,
        height: 20,
      },
      [`& .${switchClasses.track}`]: {
        borderRadius: 16,
        backgroundColor: palette.text.light,
        opacity: 1,
        transition: "background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        height: "16px",
        margin: "auto 0",
      },
      [`& .${buttonBaseClasses.root}`]: {
        margin: "auto 0",
      },
      [`& + .${formControlLabelClasses.label}`]: {
        marginLeft: 8,
      },
    },
  },
};
