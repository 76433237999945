import { Box } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

export type LoadingProps = CircularProgressProps & {
  "data-test"?: string;
};

const boxStyles = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "2rem",
};

export const Loading = (props: LoadingProps) => (
  <Box sx={boxStyles} aria-label="Loading..." data-test={props["data-test"]}>
    <CircularProgress {...props} />
  </Box>
);
