import {
  DetailedHTMLProps,
  FormHTMLAttributes,
  useEffect,
  useMemo,
} from "react";
import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";

import { Box, SxProps } from "@mui/material";
import { useUnsavedChanges } from "../unsaved-changes/UnsavedChangesProvider";

export type FormProps<FormValues extends Record<string, any>> = {
  formMethods: UseFormReturn<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  shouldAskForUnsavedChanges?: boolean;
  formHeight?: string;
  sx?: SxProps;
} & Omit<
  DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
  "onSubmit" | "ref"
>;

export const Form = <FormValues extends Record<string, any>>({
  children,
  onSubmit,
  formMethods,
  formHeight = "auto",
  shouldAskForUnsavedChanges = false,
  sx,
  ...rest
}: FormProps<FormValues>) => {
  const sxProp = useMemo(() => {
    return { height: formHeight, ...sx };
  }, [formHeight, sx]);

  const { setIsOn: setIsUnsavedChangesOn } = useUnsavedChanges();

  useEffect(() => {
    if (shouldAskForUnsavedChanges) {
      setIsUnsavedChangesOn(formMethods.formState.isDirty);
    }

    return () => {
      setIsUnsavedChangesOn(false);
    };
  }, [
    formMethods.formState.isDirty,
    setIsUnsavedChangesOn,
    shouldAskForUnsavedChanges,
  ]);

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        noValidate
        sx={sxProp}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        {...rest}
      >
        {children}
      </Box>
    </FormProvider>
  );
};
