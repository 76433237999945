import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import {
  AutocompleteField,
  CheckboxField,
  SelectField,
  TextAreaField,
  TextField,
} from "@pairtreefamily/ui";

import { useTaskStatusOptions, useTaskTypeOptions } from "../hooks";

import { ExtendedTaskGroup, Task, TaskGroup, TaskType } from "@_types/*";
import { Box } from "@mui/material";

type CommonTaskFieldsProps = {
  task?: Task;
  taskType?: TaskType;
  taskGroup?: TaskGroup;
  allTaskGroups?: ExtendedTaskGroup[];
  isAdmin: boolean;
  onTaskTypeChange: (value: TaskType) => void;
};

export function CommonTaskFields(props: CommonTaskFieldsProps) {
  const {
    task,
    taskType,
    taskGroup,
    allTaskGroups,
    isAdmin,
    onTaskTypeChange,
  } = props;

  const { t } = useTranslation("components");

  const taskStatusOptions = useTaskStatusOptions();
  const taskTypeOptions = useTaskTypeOptions();

  const mappedTaskGroups = useMemo(() => {
    if (!allTaskGroups?.length) {
      return [];
    }

    return allTaskGroups.map((item) => {
      return {
        id: item.id,
        label: item.title,
      };
    });
  }, [allTaskGroups]);

  // filters "reference" option if the taskGroup is PRO-only
  const filteredOptions = useMemo(
    () =>
      taskGroup?.providerOnly
        ? taskTypeOptions.filter(({ id }) => id !== "reference")
        : //provider should not be able to create a task type electronic document. But on admin view we can.
          //If the task already exist then that mean that is edit, and on this case we can show the electronic document option because the task type cannot ever be changed anyway
          taskTypeOptions.filter(({ id }) =>
            !isAdmin
              ? (id !== "electronicDocument" && !task?.id) || task?.id
              : true
          ),
    [taskGroup?.providerOnly, taskTypeOptions, isAdmin, task]
  );

  // determine if the "isPrivate" field should be shown
  const isPrivateFieldShown = useMemo(() => {
    // show for Pro task groups
    if (taskGroup?.providerOnly && taskType) return true;

    // for Fam task groups, show only for "reference" task type
    return !taskGroup?.providerOnly && taskType === "reference";
  }, [taskType, taskGroup]);

  const isFieldDisabled = task?.status === "completed" && !isAdmin;

  return (
    <>
      <TextField
        name="title"
        label={t("taskManagerModal.fields.title.label")}
        disabled={isFieldDisabled}
      />

      {task && (
        <Box mt={2}>
          <SelectField
            name="status"
            options={taskStatusOptions}
            label={t("taskManagerModal.fields.baseTaskFields.label")}
            placeholder={t(
              "taskManagerModal.fields.baseTaskFields.placeholder"
            )}
            disabled={isFieldDisabled}
          />
        </Box>
      )}

      <TextAreaField
        rows={4}
        name="description"
        label={t("taskManagerModal.fields.description.label")}
        placeholder={t("taskManagerModal.fields.description.placeholder")}
        disabled={isFieldDisabled}
      />

      {task?.taskType !== "reference" && mappedTaskGroups.length > 0 && (
        <Box mt={2}>
          <SelectField
            name="taskGroupId"
            options={mappedTaskGroups}
            label={t("taskManagerModal.fields.taskGroup.label")}
            placeholder={t("taskManagerModal.fields.taskGroup.placeholder")}
          />
        </Box>
      )}

      <Box mt={2}>
        <SelectField
          name="taskType"
          options={filteredOptions}
          disabled={Boolean(task)}
          onChange={(event) => onTaskTypeChange(event.target.value)}
          label={t("taskManagerModal.fields.taskType.label")}
          placeholder={t("taskManagerModal.fields.taskType.placeholder")}
        />
      </Box>

      {isPrivateFieldShown && (
        <Box mt={2}>
          <CheckboxField
            name="isPrivate"
            label={t("taskManagerModal.fields.isPrivate.label")}
            disabled={isFieldDisabled}
          />
        </Box>
      )}
    </>
  );
}
