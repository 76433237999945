import { Modal, useAsyncModalState } from "../../mui";
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NextRouter } from "next/router";

type UnsavedChangesProviderProps = {
  children: ReactNode;
  router: NextRouter;
};

export const UnsavedChangesContext = createContext<{
  setIsOn: (isOn: boolean) => void;
  detectUnsavedChanges: (
    callback: (args: any) => void
  ) => (args: any) => Promise<void>;
}>({
  setIsOn: () => {},
  detectUnsavedChanges: () => () => new Promise(() => {}),
});

export const UnsavedChangesProvider = memo(
  ({ children, router }: UnsavedChangesProviderProps) => {
    const [isOn, setIsOn] = useState(false);
    const { isModalOpen, onAccept, onClose, openModalAsync } =
      useAsyncModalState();

    const onRouteChangeStart = useCallback(
      (nextPath: string) => {
        if (!isOn) return;

        setTimeout(async () => {
          try {
            await openModalAsync();
            setIsOn(false);
            router.push(nextPath as string);
          } catch (e) {}
        });

        throw "cancelRouteChange";
      },
      [isOn, openModalAsync, router]
    );

    const removeListener = useCallback(() => {
      router.events.off("routeChangeStart", onRouteChangeStart);
    }, [router.events, onRouteChangeStart]);

    useEffect(() => {
      router.events.on("routeChangeStart", onRouteChangeStart);

      return removeListener;
    }, [router.events, onRouteChangeStart, removeListener]);

    useEffect(() => {
      if (!isOn) {
        window.onbeforeunload = null;
        return;
      }

      // Enable navigation prompt
      window.onbeforeunload = function () {
        return true;
      };

      // Remove navigation prompt
      return () => {
        window.onbeforeunload = null;
        setIsOn(false);
      };
    }, [isOn]);

    const detectUnsavedChanges = useCallback(
      (callback: (args: any) => void) => {
        return async function (args: any) {
          if (!isOn) {
            callback(args);
            return;
          }

          try {
            await openModalAsync();

            setIsOn(false);

            callback(args);
          } catch (e) {}
        };
      },
      [isOn, openModalAsync]
    );

    const value = useMemo(
      () => ({
        setIsOn,
        detectUnsavedChanges,
      }),
      [detectUnsavedChanges]
    );

    return (
      <UnsavedChangesContext.Provider value={value}>
        {children}

        <Modal
          title="You have unsaved changes"
          submitButton={{
            label: "Discard changes",
            onClick: onAccept,
          }}
          open={isModalOpen}
          onClose={onClose}
        >
          Leaving this page will discard unsaved changes. Are you sure?
        </Modal>
      </UnsavedChangesContext.Provider>
    );
  }
);

export const useUnsavedChanges = () => useContext(UnsavedChangesContext);

UnsavedChangesProvider.displayName = "UnsavedChangesProvider";
