import {
  Button,
  Modal,
  SidePanel,
  TextField,
  useSnackbarNotification,
} from "@pairtreefamily/ui";
import { FormValues } from "./validation-schema";
import { useForm } from "react-hook-form";
import { useTranslation } from "next-i18next";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import {
  RequestError,
  useGetOrgsFromFam,
  useSendOrganizationInvite,
} from "@api";
import { ca } from "date-fns/locale";

export type InviteFamilySideFamilyProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function InviteFamilySidePanel({
  isOpen,
  onClose,
}: InviteFamilySideFamilyProps) {
  const { t } = useTranslation("connect");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const sendOrganizationInvite = useSendOrganizationInvite();
  const { callSnackbar } = useSnackbarNotification();

  const getOrgsFromFam = useGetOrgsFromFam();

  const formMethods = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const handleSubmit = useCallback(async () => {
    const { email, firstName, lastName } = formMethods.getValues();
    try {
      await sendOrganizationInvite({
        email,
        firstName,
        lastName,
        isFamily: true,
        isAdmin: false,
      });

      formMethods.reset();
      callSnackbar({
        message: t("familyListing.inviteFamily.famSuccessAssociated", {
          email,
        }),
        type: "info",
      });
      onClose();
      setIsModalOpen(false);
    } catch (error) {
      callSnackbar({
        message: t("familyListing.inviteFamily.famErrorAssociated", {
          email,
        }),
        type: "error",
      });
    }
  }, [callSnackbar, formMethods, sendOrganizationInvite, t, onClose]);

  const checkFamilyAssociation = useCallback(
    async ({ email }: FormValues) => {
      try {
        const orgs = await getOrgsFromFam({ famEmail: email });
        // if the account exists, and has at least one org, confirm before
        // inviting
        if (orgs.length > 0) {
          setIsModalOpen(true);
          // if the account exists, but there are no orgs, just invite them
        } else {
          handleSubmit();
        }
      } catch (error) {
        // if no account exists, just send invite
        if ((error as RequestError).response.status === 404) {
          handleSubmit();
          return;
        }
        callSnackbar({
          message: t(
            "familyListing.inviteFamily.errorOnCheckingFamAssociation",
            {
              email,
            }
          ),
          type: "error",
        });
      }
    },
    [callSnackbar, getOrgsFromFam, handleSubmit, t]
  );

  const actions = (
    <>
      <Button type="button" variant="outlined" onClick={onClose}>
        {t("familyListing.inviteFamily.cancel")}
      </Button>

      <Button type="submit">{t("familyListing.inviteFamily.invite")}</Button>
    </>
  );

  return (
    <>
      <SidePanel<FormValues>
        open={isOpen}
        title={t("familyListing.inviteFamily.title")}
        onClose={onClose}
        onSubmit={checkFamilyAssociation}
        formMethods={formMethods}
        actions={actions}
      >
        <TextField
          required
          name="firstName"
          label={t("familyListing.inviteFamily.firstName")}
        />
        <TextField
          required
          name="lastName"
          label={t("familyListing.inviteFamily.lastName")}
        />
        <TextField
          required
          name="email"
          label={t("familyListing.inviteFamily.email")}
        />
      </SidePanel>

      <Modal
        title={t("familyListing.inviteFamily.famAlreadyAssociatedTitle")}
        open={isModalOpen}
        submitButton={{
          label: t("familyListing.inviteFamily.famAlreadyAssociatedYes"),
          onClick: handleSubmit,
        }}
        onClose={() => setIsModalOpen(false)}
      >
        <Box component="p">
          {t("familyListing.inviteFamily.famAlreadyAssociatedMessage")}
          <br />
          <strong>
            {t("familyListing.inviteFamily.famAlreadyAssociatedQuestion")}
          </strong>
        </Box>
      </Modal>
    </>
  );
}
