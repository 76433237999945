import { palette } from "../../palette";
import { typography } from "../../typography";
import { tableCellClasses } from "@mui/material";

const tableHeadHeight = "56px";

export const MuiTableHead = {
  styleOverrides: {
    root: {
      backgroundColor: palette.background.light,
      color: palette.text.dark,
      textTransform: "uppercase" as const,
      borderTop: `1px solid ${palette.divider}`,
      borderBottom: `1px solid ${palette.divider}`,

      [`& .${tableCellClasses.root}`]: {
        maxHeight: tableHeadHeight,
        height: tableHeadHeight,
        borderBottom: "none",
        ...typography.overline,
      },
    },
  },
};
