import { palette } from "../palette";

export const MuiIconButton = {
  styleOverrides: {
    root: {
      height: "48px",
      width: "48px",
      color: palette.text.light,
      backgroundColor: "transparent",
      fontSize: "24px",

      "&:hover": {
        backgroundColor: "transparent",
        color: palette.primary.main,
      },

      "&:active": {
        backgroundColor: "transparent",
        color: palette.primary.dark,
      },

      [`& svg`]: {
        height: "24px",
        width: "24px",
      },
    },
    sizeSmall: {
      height: "24px",
      width: "24px",

      "&:hover": {
        backgroundColor: "transparent",
        color: palette.primary.main,
      },
    },
  },
};

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    primary: true;
    secondary: false;
    warning: false;
    error: false;
  }
}
