import { usePhoneNumberRegExp } from "@shared/configs";
import { requiredString } from "./requiredString";

export const phoneNumberValidation = requiredString.refine(
  (data) => usePhoneNumberRegExp.test(data),
  {
    params: {
      i18n: {
        key: "custom.phoneNumberExactDigitsLength",
        values: { length: 10 },
      },
    },
  }
);
