import { trpc } from "../trpc";
import { useCallback } from "react";
import { Person, Task } from "@_types/*";
import { TRPCClientErrorLike } from "@trpc/client";

export const useGetReferenceTask = ({
  enabled,
  caseId,
  taskId,
}: {
  enabled: boolean;
  taskId: string;
  caseId: string;
}) => {
  return trpc.homeStudy.getReferenceTaskById.useQuery(
    { caseId, id: taskId },
    { enabled: enabled }
  );
};

export const useSendReferenceRequest = ({
  caseId,
  onSuccess,
  onError,
}: {
  caseId: string;
  onSuccess: () => void;
  onError?: (err: TRPCClientErrorLike<any>) => void;
}) => {
  const { mutateAsync: sendReferenceRequest } =
    trpc.homeStudy.sendReferenceRequest.useMutation({
      onSuccess,
      onError,
    });

  return useCallback(
    async (args: { task: Task; recipient: Person; personalNote?: string }) => {
      const { task, recipient, personalNote } = args;

      return sendReferenceRequest({
        caseId: caseId,
        taskId: task.id,
        recipient,
        personalNote: personalNote,
      });
    },
    [sendReferenceRequest, caseId]
  );
};

export const useCreateReferenceTask = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  // create reference task
  const { mutateAsync: createReferenceTask } =
    trpc.homeStudy.createReferenceTask.useMutation({
      onSuccess,
    });

  return useCallback(
    async (args: { taskGroupId: string }) => {
      return createReferenceTask({
        caseId: caseId,
        taskGroupId: args.taskGroupId,
      });
    },
    [createReferenceTask, caseId]
  );
};
