import { ChangeEvent, useCallback, useMemo, useState } from "react";

import { debounce } from "@pairtreefamily/utils";

import { Box, IconButton, type BoxProps } from "@mui/material";
import { SearchIcon, TextInput } from "@pairtreefamily/ui";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

type SearchBoxProps = BoxProps & {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  placeholder?: string;
};

export const SearchBox = ({
  searchQuery,
  setSearchQuery,
  placeholder = "",
  ...props
}: SearchBoxProps) => {
  const [value, setValue] = useState("");

  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((value: string) => {
        setSearchQuery(value);
      }, 200),
    [setSearchQuery]
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(event.target.value);
      debouncedSetSearchQuery(event.target.value);
    },
    [debouncedSetSearchQuery]
  );

  const handleInputClear = useCallback(() => {
    setSearchQuery("");
    setValue("");
  }, [setSearchQuery]);

  const InputProps = useMemo(() => {
    return {
      startAdornment: (
        <Box sx={{ color: "success.light" }}>
          <SearchIcon />
        </Box>
      ),
      endAdornment: searchQuery ? (
        <IconButton
          sx={{ marginRight: "-6px" }}
          color="success"
          onClick={handleInputClear}
        >
          <CloseOutlinedIcon
            sx={{ height: "24px", width: "24px", color: "success.light" }}
          />
        </IconButton>
      ) : null,
    };
  }, [handleInputClear, searchQuery]);

  return (
    <Box {...props}>
      <TextInput
        value={value}
        placeholder={placeholder}
        InputProps={InputProps}
        onChange={handleInputChange}
      />
    </Box>
  );
};
