import { Children, Fragment, isValidElement, memo, ReactNode } from "react";

import { Divider, Typography } from "@mui/material";

export type DropdownSectionProps = {
  title?: ReactNode;
  children: ReactNode[] | ReactNode;
};

export const DropdownSection = memo(
  ({ title, children }: DropdownSectionProps) => {
    return (
      <Fragment>
        {title && (
          <Typography component="h3" variant="caption2">
            {title}
          </Typography>
        )}

        {Children.map(children, (child) => {
          if (!isValidElement(child)) return null;
          return child;
        })}

        <Divider />
      </Fragment>
    );
  }
);

DropdownSection.displayName = "DropdownSection";
