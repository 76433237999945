import type { DefaultValues } from "react-hook-form";
import type { FormValues } from "./validation-schema";

export const defaultTaskValues: DefaultValues<FormValues> = {
  taskType: undefined,
  title: "",
  description: "",
  status: undefined,
  taskGroupId: "",
  isPrivate: false,
  templateUuid: undefined,
  question: "",
  answer: null,
  expirationDateRequired: false,
  expirationDate: null,
  areNotificationsEnabled: false,
  recipientEmailIds: [],
};

export const taskTypes = [
  "electronicDocument",
  "fileUpload",
  "reference",
] as const;

export const taskStatuses = [
  "notStarted",
  "sent",
  "inProgress",
  "completed",
  "needsReview",
] as const;
