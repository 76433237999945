import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import { Ok, Result, sift } from "@pairtreefamily/utils";
import { ExtendedTaskGroup } from "@_types/tasks";
import { encodeFile } from "@shared/utils";
import { useFamilyTools } from "./useFamilyTools";

export type UseFileUploadArgs = {
  getFileData: ReturnType<typeof useFamilyTools>["getFileData"];
  uploadDocument: ReturnType<typeof useFamilyTools>["uploadDocument"];
  deleteDocument: ReturnType<typeof useFamilyTools>["deleteDocument"];
  caseId: string;
};

export type UseFileUpload = ReturnType<typeof useFileUpload>;

export function useFileUpload(args: UseFileUploadArgs) {
  const { caseId, getFileData, uploadDocument, deleteDocument } = args;

  const { t } = useTranslation("common");

  // local filename lookup - to be used in functions/components
  const getFileName: (fileId: string) => Promise<string | null> = async (
    fileId
  ) => {
    const name = await getFileData({
      googleDriveFileId: fileId,
      caseId: caseId,
    });
    if (name.ok) {
      return name.content.name ?? null;
    } else {
      return null;
    }
  };

  async function uploadFile(args: {
    file: File;
    fileName: string;
    taskId: string;
    taskGroupId: string;
    isPrivate: boolean;
  }) {
    // base 64 encode the file:
    const encodedFileRes = await encodeFile(args.file);
    if (!encodedFileRes.ok) {
      // TODO set error state
      //logger.error("uploadFile > Error on file encoding", encodedFileRes.error);
      console.log(encodedFileRes.error);
      return;
    }

    await uploadDocument({
      caseId,
      taskId: args.taskId,
      file: encodedFileRes.content,
      fileName: args.fileName,
      taskGroupId: args.taskGroupId,
      isPrivate: args.isPrivate,
    });
  }

  async function deleteFile(args: {
    fileId: string;
    fileName: string;
    taskId: string;
    taskGroupId: string;
  }) {
    if (
      confirm(t("hooks.fileUpload.confirmDelete", { fileName: args.fileName }))
    ) {
      await deleteDocument({
        caseId,
        taskId: args.taskId,
        fileId: args.fileId,
        taskGroupId: args.taskGroupId,
      });
    }
  }

  return { getFileName, uploadFile, deleteFile };
}
