export const SwapIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2176 16.8589C11.9342 16.8589 11.6967 16.7631 11.5053 16.5714C11.3138 16.3798 11.2181 16.1423 11.2181 15.859V7.15124L8.12831 10.241C7.94798 10.4256 7.72256 10.5179 7.45205 10.5179C7.18154 10.5179 6.94287 10.4223 6.73605 10.231C6.5292 10.0223 6.42578 9.78378 6.42578 9.5154C6.42578 9.24703 6.5292 9.00943 6.73605 8.8026L11.3617 4.17697C11.4865 4.05219 11.6186 3.96416 11.7582 3.91287C11.8978 3.86158 12.0474 3.83594 12.207 3.83594C12.3665 3.83594 12.5181 3.86177 12.6616 3.91344C12.8052 3.96513 12.9386 4.05297 13.0616 4.17697L17.7206 8.83594C17.9189 9.03423 18.0168 9.26414 18.0142 9.52567C18.0116 9.78721 17.9069 10.0223 17.7001 10.231C17.4933 10.4223 17.2591 10.5214 16.9976 10.5282C16.736 10.535 16.5019 10.435 16.295 10.2282L13.218 7.15124V15.8718C13.218 16.1515 13.1222 16.3859 12.9304 16.5751C12.7387 16.7643 12.5011 16.8589 12.2176 16.8589ZM19.7932 28.1512C19.6337 28.1512 19.4822 28.1254 19.3386 28.0737C19.195 28.0221 19.0616 27.9342 18.9386 27.8102L14.2796 23.1512C14.0813 22.953 13.9835 22.7231 13.986 22.4615C13.9886 22.2 14.0933 21.9649 14.3001 21.7562C14.5069 21.5649 14.7411 21.4658 15.0026 21.459C15.2642 21.4522 15.4984 21.5522 15.7052 21.759L18.7822 24.8359V16.1154C18.7822 15.8357 18.878 15.6013 19.0698 15.412C19.2615 15.2228 19.4991 15.1282 19.7826 15.1282C20.0661 15.1282 20.3035 15.2241 20.4949 15.4157C20.6864 15.6074 20.7821 15.8449 20.7821 16.1282V24.8359L23.8719 21.7462C24.0522 21.5616 24.2777 21.4692 24.5482 21.4692C24.8187 21.4692 25.0574 21.5649 25.2642 21.7562C25.471 21.9649 25.5744 22.2034 25.5744 22.4718C25.5744 22.7402 25.471 22.9777 25.2642 23.1846L20.6385 27.8102C20.5138 27.935 20.3816 28.023 20.242 28.0743C20.1024 28.1256 19.9528 28.1512 19.7932 28.1512Z"
        fill="currentColor"
      />
    </svg>
  );
};
