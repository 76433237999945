import { Box } from "@mui/material";
import { Header } from "./components/header";
import { NavigationPanel } from "./components/navigation-panel/NavigationPanel";
import { ReactNode, useState } from "react";
import { styles } from "./styles";
import { RouteConfig } from "@features/routing";

type LayoutProps = {
  children: ReactNode;
  routeConfig: RouteConfig;
};

export const Layout = ({ children, routeConfig }: LayoutProps) => {
  const [isNavigationPanelCollapsed, setIsNavigationPanelCollapsed] =
    useState(false);

  return (
    <Box sx={styles.wrapper(isNavigationPanelCollapsed, routeConfig)}>
      {routeConfig.hasHeader && (
        <Box sx={styles.header}>
          <Header />
        </Box>
      )}

      {routeConfig.hasNavigationPanel && (
        <Box sx={styles.navigationPanel}>
          <NavigationPanel
            isCollapsed={isNavigationPanelCollapsed}
            onToggleViewButtonClick={() => {
              setIsNavigationPanelCollapsed(!isNavigationPanelCollapsed);
            }}
          />
        </Box>
      )}

      <Box sx={styles.content()}>{children}</Box>
    </Box>
  );
};
