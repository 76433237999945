import { ReactNode } from "react";

type PageProps = {
  children: ReactNode;
};

export function Page({ children }: PageProps) {
  return (
    <div className="px-8">
      <div className="text-body-2 mx-auto max-w-screen-2xl">{children}</div>
    </div>
  );
}
