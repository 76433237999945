export function EmailOutlineIcon() {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0.5C11.3203 0.5 12 1.17969 12 2V8C12 8.84375 11.3203 9.5 10.5 9.5H1.5C0.65625 9.5 0 8.84375 0 8V2C0 1.17969 0.65625 0.5 1.5 0.5H10.5ZM1.5 1.625C1.28906 1.625 1.125 1.8125 1.125 2V2.53906L5.01562 5.77344C5.55469 6.21875 6.42188 6.21875 6.96094 5.77344L10.875 2.53906V2C10.875 1.8125 10.6875 1.625 10.5 1.625H1.5ZM10.5 8.375C10.6875 8.375 10.875 8.21094 10.875 8V3.99219L7.66406 6.66406C7.19531 7.03906 6.60938 7.25 6 7.25C5.36719 7.25 4.78125 7.03906 4.3125 6.66406L1.125 3.99219V8C1.125 8.21094 1.28906 8.375 1.5 8.375H10.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
