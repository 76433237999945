import { useTranslation } from "next-i18next";
import { Button } from "@pairtreefamily/ui";
import { Result } from "@pairtreefamily/utils";
import { ReferenceTask } from "@_types/*";
import { useState } from "react";
import RecipientModal from "./components/recipient-modal/RecipientModal";
import { RefreshTask } from "../refresh-task";
import { Box } from "@mui/material";
import { UseProfessionalPageTools } from "@features";

export type ReferenceRowProps = {
  deleteTask?: (task: ReferenceTask) => void;
  editTask?: (task: ReferenceTask) => void;
  issueReferenceRequest: (args: {
    firstName: string;
    lastName: string;
    email: string;
    personalNote?: string;
  }) => Promise<Result<unknown, unknown>>;
  task: ReferenceTask;
  enableViewCompletedReference?: boolean;
  refreshTask?: UseProfessionalPageTools["refreshTask"];
};

export function ReferenceRow(props: ReferenceRowProps) {
  const { task, deleteTask, issueReferenceRequest, editTask } = props;

  const { t } = useTranslation("components");

  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] =
    useState<boolean>(false);

  const isRequestButtonDisabled = () => {
    const isFamily = !editTask;
    const hasQuestion = Boolean(task.question);
    const hasAnswer = task.answer !== null;

    return (isFamily && hasQuestion && !hasAnswer) || task.isLocked;
  };

  return (
    <>
      <RecipientModal
        isOpen={isCreateTaskModalOpen}
        onClose={() => setIsCreateTaskModalOpen(false)}
        onSubmit={async (recipient) => {
          const res = await issueReferenceRequest({
            ...recipient,
            personalNote: recipient.note,
          });
          return res.ok;
        }}
      />
      {task.status === "notStarted" && (
        <>
          <div className="flex justify-center">
            <Button
              onClick={() => setIsCreateTaskModalOpen(true)}
              disabled={isRequestButtonDisabled()}
            >
              {t("referenceRow.buttons.request")}
            </Button>
          </div>

          {deleteTask && (
            <div className="flex justify-center pt-4">
              <Button onClick={() => deleteTask(task)} color="error">
                {t("referenceRow.buttons.delete")}
              </Button>
            </div>
          )}
        </>
      )}
      {editTask && (
        <div className="flex justify-center pt-4">
          <Button
            onClick={() => editTask(task)}
            color="error"
            variant="outlined"
          >
            {t("referenceRow.buttons.edit")}
          </Button>
        </div>
      )}

      {task.status !== "notStarted" && !editTask && (
        <div className="flex justify-center">
          <i>{t("referenceRow.noActionRequired")}</i>
        </div>
      )}

      {props.refreshTask && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          <RefreshTask
            task={task}
            refreshTask={props.refreshTask}
            expirationDate={task.expirationDate}
          />
        </Box>
      )}
    </>
  );
}
