import {
  CSSProperties,
  TypographyOptions,
  TypographyStyleOptions,
} from "@mui/material/styles/createTypography";

export const typography: CustomTypographyOptions = {
  fontFamily: "Montserrat, sans-serif",
  fontSize: 20,
  head1: {
    fontSize: 48,
    lineHeight: "56px",
    fontWeight: 700,
    fontFamily: "Rebrand",
    fontStyle: "italic",
    letterSpacing: "-0.48px",
  },
  head2: {
    fontSize: 35,
    lineHeight: "40px",
    fontWeight: 700,
    fontFamily: "Rebrand",
    fontStyle: "italic",
    letterSpacing: "-0.35px",
  },
  head3: {
    fontSize: 25,
    lineHeight: "32px",
    fontWeight: 700,
    fontFamily: "Rebrand",
    fontStyle: "italic",
    letterSpacing: "-0.25px",
  },
  head4: {
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: 700,
    fontFamily: "Montserrat, sans-serif",
  },
  head5: {
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 700,
    fontFamily: "Montserrat, sans-serif",
  },
  head6: {
    fontSize: 17,
    lineHeight: "24px",
    fontWeight: 700,
    fontFamily: "Montserrat, sans-serif",
  },
  body1: {
    fontSize: 20,
    lineHeight: "32px",
    fontWeight: 400,
    fontFamily: "Montserrat, sans-serif",
  },
  body2: {
    fontSize: 17,
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Montserrat, sans-serif",
  },
  body3: {
    fontSize: 15,
    lineHeight: "24px",
    fontWeight: 700,
    fontFamily: "Montserrat, sans-serif",
  },
  body4: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Montserrat, sans-serif",
  },
  body5: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 700,
    fontStyle: "italic",
    fontFamily: "Montserrat, sans-serif",
  },
  body6: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 400,
    fontFamily: "Montserrat, sans-serif",
  },
  body7: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 400,
    fontStyle: "italic",
    fontFamily: "Montserrat, sans-serif",
  },
  body8: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 500,
    fontFamily: "Montserrat, sans-serif",
  },
  body9: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 600,
    fontFamily: "Montserrat, sans-serif",
  },
  body10: {
    fontSize: 13,
    lineHeight: "20px",
    fontWeight: 500,
    fontStyle: "italic",
    fontFamily: "Montserrat, sans-serif",
  },
  caption1: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 400,
    fontFamily: "Montserrat, sans-serif",
  },
  caption2: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 600,
    fontFamily: "Montserrat, sans-serif",
  },
  caption3: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 400,
    fontStyle: "italic",
    fontFamily: "Montserrat, sans-serif",
  },
  tooltip: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 500,
    fontFamily: "Montserrat, sans-serif",
  },
  input: {
    fontSize: 15,
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Montserrat, sans-serif",
  },
  button: {
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: 600,
    fontFamily: "Montserrat, sans-serif",
  },
  alerts: {
    fontSize: 10,
    lineHeight: "16px",
    fontWeight: 700,
    letterSpacing: "0.5px",
    fontFamily: "Montserrat, sans-serif",
  },
  overline: {
    fontSize: 13,
    lineHeight: "20px",
    letterSpacing: "0.65px",
    fontWeight: 700,
    fontFamily: "Montserrat, sans-serif",
  },
  portalHeadline: {
    fontSize: 55,
    lineHeight: "60px",
    fontWeight: 700,
    fontFamily: "Rebrand",
    fontStyle: "italic",
  },
};

declare module "@mui/material/Typography" {
  // Turn on custom variants
  interface TypographyPropsVariantOverrides {
    head1: true;
    head2: true;
    head3: true;
    head4: true;
    head5: true;
    head6: true;
    body2: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
    body8: true;
    body9: true;
    body10: true;
    caption1: true;
    caption2: true;
    caption3: true;
    tooltip: true;
    input: true;
    button: true;
    alerts: true;
    overline: true;
    portalHeadline: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    head1: CSSProperties;
    head2: CSSProperties;
    head3: CSSProperties;
    head4: CSSProperties;
    head5: CSSProperties;
    head6: CSSProperties;
    body2: CSSProperties;
    body3: CSSProperties;
    body4: CSSProperties;
    body5: CSSProperties;
    body6: CSSProperties;
    body7: CSSProperties;
    body8: CSSProperties;
    body9: CSSProperties;
    body10: CSSProperties;
    caption1: CSSProperties;
    caption2: CSSProperties;
    caption3: CSSProperties;
    tooltip: CSSProperties;
    input: CSSProperties;
    button: CSSProperties;
    alerts: CSSProperties;
    overline: CSSProperties;
    portalHeadline: CSSProperties;
  }
}

export interface CustomTypographyOptions extends TypographyOptions {
  head1: TypographyStyleOptions;
  head2: TypographyStyleOptions;
  head3: TypographyStyleOptions;
  head4: TypographyStyleOptions;
  head5: TypographyStyleOptions;
  head6: TypographyStyleOptions;
  body2: TypographyStyleOptions;
  body3: TypographyStyleOptions;
  body4: TypographyStyleOptions;
  body5: TypographyStyleOptions;
  body6: TypographyStyleOptions;
  body7: TypographyStyleOptions;
  body8: TypographyStyleOptions;
  body9: TypographyStyleOptions;
  body10: TypographyStyleOptions;
  caption1: TypographyStyleOptions;
  caption2: TypographyStyleOptions;
  caption3: TypographyStyleOptions;
  tooltip: TypographyStyleOptions;
  input: TypographyStyleOptions;
  button: TypographyStyleOptions;
  alerts: TypographyStyleOptions;
  overline: TypographyStyleOptions;
  portalHeadline: TypographyStyleOptions;
}
