export const MyFamilyTasksIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.0342 12H31.9289C31.4436 8.60771 28.5262 6 24.9998 6H24.4998H23.9998C20.4733 6 17.5559 8.60771 17.0706 12H14.0342C11.2728 12 9.03418 14.2386 9.03418 17V37C9.03418 39.7614 11.2728 42 14.0342 42H34.0342C36.7956 42 39.0342 39.7614 39.0342 37V17C39.0342 14.2386 36.7956 12 34.0342 12ZM27.9563 14C27.9849 13.854 27.9998 13.6882 27.9998 13.5C27.9998 11.567 26.4328 10 24.4998 10C22.5668 10 20.9998 11.567 20.9998 13.5C20.9998 13.6882 21.0146 13.854 21.0432 14H18.9998C17.8952 14 16.9998 14.8954 16.9998 16C16.9998 17.1046 17.8952 18 18.9998 18H29.9998C31.1043 18 31.9998 17.1046 31.9998 16C31.9998 14.8954 31.1043 14 29.9998 14H27.9563ZM29.9474 23.0007C31.6074 22.957 32.4363 25.0148 31.2541 26.2159L22.7941 34.9592C22.2763 35.4943 21.4052 35.4943 20.8874 34.9592L16.5941 30.5014C14.8119 28.7445 17.4452 26.023 19.1452 27.8649L21.5296 30.3292C21.6963 30.5014 21.9852 30.5014 22.1719 30.3292L28.703 23.5794C29.0341 23.2143 29.4696 23.0214 29.9474 23.0007Z"
        fill="#99CA44"
      />
    </svg>
  );
};
