import { trpc } from "../trpc";
import { useCallback } from "react";

export const useUploadDocument = ({ onSuccess }: { onSuccess: () => void }) => {
  const { mutateAsync: uploadDocument } =
    trpc.homeStudy.uploadDocument.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: {
      caseId: string;
      taskId: string;
      file: string;
      fileName: string;
      taskGroupId: string;
      isPrivate: boolean;
    }) =>
      uploadDocument({
        caseId: args.caseId,
        taskId: args.taskId,
        file: args.file,
        fileName: args.fileName,
        taskGroupId: args.taskGroupId,
        isPrivate: args.isPrivate,
      }),
    [uploadDocument]
  );
};

export const useDeleteDocument = ({ onSuccess }: { onSuccess: () => void }) => {
  const { mutateAsync: deleteDocument } =
    trpc.homeStudy.deleteDocument.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: {
      caseId: string;
      taskId: string;
      fileId: string;
      taskGroupId: string;
    }) =>
      deleteDocument({
        ...args,
      }),
    [deleteDocument]
  );
};
