import { Routes } from "./routes.enum";

import { CombinedUserRole } from "../../shared/components/user-provider/types";
import type { UserDataWithProDetails } from "@shared/components";
import type { OrganizationMembership } from "@api";

export type RouteConfig = {
  roles: CombinedUserRole[];
  hasHeader: boolean;
  hasNavigationPanel: boolean;
  hasAccess?: (
    userData: UserDataWithProDetails | null,
    orgData: OrganizationMembership | null
  ) => boolean;
};

type RoutesConfig = Record<Routes, RouteConfig>;

export const routesConfig: RoutesConfig = {
  [Routes.Home]: {
    roles: [CombinedUserRole.Admin],
    hasHeader: true,
    hasNavigationPanel: false,
  },
  [Routes.NotFound]: {
    roles: [],
    hasHeader: true,
    hasNavigationPanel: false,
  },

  [Routes.Admin]: {
    roles: [CombinedUserRole.Admin],
    hasHeader: true,
    hasNavigationPanel: true,
  },

  [Routes.AdminCase]: {
    roles: [CombinedUserRole.Admin],
    hasHeader: true,
    hasNavigationPanel: true,
  },

  [Routes.Family]: {
    roles: [CombinedUserRole.Admin, CombinedUserRole.Family],
    hasHeader: true,
    hasNavigationPanel: false,
  },
  [Routes.FamilyCase]: {
    roles: [CombinedUserRole.Admin, CombinedUserRole.Family],
    hasHeader: true,
    hasNavigationPanel: false,
  },

  [Routes.ProfessionalDashboard]: {
    roles: [
      CombinedUserRole.Admin,
      CombinedUserRole.ProviderHomeBase,
      CombinedUserRole.ProviderHomeBaseConnect,
    ],
    hasHeader: true,
    hasNavigationPanel: true,
  },
  [Routes.ProfessionalCase]: {
    roles: [
      CombinedUserRole.Admin,
      CombinedUserRole.ProviderHomeBase,
      CombinedUserRole.ProviderHomeBaseConnect,
    ],
    hasHeader: true,
    hasNavigationPanel: true,
  },

  [Routes.References]: {
    roles: [],
    hasHeader: true,
    hasNavigationPanel: false,
  },
  [Routes.Connect]: {
    roles: [
      CombinedUserRole.Admin,
      CombinedUserRole.ProviderConnect,
      CombinedUserRole.ProviderHomeBaseConnect,
    ],
    hasHeader: true,
    hasNavigationPanel: true,
    hasAccess: (userData) => !!userData?.proDetails?.org_memberships?.length,
  },
  [Routes.Organization]: {
    roles: [
      CombinedUserRole.Admin,
      CombinedUserRole.ProviderConnect,
      CombinedUserRole.ProviderHomeBaseConnect,
    ],
    hasHeader: true,
    hasNavigationPanel: true,
    hasAccess: (userData, orgData) =>
      userData?.proDetails?.org_memberships?.some(
        (org) => org.org_uuid === orgData?.id && org.is_admin
      ) ?? false,
  },
  [Routes.ProAccountSignUp]: {
    hasHeader: false,
    hasNavigationPanel: false,
    roles: [],
  },
  [Routes.ProAccountSignUpWelcome]: {
    hasHeader: false,
    hasNavigationPanel: false,
    roles: [
      CombinedUserRole.ProviderHomeBaseConnect,
      CombinedUserRole.ProviderConnect,
    ],
  },
  [Routes.ProAccountSignIn]: {
    hasHeader: false,
    hasNavigationPanel: false,
    roles: [],
  },
  [Routes.ProForgotPassword]: {
    hasHeader: false,
    hasNavigationPanel: false,
    roles: [],
  },
  [Routes.SafetyCenter]: {
    hasHeader: true,
    hasNavigationPanel: true,
    roles: [
      CombinedUserRole.Admin,
      CombinedUserRole.ProviderHomeBase,
      CombinedUserRole.ProviderConnect,
      CombinedUserRole.ProviderHomeBaseConnect,
    ],
    hasAccess(userData, orgData) {
      // TODO revert this to enable safety center permissions
      return true;
      //
      //return orgData?.hasSafetyCenterPermission ?? false;
    },
  },
  [Routes.Billing]: {
    hasHeader: true,
    hasNavigationPanel: false,
    roles: [],
  },
};

export const publicRoutes = Object.entries(routesConfig).reduce(
  (acc, [route, { roles }]) => {
    if (!roles.length) {
      acc.push(route);
    }
    return acc;
  },
  [] as string[]
);
