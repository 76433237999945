import { palette, theme } from "@pairtreefamily/ui";

export const styles = {
  iconButton: {
    height: 32,
    width: 28,
    backgroundColor: "white",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    border: `1px solid ${theme.palette.divider}`,
    borderRight: "none",
    color: palette.text.light,
    padding: 0,
    justifyContent: "right",
  },
  icon: (isCollapsed: boolean) => {
    return {
      width: "21px",
      height: "21px",
      color: palette.text.light,
      transform: `scale(${isCollapsed ? 1 : -1})`,
    };
  },
};
