import { ReactNode, useEffect, useRef, useState } from "react";
import { Box, Link } from "@mui/material";
import { styles } from "./styles";
import { useTranslation } from "next-i18next";

type TruncableTextProps = {
  children: ReactNode;
  deps?: string;
  maxLines?: number;
  expanded?: boolean;
};

export const TruncableText = ({
  children,
  maxLines = 3,
  expanded = false,
  deps,
}: TruncableTextProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isTruncable, setIsTruncable] = useState(false);
  const { t } = useTranslation("components");

  useEffect(() => {
    // Wait for potential animations
    setTimeout(() => {
      if (contentRef.current) {
        const computedStyle = window.getComputedStyle(contentRef.current);
        const lineHeight = parseFloat(computedStyle.lineHeight);
        const maxHeight = lineHeight * maxLines;

        setIsTruncable(contentRef.current.scrollHeight > maxHeight);
      }
    });
  }, [maxLines, deps]);

  return (
    <>
      <Box ref={contentRef} sx={styles.text({ isExpanded, maxLines })}>
        {children}
      </Box>

      {isTruncable && (
        <Link
          onClick={() => setIsExpanded(!isExpanded)}
          sx={styles.expandButton}
        >
          {t(`truncableText.${isExpanded ? "viewLess" : "viewMore"}`)}
        </Link>
      )}
    </>
  );
};
