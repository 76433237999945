import { Separator } from "@shared/components";
import { ExpirationDateRequired } from "./ExpirationDateRequired";

export function FileUploadTaskFields() {
  return (
    <>
      <div className="text-left">
        <ExpirationDateRequired />
      </div>
    </>
  );
}
