import { useTranslation } from "next-i18next";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, BoxProps, Typography } from "@mui/material";

import { styles } from "./styles";
import { useMemo } from "react";

export type WorkspaceInfoProps = BoxProps & {
  isToggled?: boolean;
  userFirstName?: string;
  userLastName?: string;
  organizationName?: string;
};

export const WorkspaceInfo = ({
  isToggled,
  organizationName,
  userFirstName,
  userLastName,
  ...props
}: WorkspaceInfoProps) => {
  const { t } = useTranslation("common");

  const userNameInitials = useMemo(() => {
    if (!userFirstName?.length || !userLastName?.length) return "";
    return `${userFirstName[0]}${userLastName[0]}`;
  }, [userFirstName, userLastName]);

  return (
    <Box sx={styles.wrapper} {...props}>
      {organizationName && (
        <Box sx={styles.workspace}>
          <Typography variant="alerts" sx={styles.workspaceLabel}>
            {t("layout.header.workspace.info.label")}
          </Typography>

          <Typography variant="body9" sx={styles.workspaceValue}>
            {organizationName}
          </Typography>
        </Box>
      )}

      <Box sx={styles.avatarWrapper}>
        <Avatar sx={styles.avatarContainer}>
          {userNameInitials ? (
            <Typography variant="body3">{userNameInitials}</Typography>
          ) : (
            <PersonIcon />
          )}
        </Avatar>

        <ArrowDownIcon sx={styles.avatarArrow(Boolean(isToggled))} />
      </Box>
    </Box>
  );
};
