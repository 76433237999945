import { trpc } from "../trpc";
import { useCallback } from "react";
import type { TaskStatus } from "@_types/*";

export const useUpsertElectronicDocumentTask = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { mutateAsync: upsertElectronicDocumentTask } =
    trpc.homeStudy.upsertElectronicDocumentTask.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: {
      task: {
        taskId?: string;
        description?: string;
        taskGroupId: string;
        caseId: string;
        title: string;
        taskType: "electronicDocument";
        templateUuid: string;
        status: TaskStatus;
        areNotificationsEnabled: boolean;
        recipientEmailIds: string[];
        answer: boolean | null;
        question?: string;
        expirationDateRequired: boolean;
        expirationDate?: Date | null;
        isLocked: boolean;
      };
      caseId: string;
    }) =>
      upsertElectronicDocumentTask({
        ...args.task,
        caseId: args.caseId,
      }),
    [upsertElectronicDocumentTask]
  );
};

export const useUpsertFileUploadTask = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { mutateAsync: upsertFileUploadTask } =
    trpc.homeStudy.upsertFileUploadTask.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: {
      task: {
        taskId?: string;
        description?: string;
        taskGroupId: string;
        caseId: string;
        title: string;
        taskType: "fileUpload";
        status: TaskStatus;
        areNotificationsEnabled: boolean;
        recipientEmailIds: string[];
        isPrivate: boolean;
        answer: boolean | null;
        question?: string;
        expirationDateRequired: boolean;
        expirationDate?: Date | null;
        isLocked: boolean;
      };
      caseId: string;
    }) =>
      upsertFileUploadTask({
        ...args.task,
        caseId: args.caseId,
      }),
    [upsertFileUploadTask]
  );
};

export const useUpsertExternalSignerTask = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { mutateAsync: _upsertExternalSignerTask } =
    trpc.homeStudy.upsertExternalSignerTask.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: {
      task: {
        taskId?: string;
        description?: string;
        taskGroupId: string;
        caseId: string;
        title: string;
        taskType: "reference";
        templateUuid: string;
        status: TaskStatus;
        areNotificationsEnabled: boolean;
        recipientEmailIds: string[];
        isPrivate: boolean;
        answer: boolean | null;
        question?: string;
        expirationDateRequired: boolean;
        expirationDate?: Date | null;
        isLocked: boolean;
      };
      caseId: string;
    }) =>
      _upsertExternalSignerTask({
        ...args.task,
        caseId: args.caseId,
      }),
    [_upsertExternalSignerTask]
  );
};
