import { DefaultValues } from "react-hook-form";

import { FormValues } from "./validation-schema";
import { defaultTaskValues } from "./constants";

import type { Task, TaskGroup, TaskType } from "@_types/*";

export const updateFormValues = (
  task?: Task,
  taskGroup?: TaskGroup
): DefaultValues<FormValues> => {
  if (!task)
    return {
      ...defaultTaskValues,
      status: "notStarted",
      taskGroupId: taskGroup?.id,
    };

  const {
    title,
    description,
    taskType,
    status,
    question,
    answer,
    expirationDateRequired,
    expirationDate,
    areNotificationsEnabled,
    recipientEmailIds,
  } = task;

  let templateUuid: string | undefined = undefined;

  switch (task?.taskType) {
    case "electronicDocument":
      templateUuid = task?.templateUuid ?? undefined;
      break;

    case "reference":
      templateUuid = task?.templateUuid ?? undefined;
      break;
  }

  return {
    title,
    taskType,
    templateUuid,
    answer,
    expirationDateRequired,
    expirationDate,
    status,
    areNotificationsEnabled,
    recipientEmailIds,
    isPrivate: task?.isPrivate ?? false,
    taskGroupId: task?.TaskGroup?.id ?? taskGroup?.id,
    description: description ?? undefined,
    question: question ?? "",
  };
};

export const updateShouldBeSentToPrivateFolder = (
  taskType: TaskType,
  taskGroup?: TaskGroup
) => {
  if (!taskType) return false;

  const isProviderOnly = !!taskGroup?.providerOnly;
  const isReferenceType = taskType === "reference";

  return isProviderOnly || isReferenceType;
};
