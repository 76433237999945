import { menuItemClasses } from "@mui/material";
import { typography } from "../typography";
import { palette } from "../palette";

export const MuiList = {
  defaultProps: {},
  styleOverrides: {
    root: {
      [`& .${menuItemClasses.root}`]: {
        ...typography.input,

        "&:hover": {
          backgroundColor: palette.background.light,
        },

        [`&.${menuItemClasses.focusVisible}`]: {
          backgroundColor: palette.primary.background,
        },

        [`&.${menuItemClasses.selected}`]: {
          backgroundColor: palette.background.light,

          "&:hover": {
            backgroundColor: palette.background.light,
          },
        },
      },
    },
  },
};
