export const SendIcon = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.375 6.09375C16.75 6.25 16.9688 6.625 17 7C17 7.40625 16.75 7.78125 16.375 7.9375L2.375 13.9375C2.25 14 2.125 14.0312 2 14.0312C1.71875 14.0312 1.4375 13.9062 1.25 13.6875C0.96875 13.4062 0.90625 12.9375 1.09375 12.5625L4 7L1.09375 1.46875C0.90625 1.09375 0.96875 0.625 1.25 0.3125C1.4375 0.09375 1.71875 0 2 0C2.125 0 2.25 0.03125 2.375 0.09375L16.375 6.09375ZM3.0625 2.03125L5.28125 6.25H12.9688L3.0625 2.03125ZM5.28125 7.75L3.0625 12L12.9688 7.75H5.28125Z"
        fill="currentColor"
      />
    </svg>
  );
};
