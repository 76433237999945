import { palette } from "../palette";
import { typography } from "../typography";

export const MuiTooltip = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      backgroundColor: palette.primary.main,
      borderRadius: "10px",
      ...typography.caption1,
      lineHeight: "17px",
      padding: "10px",
    },
    arrow: {
      color: palette.primary.main,
    },
  },
};
