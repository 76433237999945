import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import type { TaskStatusOption } from "../types";

export function useTaskStatusOptions(): TaskStatusOption[] {
  const { t } = useTranslation("components");

  return useMemo(
    () => [
      {
        id: "notStarted",
        label: t("statusElement.statuses.notStarted"),
      },
      {
        id: "sent",
        label: t("statusElement.statuses.sent"),
      },
      {
        id: "inProgress",
        label: t("statusElement.statuses.inProgress"),
      },
      {
        id: "completed",
        label: t("statusElement.statuses.completed"),
      },
      {
        id: "needsReview",
        label: t("statusElement.statuses.needsReview"),
      },
    ],
    [t]
  );
}
