import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import type { StatusType } from "@pairtreefamily/ui";
import { TaskStatus } from "@_types/*";

type StatusConfig = {
  text: string;
  iconType: StatusType;
};

export const useCurrentStatus = (
  status: TaskStatus,
  isFamily?: boolean
): StatusConfig => {
  const { t } = useTranslation("components");

  const statusesConfig: Record<TaskStatus, StatusConfig> = useMemo(
    () => ({
      completed: {
        text: t("statusElement.statuses.completed"),
        iconType: "success",
      },
      sent: {
        text: isFamily
          ? t("statusElement.statuses.notStarted")
          : t("statusElement.statuses.sent"),
        iconType: "warning",
      },
      inProgress: {
        text: t("statusElement.statuses.inProgress"),
        iconType: "warning",
      },
      notStarted: {
        text: isFamily
          ? t("statusElement.statuses.notIssued")
          : t("statusElement.statuses.notStarted"),
        iconType: "neutral",
      },
      needsReview: {
        text: isFamily
          ? t("statusElement.statuses.pendingApproval")
          : t("statusElement.statuses.needsReview"),
        iconType: "warning",
      },
    }),
    [isFamily, t]
  );

  return statusesConfig[status];
};
