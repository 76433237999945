import { useCallback, useMemo, useState } from "react";

export function useTabs<T>(initialTab: T) {
  const [currentTab, setCurrentTab] = useState<T>(initialTab);

  const handleTabChange = useCallback((newValue: T) => {
    setCurrentTab(newValue);
  }, []);

  return useMemo(() => {
    return {
      currentTab,
      handleTabChange,
    };
  }, [currentTab, handleTabChange]);
}
