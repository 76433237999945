export const HomeBaseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="1 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0687 19.3543H6.2375V10.1486L12.95 5.54571L19.6625 10.1486V19.3543H14.0687ZM4 10.1486V19.3543C4 19.9169 4.21909 20.3985 4.65727 20.7991C5.09544 21.1997 5.62219 21.4 6.2375 21.4H14.0687H19.6625C20.2778 21.4 20.8046 21.1997 21.2427 20.7991C21.6809 20.3985 21.9 19.9169 21.9 19.3543V10.1486C21.9 9.82467 21.8208 9.51781 21.6623 9.228C21.5038 8.93819 21.2847 8.69952 21.005 8.512L14.2925 3.90914C13.9009 3.63638 13.4534 3.5 12.95 3.5C12.4466 3.5 11.9991 3.63638 11.6075 3.90914L4.895 8.512C4.61531 8.69952 4.39622 8.93819 4.23773 9.228C4.07924 9.51781 4 9.82467 4 10.1486ZM12.1 14.85L10.675 13.425C10.575 13.325 10.4667 13.25 10.35 13.2C10.2333 13.15 10.1125 13.125 9.9875 13.125C9.8625 13.125 9.7375 13.15 9.6125 13.2C9.4875 13.25 9.375 13.325 9.275 13.425C9.09167 13.6083 9 13.8417 9 14.125C9 14.4083 9.09167 14.6417 9.275 14.825L11.4 16.975C11.6 17.175 11.8333 17.275 12.1 17.275C12.3667 17.275 12.6 17.175 12.8 16.975L17.45 12.725C17.45 12.4583 17.4333 12.2 17.4 11.95C17.3667 11.7 17.2583 11.4833 17.075 11.3C16.875 11.1 16.6375 11 16.3625 11C16.0875 11 15.85 11.1 15.65 11.3L12.1 14.85Z"
        fill="currentColor"
      />
    </svg>
  );
};
