import { z } from "zod";
import { requiredString } from "@shared/validation-schemas";

export const validationSchema = z.object({
  firstName: requiredString,
  lastName: requiredString,
  email: z.string().email(),
  note: z.string().optional(),
});

export type FormValues = z.infer<typeof validationSchema>;
