import { alpha, selectClasses } from "@mui/material";
import { palette } from "../palette";
import { typography } from "../typography";

export const MuiMenu = {
  defaultProps: {
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "right" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "right" as const,
    },
  },
  styleOverrides: {
    paper: {
      borderRadius: 10,
      border: `1px solid ${palette.divider}`,
      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
    },
    root: {
      "& .MuiTypography-caption2": {
        padding: "8px 24px",
        textTransform: "uppercase",
        color: alpha(palette.text.light, 0.5),
        userSelect: "none",
      },
    },
    list: {
      "& .MuiMenuItem-root": {
        minHeight: 36,
        padding: "8px 24px",
        backgroundColor: "transparent",
        gap: 16,
        justifyContent: "space-between",

        "&:hover": {
          backgroundColor: "transparent",
          color: palette.primary.main,
        },

        "&.Mui-selected": {
          backgroundColor: "transparent",
          color: palette.primary.main,

          "&:hover": {
            backgroundColor: "transparent",
          },
        },

        "&.Mui-focusVisible": {
          backgroundColor: "transparent",
          color: palette.primary.main,
        },

        "& .MuiTypography-root": {
          ...typography.body9,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },

        "& .MuiListItemIcon-root": {
          minWidth: 16,
          color: "inherit",
        },
      },

      "& .MuiDivider-root:last-of-type": {
        display: "none",
      },
    },
  },
};
