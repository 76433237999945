import { trpc } from "../trpc";
import { UserData } from "./types";
import { Result } from "@pairtreefamily/utils";
import { useCallback, useState } from "react";

export const useGetAuthUserData = (options?: { enabled: boolean }) => {
  return trpc.homeStudy.getAuthUserData.useQuery<
    void,
    Result<UserData, string>
  >(undefined, { ...options, retry: false });
};

export const useGetAuthUserDataAsync = () => {
  const [isEnabled, setIsEnabled] = useState(false);

  const { refetch } = useGetAuthUserData({
    enabled: isEnabled,
  });

  return useCallback(async () => {
    setIsEnabled(true);

    const data = await refetch();

    setIsEnabled(false);

    return data;
  }, [refetch]);
};
