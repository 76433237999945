import { ChangeEvent, memo, ReactNode, useCallback, useMemo } from "react";
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  FormControlLabel,
} from "@mui/material";

const labelStyles = {
  marginLeft: 0,
  marginRight: 1,
};

const componentProps = {
  typography: {
    variant: "input" as const,
    marginRight: 1,
  },
};

export type SwitchProps = {
  label?: ReactNode;
  onChange: (isChecked: boolean) => void;
  labelPlacement?: "end" | "start" | "top" | "bottom";
} & Omit<MuiSwitchProps, "onChange">;

export const Switch = memo(
  ({ onChange, label, labelPlacement, ...rest }: SwitchProps) => {
    const inputProps = useMemo(() => {
      if (typeof label === "string") {
        return { "aria-label": label };
      }
      return undefined;
    }, [label]);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
      },
      [onChange]
    );

    if (!label) {
      return (
        <MuiSwitch inputProps={inputProps} onChange={handleChange} {...rest} />
      );
    }

    return (
      <FormControlLabel
        componentsProps={componentProps}
        sx={labelStyles}
        control={
          <MuiSwitch
            inputProps={inputProps}
            onChange={handleChange}
            {...rest}
          />
        }
        label={label}
        labelPlacement={labelPlacement ?? "end"}
      />
    );
  }
);

Switch.displayName = "Switch";
