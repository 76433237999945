export type TextInputProps = {
  value: string;
  onChange: (value: string) => void;
  label: JSX.Element | string;
};

export function TextInput(props: TextInputProps) {
  const { value, onChange, label } = props;
  return (
    <label className="my-4 flex flex-col items-start">
      <span className="text-body-4-semi">{label}</span>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </label>
  );
}
