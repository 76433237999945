export const getMatchText = ({
  searchQuery,
  value,
}: {
  searchQuery: string;
  value: string;
}) => {
  let beforeMatch = "";
  let matchText = "";
  let afterMatch = "";

  const matchIndex = value.toLowerCase().indexOf(searchQuery.toLowerCase());

  if (matchIndex >= 0) {
    beforeMatch = value.slice(0, matchIndex);
    matchText = value.slice(matchIndex, matchIndex + searchQuery.length);
    afterMatch = value.slice(matchIndex + searchQuery.length);
  }

  return { beforeMatch, matchText, afterMatch };
};
