import {
  palette,
  theme,
  typography,
} from "@pairtreefamily/ui/src/components/theme";

export const butonStyle = {
  borderRadius: "24px",
  borderWidth: "2px",
  width: "100%",
  padding: `${theme.spacing(2)}`,
  ...typography.body9,
  display: "flex",
  gap: 1,
  "&.Mui-disabled": {
    backgroundColor: `${palette.divider} !important`,
    color: palette.text.disabled,
  },
};

export const googleButtonStyle = {
  borderColor: palette.divider,
  color: palette.success.main,
  ...butonStyle,
};

export const loginButtonStyle = {
  backgroundColor: `${palette.primary.main} !important`,
  color: palette.primary.contrastText,
  marginTop: 3,
  ...butonStyle,
};
