import { useWebsiteRegExp } from "@shared/configs";
import { requiredString } from "./requiredString";

export const websiteValidation = requiredString.refine(
  (data) => useWebsiteRegExp.test(data),
  {
    params: {
      i18n: {
        key: "custom.invalidWebsite",
      },
    },
  }
);
