import { ReactNode, useEffect, useState, startTransition, memo } from "react";
import { Loading } from "../mui";

export type ConcurrentLoaderProps = {
  children: ReactNode;
};

/**
 * ConcurrentLoader is a component that shows a loading spinner while the children are being loaded.
 * Use this component ONLY when the children are heavy to load!
 * Better solution is always optimizing children components.
 * @param children
 * @constructor
 */
export const ConcurrentLoader = memo(({ children }: ConcurrentLoaderProps) => {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    startTransition(() => {
      // Ensures the loader is shown before heavy computation begins
      setShowComponent(true);
    });
  }, []);

  if (!showComponent) {
    return <Loading />;
  }

  return <>{children}</>;
});

ConcurrentLoader.displayName = "ConcurrentLoader";
