import { palette } from "../../theme";

export const styles = {
  PaperProps: {
    // Taken from the design, color is not in UI kit
    sx: { boxShadow: "0px 16px 19px 0px #111F3440" },
  },
  slotProps: {
    backdrop: { sx: { backgroundColor: "transparent" } },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // This should be updated when Task View will have document Viewer
    maxWidth: "520px",
    width: "520px",
    height: "100%",
  },
  contentWrapper: {
    height: "100%",
    overflowY: "scroll",
    px: 5,
    pt: 4,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    gap: 2,
    justifyContent: "space-between",
    mb: 2,
  },
  iconButton: { height: "32px", width: "32px" },
  icon: { height: "32px", width: "32px" },
  actionsWrapper: {
    height: "88px",
    borderTop: `1px solid ${palette.text.disabled}`,
    backgroundColor: palette.background.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 3,
  },
};
