import {
  paperClasses,
  snackbarContentClasses,
  svgIconClasses,
} from "@mui/material";
import { palette } from "../palette";
import { typography } from "../typography";

export const MuiSnackbar = {
  styleOverrides: {
    root: {
      borderRadius: "8px",

      [`& .${paperClasses.root}`]: {
        maxWidth: "748px",
        //TODO: Refactor use ProBlue palette here
        boxShadow: `0px 2px 2px 0px ${palette.primary.snackbarShadow}`,
        ...typography.body6,
        flexFlow: "nowrap",
      },
      [`& .${svgIconClasses.root}`]: {
        fontSize: "24px",
      },

      [`& .${snackbarContentClasses.message}`]: {
        whiteSpace: "pre-line" as const,
      },
    },
  },
};
