import { palette } from "@pairtreefamily/ui";

export const styles = {
  iconButton: {
    alignSelf: "center",
    color: palette.text.primary,
    "&:hover": { color: palette.primary.main },
  },
  modalContentWrapper: { display: "flex", gap: 3 },
};
