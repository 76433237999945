import { FirebaseOptions } from "firebase/app";

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;

const firebaseConfig: FirebaseOptions =
  environment === "production"
    ? {
        apiKey: "AIzaSyCFdxRiNCa7YCLdvPjKlJ9g4O9LKewUb7Y",
        authDomain: "pairtree-production.firebaseapp.com",
        projectId: "pairtree-production",
        storageBucket: "pairtree-production.appspot.com",
        messagingSenderId: "21351806198",
        appId: "1:21351806198:web:cfa9468ad609ebf098e8d7",
        measurementId: "G-R5DD9VFQXE",
      }
    : {
        apiKey: "AIzaSyC-r0P3BbFx-6OKYeNeeLy89jg9GO9YEvA",
        authDomain: "pairtree-development.firebaseapp.com",
        projectId: "pairtree-development",
        storageBucket: "pairtree-development.appspot.com",
        messagingSenderId: "781051164274",
        appId: "1:781051164274:web:35fe6602ed2ce3d3dbb4eb",
        measurementId: "G-JHCX8M4N8K",
      };

export const appConfig = {
  companyName: "PairTree",
  appName: "Home Study",
  firebase: firebaseConfig,
  apiUrl: process.env.NEXT_PUBLIC_HOME_STUDY_TRPC_API_URL,
  coreApiUrl: process.env.NEXT_PUBLIC_CORE_API_URL,
  coreApiVersion: "v4",
  professionalContactSlackUrl:
    "https://pairtreefamily.slack.com/archives/C0588U9PA3C",
  companyContactEmail: "homebase@pairtreefamily.com",
  supportContactEmail: "support@pairtreefamily.com",
  pandaDocUrl: "https://app.pandadoc.com/s/",
  appPairtree: "https://app.pairtreefamily.com/welcome/identity",
  pandaDocRootTemplateFolder: process.env
    .NEXT_PUBLIC_PANDADOC_ROOT_TEMPLATE_FOLDER_ID as string,
  daysWhenTaskConsideredExpiring: 60,
  useAuthEmulator: !!process.env.NEXT_PUBLIC_USE_FIREBASE_AUTH_EMULATOR,
  pairTreeFamilySiteUrl: process.env.NEXT_PUBLIC_PAIR_TREE_FAMILY_SITE_URL,
  pairtreeTerms: "https://pairtreefamily.com/terms-and-conditions",
  pairtreePrivacy: "https://pairtreefamily.com/privacyPolicy",
  pairtreeHelp: "hello@pairtreefamily.com",
  stripe: {
    managementPortalUrl: process.env.NEXT_PUBLIC_STRIPE_MANAGEMENT_PORTAL_URL,
    servicesUrl: process.env.NEXT_PUBLIC_STRIPE_SERVICES_URL,
  },
};
