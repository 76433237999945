import {
  FormValues,
  validationSchemaForgotPassword,
} from "./validation-schema";
import { Button, Form, TextField, useFirebaseAuth } from "@pairtreefamily/ui";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { FirebaseApp, FirebaseError } from "firebase/app";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export type ResetPasswordProps = {
  onCancel?: () => void;
  onSuccess?: (email: string) => void;
  onError?: (error: FirebaseError | any) => void;
  app: FirebaseApp;
};

export function ForgotPasswordForm({
  app,
  onCancel,
  onSuccess,
  onError,
}: ResetPasswordProps) {
  const { t } = useTranslation("components");

  const { sendPasswordResetEmail } = useFirebaseAuth({ app });

  const [isLoading, setIsLoading] = useState(false);

  const formMethods = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(validationSchemaForgotPassword),
  });

  const handleResetPassword = useCallback(
    async ({ email }: FormValues) => {
      try {
        setIsLoading(true);
        await sendPasswordResetEmail(email);
        setIsLoading(false);
        onSuccess?.(email);
        formMethods.reset();
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        onError?.(e);
      }
    },
    [onError, onSuccess, sendPasswordResetEmail, formMethods]
  );

  return (
    <Form<FormValues> formMethods={formMethods} onSubmit={handleResetPassword}>
      <TextField
        name="email"
        label={t("authorization.forgotPassword.email")}
        placeholder={t("authorization.forgotPassword.emailPlaceholder")}
      />
      <Box display="flex" gap={3} mt={3}>
        <Button fullWidth variant="outlined" onClick={onCancel} type="button">
          {t("authorization.forgotPassword.cancelButtonLabel")}
        </Button>

        <Button fullWidth type="submit" isLoading={isLoading}>
          {t("authorization.forgotPassword.submitButtonLabel")}
        </Button>
      </Box>
    </Form>
  );
}
