import { format } from "date-fns";

export const formatDateToView = (date: Date | null) => {
  if (!date) return null;

  try {
    return format(date, "P");
  } catch (e) {
    return null;
  }
};
