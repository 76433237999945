import { FormControlLabel, Radio } from "@mui/material";
import { RadioProps } from "@mui/material/Radio/Radio";
import { useCallback, useMemo } from "react";
import * as React from "react";

const formControlStyles = {
  marginLeft: "-5px",
};

const componentProps = {
  typography: {
    variant: "input" as const,
    paddingLeft: "4px",
  },
};

export type RadioInputNewProps = {
  label: string;
  onChange: (value: string | boolean | null, checked: boolean) => void;
} & Omit<RadioProps, "inputProps" | "onChange">;

// Temporary name for this component until we remove the old one
export const RadioInput = ({
  label,
  onChange,
  value,
  ...rest
}: RadioInputNewProps) => {
  const inputProps = useMemo(() => {
    return { "aria-label": label };
  }, [label]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const { value: eventValue } = event.target;
      const convertedValue =
        eventValue === "true"
          ? true
          : eventValue === "false"
          ? false
          : eventValue === "null"
          ? null
          : eventValue;

      onChange?.(convertedValue, checked);
    },
    [onChange]
  );

  return (
    <FormControlLabel
      sx={formControlStyles}
      componentsProps={componentProps}
      control={
        <Radio
          inputProps={inputProps}
          onChange={handleChange}
          value={`${value}`}
          {...rest}
        />
      }
      label={label}
    />
  );
};
