import { useCallback } from "react";
import { trpc } from "../trpc";

type UseReplaceTaskTitlesArgs = {
  caseId: string;
  onSuccess: () => void;
};
export const useReplaceTaskTitles = ({
  caseId,
  onSuccess,
}: UseReplaceTaskTitlesArgs) => {
  const { mutateAsync: replaceTasksTitle } =
    trpc.homeStudy.replaceTasksTitle.useMutation({
      onSuccess: onSuccess,
    });

  return useCallback(
    async (args: { placeholder: string; value: string; caseId: string }) => {
      const { placeholder, value } = args;
      return await replaceTasksTitle({ placeholder, value, caseId });
    },
    [replaceTasksTitle, caseId]
  );
};
