import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "next-i18next";

import { DateInputField, RadioListField } from "@pairtreefamily/ui";

import type { FormValues } from "../validation-schema";
import { Box } from "@mui/material";

export function ExpirationDateRequired() {
  const { t } = useTranslation(["common", "components"]);
  const { watch } = useFormContext<FormValues>();

  const expirationDateRequired = watch("expirationDateRequired");

  const fieldsStatesList = useMemo(
    () => [
      { value: true, label: t("common:labels.yes") },
      { value: false, label: t("common:labels.no") },
    ],
    [t]
  );

  return (
    <div>
      <h4 className="text-body-3-semi">
        <strong>{t("components:expirationDateRequired.caption")}</strong>
      </h4>

      <p className="text-body-4 my-2">
        <em>{t("components:expirationDateRequired.description")}</em>
      </p>

      <Box sx={{ display: "flex", gap: 1 }}>
        <RadioListField
          name="expirationDateRequired"
          options={fieldsStatesList}
        />
      </Box>

      {expirationDateRequired && (
        <DateInputField name="expirationDate" label="" />
      )}
    </div>
  );
}
