import type { SortDirection } from "@tanstack/react-table";

export interface InfinitePaginationResponse<T> {
  pageIndex: number;
  pageSize: number;
  items: T[];
}

export interface SortingState {
  sortBy: string;
  sortDirection: SortDirection;
}

export interface PaginationState {
  limit: number;
  page: number;
}

export enum OrganizationService {
  HomeStudy = "home_study",
  Matching = "matching",
  Placement = "placement",
  Support = "support",
}

export enum OrganizationTypes {
  HomeStudyProvider = "Home Study Provider",
  Agency = "Agency",
  Attorney = "Attorney",
}

export interface Organization {
  uuid: string;
  description: string;
  website: string;
  contactEmail: string;
  contactPhone: string;
  contactPhoneExt?: string;
  isPublished: boolean;
  states: string[];
  services: OrganizationService[];
  name: string;
  orgType: OrganizationTypes;
}

export interface PendingInvitesRequest {
  uuid: string;
}

export interface PendingInvitation {
  uuid: string;
  emailAddress: string;
  firstName?: string;
  lastName?: string;
}

export interface OrganizationInviteRequest {
  uuid: string;
  email: string;
  firstName?: string;
  lastName?: string;
  isAdmin: boolean;
  isFamily: boolean;
}

export interface ResendOrganizationInviteRequest {
  organizationUuid: string;
  inviteUuid: string;
}

export interface RevokeOrganizationInviteRequest {
  organizationUuid: string;
  inviteUuid: string;
}

export interface OrganizationMember {
  proUuid: string;
  orgUuid: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  title: string;
  contactEmail: string;
  contactPhone: string;
  contactPhoneExt?: string;
  isAdmin: boolean;
  orgName: string;
}

export interface UnlinkFamFromOrgRequest {
  famUuid: string;
  organizationUuid: string;
}

export interface RequestFamilyInformationRequest {
  organizationUuid: string;
  accountUuid: string;
  subject: string;
  senderAddress: string;
  phoneNumber: string;
  message: string;
}

export interface OrganizationDataForFamilyResponse {
  phoneNumber: string;
  senderAddress: string;
}
export interface RequestError {
  response: {
    status: number;
  };
}

export interface NewSafetyCenterThreadRequest {
  title: string;
  description: string;
  proUuid: string;
  organizationUuid: string;
}

export interface Author {
  firstName: string;
  lastName: string;
  // Link could be added in future
  avatar: string | null;
  emailAddress: string;
}

export interface ThreadListItem {
  uuid: string;
  title: string;
  author: Author;
  description: string;
  orgName: string;
  commentsCount: number;
  updatedAt: string;
}

export type ThreadsListRequest = {
  query: Partial<PaginationState>;
  body: { searchQuery: string };
  organizationUuid: string;
};

export type ThreadsListResponse = {
  pageSize: number;
  pageIndex: number;
  items: ThreadListItem[];
};

export interface ThreadDetailsRequest {
  organizationUuid: string;
  id: string;
}

export interface ThreadComment {
  uuid: string;
  description: string;
  createdAt: string;
  orgName: string;
  author: Author;
}

export interface ThreadDetailsResponse
  extends Omit<ThreadListItem, "commentsCount"> {
  description: string;
  comments: ThreadComment[];
}

export interface NewCommentRequest {
  threadId: string;
  description: string;
  proUuid: string;
  organizationUuid: string;
}
