import { Remark } from "react-remark";

interface Props {
  content: string;
}
const Markdown = ({ content }: Props) => (
  <Remark
    rehypeReactOptions={{
      components: {
        a: (props: any) => (
          <a
            className="text-teal"
            style={{ textDecoration: "underline" }}
            target="_blank"
            {...props}
          />
        ),
        p: (props: any) => <p {...props} />,
      },
    }}
  >
    {content}
  </Remark>
);

export default Markdown;
