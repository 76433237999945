import { useCallback, useMemo } from "react";
import {
  trpc,
  useGetCaseCompletion,
  useGetDocumentHomeLink,
  useGetPrivateDocumentLink,
} from "@api";

export type UseCaseArgs = {
  caseId: string;
  includePrivateDocs?: boolean;
};

export type UseCaseReturn = ReturnType<typeof useCase>;

export const useCase = ({ caseId, includePrivateDocs }: UseCaseArgs) => {
  // get Completed Documents link
  const { data: documentHomeLink } = useGetDocumentHomeLink(caseId);

  const { data: privateDocumentLink } = useGetPrivateDocumentLink({
    caseId: caseId,
    enabled: !!includePrivateDocs,
  });

  const { data: isCaseCompleted } = useGetCaseCompletion({
    caseId: caseId,
  });

  return useMemo(() => {
    return { documentHomeLink, privateDocumentLink, isCaseCompleted };
  }, [documentHomeLink, privateDocumentLink, isCaseCompleted]);
};
