import {
  pickersCalendarHeaderClasses,
  dayPickerClasses,
} from "@mui/x-date-pickers";
import { typography } from "../typography";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { palette } from "../palette";
import { alpha } from "@mui/material";

export const MuiDateCalendar = {
  defaultProps: {
    slots: {
      switchViewIcon: ArrowDropDownRoundedIcon,
      leftArrowIcon: KeyboardArrowLeftRoundedIcon,
      rightArrowIcon: KeyboardArrowRightRoundedIcon,
    },
    slotProps: {
      switchViewButton: {
        color: "success",
        sx: { color: "success.light" },
      },
      day: {
        sx: { ...typography.caption1 },
      },
      previousIconButton: {
        color: "success",
      },
      leftArrowIcon: {
        sx: { color: "success.light" },
      },
      nextIconButton: {
        color: "success",
      },
      rightArrowIcon: {
        color: "success",
        sx: { color: "success.light" },
      },
    },
  },
  styleOverrides: {
    root: {
      width: "296px",

      [`& .${dayPickerClasses.weekDayLabel}`]: {
        ...typography.caption3,
        color: palette.text.light,
      },

      [`& .${dayPickerClasses.header}`]: {
        marginBottom: "8px",
        borderBottom: `1px solid ${palette.divider}`,
      },

      [`& .MuiPickersDay-dayOutsideMonth`]: {
        pointerEvents: "none",
        color: palette.text.disabled,
      },

      [`& .MuiPickersDay-today`]: {
        border: `1px solid ${palette.primary.main}`,
        color: palette.primary.main,
      },
      [`& .${pickersCalendarHeaderClasses.label}`]: {
        ...typography.caption2,
      },

      [`& .MuiPickersYear-yearButton`]: {
        ...typography.caption1,
        margin: 0,

        "&:hover": {
          backgroundColor: alpha(
            palette.primary.light,
            palette.action.hoverOpacity
          ),
        },
      },
    },
  },
};
