import { palette, theme } from "@pairtreefamily/ui";

export const styles = {
  wrapper: {
    backgroundColor: palette.background.default,
    width: "100%",
    height: "100%",
    position: "relative",
    paddingY: theme.spacing(6),
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  iconWrapper: {
    position: "absolute",
    top: theme.spacing(1),
    right: 0,
  },
};
