import { palette } from "../palette";
import { typography } from "../typography";

export const MuiLink = {
  styleOverrides: {
    root: {
      cursor: "pointer",
      color: palette.primary.main,
      textDecoration: "none",
      ...typography.body4,

      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
};
