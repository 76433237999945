import { typography } from "../typography";
import { palette } from "../palette";
import { accordionClasses, accordionSummaryClasses } from "@mui/material";

export const MuiAccordionSummary = {
  styleOverrides: {
    root: {
      height: "128px",
      padding: 0,
      ...typography.body1,
      fontWeight: "700",

      [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
        color: palette.success.light,
      },

      [`& .${accordionSummaryClasses.content}`]: {
        margin: 0,
      },

      [`&.${accordionSummaryClasses.focusVisible}`]: {
        backgroundColor: "white",
      },
    },
  },
};

export const MuiAccordionDetails = {
  styleOverrides: {
    root: {
      padding: "0 0 32px",
    },
  },
};

export const MuiAccordion = {
  styleOverrides: {
    [`& .${accordionClasses.expanded}`]: {
      margin: 0,
    },
    root: {
      borderTop: `1px solid ${palette.divider}`,
      borderBottom: `1px solid ${palette.divider}`,
      borderRadius: "0 !important",
      boxShadow: "none",

      ["&::before"]: {
        display: "none",
      },
    },
  },
};
