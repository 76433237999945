import { UserDataWithProDetails } from "./user-provider";
import { UserRole } from "@api";
import { CombinedUserRole } from "./types";

export const getCombinedUserRole = (
  userData: Omit<UserDataWithProDetails, "combinedUserRole">
) => {
  if (userData.role === UserRole.Admin) {
    return CombinedUserRole.Admin;
  }

  if (userData.role === UserRole.Family) {
    return CombinedUserRole.Family;
  }

  if (userData.isHomeBaseUser) {
    if (!userData.proDetails) {
      return CombinedUserRole.ProviderHomeBase;
    }

    return CombinedUserRole.ProviderHomeBaseConnect;
  }

  return CombinedUserRole.ProviderConnect;
};
