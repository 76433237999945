import { useMemo } from "react";
import { Task, ExtendedTaskGroup } from "@_types/*";

type OrderNumberHookProps = {
  task?: Task;
  taskGroupId?: string;
  allTaskGroups?: ExtendedTaskGroup[];
};

export const useOrderNumber = (props: OrderNumberHookProps) => {
  const { taskGroupId, task, allTaskGroups } = props;

  return useMemo(() => {
    if (taskGroupId === task?.TaskGroup?.id) {
      return task?.orderNumber ?? 1;
    }

    const group = allTaskGroups?.find((item) => item.id === taskGroupId);

    return group ? group.tasks.length : 1;
  }, [allTaskGroups, taskGroupId, task?.TaskGroup?.id, task?.orderNumber]);
};
