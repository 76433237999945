import { useState, useEffect } from "react";
import { type OrganizationMembership } from "@api";
import { useUserData } from "@shared/components";
import { useLocalStorage } from "@shared/hooks";

export const useGetUserOrganizationsList = (): {
  data: OrganizationMembership[] | null;
  activeOrganizationId: string | null;
  setActiveOrganizationId: (activeOrgId: string | null) => void;
  isLoading: boolean;
} => {
  const { userData, isUserDataLoading } = useUserData();
  const [activeOrganizationId, setActiveOrganizationId] = useLocalStorage<
    string | null
  >("active-org-id", null);

  const [data, setData] = useState<OrganizationMembership[] | null>(null);

  useEffect(() => {
    const orgMemberships = userData?.proDetails?.org_memberships;

    if (orgMemberships?.length) {
      setData(
        orgMemberships.map((om) => ({
          id: om.org_uuid,
          name: om.org_name,
          firstName: om.first_name,
          lastName: om.last_name,
          hasSafetyCenterPermission: om.has_safety_center_permission,
        }))
      );

      // Set the first organization as the active organization if there is no active organization
      const org = orgMemberships[0];

      const isActiveOrganizationExist = activeOrganizationId
        ? orgMemberships.some((org) => {
            return org.org_uuid === activeOrganizationId;
          })
        : false;

      if (!activeOrganizationId || !isActiveOrganizationExist) {
        setActiveOrganizationId(org.org_uuid);
      }
    }

    //If the user has no organizations, clear the active organization
    if (
      userData &&
      !isUserDataLoading &&
      !orgMemberships?.length &&
      activeOrganizationId
    ) {
      setActiveOrganizationId(null);
    }
  }, [
    activeOrganizationId,
    isUserDataLoading,
    setActiveOrganizationId,
    userData,
  ]);

  return {
    data,
    activeOrganizationId,
    setActiveOrganizationId,
    isLoading: isUserDataLoading,
  };
};
