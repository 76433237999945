import { z } from "zod";

export const requiredString = z
  .string()
  .trim()
  .refine(
    (value) => {
      return !!value;
    },
    {
      params: {
        i18n: "custom.required",
      },
    }
  );
