import { useMutation, useQuery } from "react-query";
import { useCoreClient, RequestFamilyInformationRequest } from "../shared";

import type {
  FamilyListCountRequestArgs,
  FamilyListFilterPayload,
  FamilyListRequestPayload,
  FamilyProfileDetailsRequest,
} from "./types";
import { useGetOrganization } from "../organization";
import { useOrganizationsData } from "@shared/components";

export const useGetFamilyList = (props: FamilyListRequestPayload) => {
  const { CoreClient } = useCoreClient();

  return useQuery({
    queryKey: ["family-list", props],
    queryFn: () => CoreClient.getFamilyList(props),
    enabled: props.organizationUuid !== "",
  });
};

export const useUnlinkFamFromOrg = () => {
  const { CoreClient } = useCoreClient();
  const { data } = useGetOrganization();

  const { mutateAsync } = useMutation((famUuid: string) =>
    CoreClient.unlikFamFromOrg({ famUuid, organizationUuid: data?.uuid ?? "" })
  );

  return mutateAsync;
};

export const useGetFamilyListCount = (props: FamilyListCountRequestArgs) => {
  const { CoreClient } = useCoreClient();

  return useQuery(["family-list-count", props], () =>
    CoreClient.getFamilyListCount(props)
  );
};

export const useGetFamilyProfileDetails = (
  props: FamilyProfileDetailsRequest
) => {
  const { CoreClient } = useCoreClient();

  return useQuery({
    queryKey: ["family-profile-details", props.slug],
    queryFn: () => CoreClient.getFamilyProfileDetails(props),
    enabled: Boolean(props.slug && props.organizationUuid !== ""),
  });
};

//TODO: implement actual call when BE ready
const data = {
  data: [
    {
      uuid: "1",
      emailAddress: "jdoe@email.com",
      firstName: "John",
      lastName: "Doe",
    },
    {
      uuid: "2",
      emailAddress: "name@email.com",
      firstName: "First Name",
      lastName: "Last Name",
    },
  ],
  error: null,
};

export const useGetFamiliesPendingInvitation = (orgUuid: string) => {
  //TODO: implement actual call when BE ready
  console.log("orgUuid", orgUuid);
  return data;
};

export const useRequestFamilyInformation = () => {
  const { CoreClient } = useCoreClient();
  const { userOrganizationData } = useOrganizationsData();

  const { mutateAsync } = useMutation(
    (args: Omit<RequestFamilyInformationRequest, "organizationUuid">) =>
      CoreClient.requestFamilyInformation({
        ...args,
        organizationUuid: userOrganizationData?.id || "",
      })
  );

  return mutateAsync;
};

export const useGetOrganizationDataForFamilyRequest = () => {
  const { CoreClient } = useCoreClient();
  const { userOrganizationData } = useOrganizationsData();

  return useQuery(
    `get-organization-data-for-family-request-${userOrganizationData?.id}`,
    () =>
      CoreClient.getOrganizationDataForFamilyRequest({
        organizationUuid: userOrganizationData?.id || "",
      }),
    {
      enabled: !!userOrganizationData?.id,
    }
  );
};
