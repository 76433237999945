import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { ErrorMessage } from "../error-message";
import { Checkbox, CheckboxProps } from "../../mui/checkbox";

export interface CheckboxFieldProps
  extends Omit<CheckboxProps, "onCheckboxChange" | "isChecked" | "onChange"> {
  name: string;
}

export const CheckboxField = ({ name, ...rest }: CheckboxFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <>
          <Checkbox
            checked={Boolean(field.value)}
            onChange={field.onChange}
            name={name}
            {...rest}
          />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </>
      );
    },
    [name, rest]
  );

  return <Controller name={name} render={render} />;
};
