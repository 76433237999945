import { usePathname } from "next/navigation";
import Link from "next/link";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { styles } from "./styles";

import type { ReactNode } from "react";
import type { Routes } from "@features/routing";

export type MenuItemProps = {
  icon: ReactNode;
  label: ReactNode;
  route: Routes;
  isCollapsed: boolean;
};

export const MenuItem = ({
  icon,
  label,
  route,
  isCollapsed,
}: MenuItemProps) => {
  const pathname = usePathname();
  const isActiveRoute = !!pathname && pathname.startsWith(route);

  return (
    <ListItem sx={styles.wrapper}>
      <ListItemButton
        component={Link}
        href={route}
        selected={isActiveRoute}
        sx={styles.button(isActiveRoute)}
      >
        <ListItemIcon sx={styles.icon}>{icon}</ListItemIcon>

        <ListItemText
          primary={label}
          sx={styles.text(isCollapsed)}
          primaryTypographyProps={{
            variant: "body9",
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
