import { useMemo } from "react";
import { z } from "zod";
import {
  BaseDreamcatcherInput,
  DreamcatcherInputPlugin,
  useDreamcatcherInputPathAt,
} from "../..";
import { DateInputLegacy } from "../../../components";
import type { ValidationError } from "../../../components/legacy/DateInput";
import {
  DreamcatcherInputBaseMetadataSchema,
  DreamcatcherInputMetadataWithLabelSchema,
} from "../metadata";

// Declare the inputType; useful for having one spot to change the type.
type inputType = "date";
// Declare the input (metadata) shape.
const DreamcatcherDateInputMetadataSchema =
  DreamcatcherInputMetadataWithLabelSchema.merge(
    DreamcatcherInputBaseMetadataSchema
  );

// Declare the input (metadata) type - usually derived from the schema.
export type DreamcatcherDateInputMetadata = z.infer<
  typeof DreamcatcherDateInputMetadataSchema
>;

export type DreamcatcherDateInput = BaseDreamcatcherInput<
  DreamcatcherDateInputMetadata,
  inputType
>;

// Declare the Input component.
// TODO extract the date input UI
export const DreamcatcherDateInputComponent = (
  props: DreamcatcherDateInput
) => {
  // Get the dreamcatcher value/setValue state. Uses the input id to get the
  // correct value/setValue state.
  const { value, setValue } = useDreamcatcherInputPathAt(props.id);
  const dateFromValue = useMemo(() => {
    const firstVal = value?.[0];
    return firstVal ? new Date(firstVal) : null;
  }, [value]);

  // Use the schema to parse the metadata.
  const metadata = DreamcatcherDateInputMetadataSchema.parse(props.metadata);

  return (
    <div>
      <DateInputLegacy
        label={metadata.label ?? ""}
        value={dateFromValue}
        onChange={(date) => {
          if (isDate(date)) {
            setValue([date.toISOString()]);
          } else {
            setValue([]);
          }
        }}
      />
    </div>
  );
};

// Declare the plugin.
export const DreamcatcherDateInputPlugin: DreamcatcherInputPlugin<
  DreamcatcherDateInputMetadata,
  inputType
> = {
  component: DreamcatcherDateInputComponent,
  type: "date",
  name: "Date",
  metadataSchema: DreamcatcherDateInputMetadataSchema,
};

function isDate(date: Date | ValidationError | null): date is Date {
  return (
    (date as ValidationError).errorType === undefined && date !== undefined
  );
}

// Now, export from a barrel file,
// and import in `inputPlugins`.
