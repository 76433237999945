import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { styles } from "./styles";

type ErrorCardProps = {
  error: string;
};

export const ErrorCard = memo(({ error }: ErrorCardProps) => {
  return (
    <Box sx={styles.wrapper}>
      <Typography variant="body6" color="error">
        {error}
      </Typography>
    </Box>
  );
});

ErrorCard.displayName = "ErrorCard";
