import { useCallback } from "react";
import { useTranslation } from "next-i18next";
import { FormModal, TextField } from "@pairtreefamily/ui";
import { FormValues, validationSchema } from "./validation-schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export type RecipientModalProps = {
  isOpen: boolean;
  onClose: () => void;
  // TODO better return type/error handling
  onSubmit: (args: {
    firstName: string;
    lastName: string;
    email: string;
    note?: string;
  }) => Promise<boolean>;
};

export function RecipientModal({
  isOpen,
  onSubmit,
  onClose,
}: RecipientModalProps) {
  const { t } = useTranslation(["components", "common"]);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      note: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const handleModalClose = useCallback(() => {
    formMethods.reset();
    onClose();
  }, [formMethods, onClose]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await onSubmit(values);
      handleModalClose();
    },
    [handleModalClose, onSubmit]
  );

  return (
    <FormModal<FormValues>
      formMethods={formMethods}
      onSubmit={handleSubmit}
      submitLabel={t("common:actions.submit")}
      title={t("components:recipientModal.title")}
      open={isOpen}
      onClose={handleModalClose}
    >
      <TextField
        name="firstName"
        label={t("components:recipientModal.fields.firstName")}
      />
      <TextField
        name="lastName"
        label={t("components:recipientModal.fields.lastName")}
      />
      <TextField
        name="email"
        label={t("components:recipientModal.fields.emailAddress")}
      />
      <TextField
        name="note"
        label={t("components:recipientModal.fields.note")}
      />
    </FormModal>
  );
}

export default RecipientModal;
