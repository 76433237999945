import { ReactNode } from "react";
import { ExtendedTaskGroup, Task, TaskGroup } from "@_types/*";
import TaskGroupAccordion from "./components/TaskGroupAccordion";
import { DragAndDrop, SortableItem } from "@shared/components";
import { SwapIcon } from "@pairtreefamily/ui/src/components/icons/Swap";

export type TaskGroupRow = (
  task: Task,
  rowNum: number
) => {
  column1?: ReactNode;
  column2?: ReactNode;
  column3?: ReactNode;
  column4?: ReactNode;
};

export interface TaskGroupProps {
  taskGroups: ExtendedTaskGroup[];
  row?: TaskGroupRow;
  deleteTaskGroup?: (taskGroup: TaskGroup) => void;
  createReferenceTask?: (taskGroupId: string) => void;
  updatedTasksOrder?: (newOrder: string[], taskGroup: TaskGroup) => void;
  updatedTaskGroupsOrder?: (newOrder: string[]) => void;
  onOpenTaskManagerModal?: (taskGroup: TaskGroup) => void;
  openTaskGroupModal?: (isHidden: boolean, taskGroup: TaskGroup) => void;
  allowReorder?: boolean;
}

export function TaskGroupView(props: TaskGroupProps) {
  const {
    deleteTaskGroup,
    createReferenceTask,
    row,
    updatedTasksOrder,
    updatedTaskGroupsOrder,
    allowReorder,
    onOpenTaskManagerModal,
    openTaskGroupModal,
  } = props;

  return (
    <>
      {allowReorder ? (
        <DragAndDrop
          className="text-body-3 even:bg-backgroundgray"
          onSort={(newOrder) =>
            updatedTaskGroupsOrder && updatedTaskGroupsOrder(newOrder)
          }
          itemsList={props.taskGroups.map(
            (taskGroup: ExtendedTaskGroup, idx) => ({
              id: taskGroup.id,
              element: (
                <SortableItem key={taskGroup.id} id={taskGroup.id}>
                  {(props) => (
                    <div className="flex justify-between">
                      <div
                        className="text-body-3 group/parent w-full cursor-default even:bg-backgroundgray"
                        ref={props.setNodeRef}
                        style={props.style}
                        {...props.attributes}
                      >
                        <div className="flex">
                          <span className="-ml-[60px] flex w-[60px]">
                            <button
                              {...props.listeners}
                              className="m-auto hidden cursor-grab p-2 text-lightgray hover:text-teal group-hover/parent:block"
                            >
                              <SwapIcon />
                            </button>
                          </span>
                          <div className="w-full">
                            <TaskGroupAccordion
                              allowReorder={allowReorder}
                              updatedTasksOrder={updatedTasksOrder}
                              key={idx}
                              taskGroup={taskGroup}
                              deleteTaskGroup={deleteTaskGroup}
                              createReferenceTask={createReferenceTask}
                              openTaskManagerModal={onOpenTaskManagerModal}
                              row={row}
                              openTaskGroupModal={openTaskGroupModal}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </SortableItem>
              ),
            })
          )}
        />
      ) : (
        props.taskGroups
          ?.filter((item) => !item.isHidden)
          ?.map((taskGroup: ExtendedTaskGroup, idx) => (
            <div className="w-full" key={idx}>
              <TaskGroupAccordion
                allowReorder={allowReorder}
                updatedTasksOrder={updatedTasksOrder}
                taskGroup={taskGroup}
                deleteTaskGroup={deleteTaskGroup}
                createReferenceTask={createReferenceTask}
                openTaskManagerModal={onOpenTaskManagerModal}
                row={row}
              />
            </div>
          ))
      )}
    </>
  );
}
