import { memo } from "react";

import {
  Typography,
  ListItemIcon,
  MenuItem,
  MenuItemProps,
} from "@mui/material";
import { DoneIcon } from "@pairtreefamily/ui";

export const DropdownItem = memo(
  ({ selected, children, ...restProps }: MenuItemProps) => (
    <MenuItem selected={selected} {...restProps}>
      <Typography component="span">{children}</Typography>

      <ListItemIcon>{selected && <DoneIcon />}</ListItemIcon>
    </MenuItem>
  )
);

DropdownItem.displayName = "DropdownItem";
