import { memo, ReactNode, useCallback, useEffect } from "react";
import { Login, useOrganizationsData, useUserData } from "@shared/components";
import { useRouter } from "next/router";
import { publicRoutes, routesConfig } from "./routes-config";
import { Routes } from "./routes.enum";
import { Loading, useAuthStatus } from "@pairtreefamily/ui";
import {
  findFirstAllowedRoute,
  cannotAccessProLogin,
  setSentryUser,
  isRoleAllowed,
  isOrganizationRequestRequired,
  hasAccessRoute,
} from "./utils";
type AuthGuardProps = {
  children: ReactNode;
};

export const AuthGuard = memo(({ children }: AuthGuardProps) => {
  const { userData, isUserDataLoading } = useUserData();
  const { userOrganizationData, isUserOrganizationDataLoading } =
    useOrganizationsData();

  const authStatusData = useAuthStatus();
  const router = useRouter();

  const {
    isAuthenticated,
    isLoading: isAuthStatusLoading,
    authedUser,
  } = authStatusData;

  if (!authStatusData) {
    throw new Error("AuthGuard used without a parent AuthStatusProvider");
  }

  setSentryUser(isAuthenticated, authedUser?.email);

  const sendUserToFirstAllowedRoute = useCallback(() => {
    const allowedRoute = findFirstAllowedRoute(userData?.combinedUserRole);

    router.replace(allowedRoute);
  }, [router, userData?.combinedUserRole]);

  useEffect(() => {
    const combinedUserRole = userData?.combinedUserRole;
    const routeConfig = routesConfig[router.pathname as Routes];
    const allowedRoles = routeConfig.roles;

    const hasOrganizations =
      (userData?.proDetails?.org_memberships?.length ?? 0) > 0;

    // If the user data & org data, the auth status are loading or the user is authenticated
    // but the user data & org data is not yet loaded, show a loading spinner
    if (
      isUserDataLoading ||
      isUserOrganizationDataLoading ||
      isAuthStatusLoading ||
      (!userData && !userOrganizationData && isAuthenticated)
    ) {
      return;
    }

    // If the Pro user is authenticated, has no organizations - redirect to the Organization Form page
    if (
      isOrganizationRequestRequired(
        isAuthenticated,
        combinedUserRole,
        hasOrganizations
      ) &&
      router.pathname !== Routes.ProAccountSignUpWelcome
    ) {
      router.replace(Routes.ProAccountSignUpWelcome);

      return;
    }

    // Is user authenticated and trying to access a public route and not PRO - redirect to the first allowed route
    if (
      cannotAccessProLogin(
        combinedUserRole,
        isAuthenticated,
        router.pathname as Routes,
        hasOrganizations
      )
    ) {
      sendUserToFirstAllowedRoute();

      return;
    }

    // If the user role is not allowed, redirect to the first allowed route
    if (isRoleAllowed(combinedUserRole, allowedRoles)) {
      sendUserToFirstAllowedRoute();

      return;
    }

    // If the user has no access, redirect to the first allowed route
    if (!hasAccessRoute(userData, userOrganizationData, routeConfig)) {
      sendUserToFirstAllowedRoute();

      return;
    }
  }, [
    userData,
    isUserDataLoading,
    userOrganizationData,
    isUserOrganizationDataLoading,
    isAuthenticated,
    isAuthStatusLoading,
    sendUserToFirstAllowedRoute,
    router,
  ]);

  if (
    (isAuthStatusLoading || isUserDataLoading) &&
    router.pathname !== Routes.ProAccountSignIn
  ) {
    return <Loading />;
  }

  if (
    !isAuthStatusLoading &&
    !isAuthenticated &&
    !publicRoutes.includes(router.pathname)
  ) {
    return <Login />;
  }

  return <>{children}</>;
});

AuthGuard.displayName = "ProtectedRoute";
