import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Box, Collapse, SxProps, Theme, Typography } from "@mui/material";

import {
  SwitchField,
  AutocompleteField,
  SelectOption,
} from "@pairtreefamily/ui";

import type { CaseLoginUser, Task } from "@_types/*";

const descriptionStyles: SxProps<Theme> = { mt: 2, mb: 2 };
const notificationsEnabledStyles: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export interface CompletionNotificationsProps {
  task?: Task;
  caseLoginUsers: CaseLoginUser[];
  enabled: boolean;
}

export function CompletionNotificationsFields(
  props: CompletionNotificationsProps
) {
  const { task, caseLoginUsers, enabled } = props;
  const { t } = useTranslation("components");

  const mappedRecipientEmails: SelectOption[] = useMemo(() => {
    if (!caseLoginUsers.length) return [];

    return caseLoginUsers.map((user) => ({
      id: user.id,
      label: (
        <>
          {user.email}{" "}
          {user.showContactInfoIfProvider && (
            <i>{t("taskManagerModal.fields.recipientEmails.primary")}</i>
          )}
        </>
      ),
    }));
  }, [caseLoginUsers, t]);

  const isFieldDisabled = task?.status === "completed";

  const notificationsEnabledLabel = t(
    `taskManagerModal.fields.completionNotifications.values.${
      enabled ? "on" : "off"
    }`
  );

  return (
    <>
      <Box sx={notificationsEnabledStyles}>
        <Typography variant="body4">
          {t("taskManagerModal.fields.completionNotifications.label")}
        </Typography>

        <SwitchField
          name="areNotificationsEnabled"
          labelPlacement="start"
          label={notificationsEnabledLabel}
          disabled={isFieldDisabled}
        />
      </Box>

      <Collapse orientation="vertical" in={enabled}>
        <Typography component="p" variant="body6" sx={descriptionStyles}>
          {t("taskManagerModal.fields.completionNotifications.description")}
        </Typography>

        <AutocompleteField
          multiple
          name="recipientEmailIds"
          options={mappedRecipientEmails}
          disabled={isFieldDisabled}
          placeholder={t("taskManagerModal.fields.recipientEmails.placeholder")}
          noOptionsText={t("taskManagerModal.fields.recipientEmails.empty")}
        />
      </Collapse>
    </>
  );
}
