import { ControllerFieldState } from "react-hook-form/dist/types/controller";
import { Typography } from "@mui/material";

type ErrorMessageProps = {
  fieldState: ControllerFieldState;
};

export const ErrorMessage = ({ fieldState }: ErrorMessageProps) => {
  return (
    <Typography pt="5px" component="p" variant="caption1" color="error.main">
      {fieldState?.error?.message}
    </Typography>
  );
};
