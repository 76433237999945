import {
  NewSafetyCenterThreadRequest,
  ThreadsListRequest,
  useCoreClient,
} from "@api";
import { useMutation, useQuery } from "react-query";
import { useOrganizationsData, useUserData } from "@shared/components";
import { useMemo } from "react";

export const useGetThreads = (
  props: Omit<ThreadsListRequest, "organizationUuid">
) => {
  const { CoreClient } = useCoreClient();
  const { organizationUuid } = useGetUserIdentifiers();

  return useQuery([`safety-center-threads`, props, organizationUuid], () =>
    CoreClient.getSafetyCenterThreads({ ...props, organizationUuid })
  );
};

export const useGetSearchThreadsCount = (searchQuery: string) => {
  const { CoreClient } = useCoreClient();
  const { organizationUuid } = useGetUserIdentifiers();

  return useQuery(
    [`safety-center-threads-count-${searchQuery}`, organizationUuid],
    () =>
      CoreClient.getSafetyCenterThreadsCount({ searchQuery, organizationUuid })
  );
};

export const useCreateThread = () => {
  const { CoreClient } = useCoreClient();
  const userIdentifiers = useGetUserIdentifiers();

  const { mutateAsync } = useMutation(
    (args: Pick<NewSafetyCenterThreadRequest, "title" | "description">) =>
      CoreClient.createNewSafetyCenterThread({
        ...args,
        ...userIdentifiers,
      })
  );

  return mutateAsync;
};

export const useGetThreadDetails = (id: string) => {
  const { CoreClient } = useCoreClient();
  const { organizationUuid } = useGetUserIdentifiers();

  return useQuery(
    `safety-center-thread-details-${id}`,
    () => CoreClient.getThreadDetails({ id, organizationUuid }),
    { enabled: !!id }
  );
};

export const useSendComment = (threadId: string) => {
  const { CoreClient } = useCoreClient();
  const { refetch } = useGetThreadDetails(threadId);
  const userIdentifiers = useGetUserIdentifiers();

  const { mutateAsync } = useMutation(
    (args: { description: string }) =>
      CoreClient.sendNewComment({ ...args, threadId, ...userIdentifiers }),
    {
      onSuccess: async () => {
        await refetch();
      },
    }
  );

  return mutateAsync;
};

function useGetUserIdentifiers() {
  const { userOrganizationData } = useOrganizationsData();
  const { userData } = useUserData();

  return useMemo(() => {
    const orgUuid = userOrganizationData?.id;
    const org = userData?.proDetails?.org_memberships.find(
      (membership) => membership.org_uuid === orgUuid
    );

    return {
      proUuid: org?.pro_uuid ?? "",
      organizationUuid: org?.org_uuid ?? "",
    };
  }, [userData?.proDetails?.org_memberships, userOrganizationData?.id]);
}
