import { theme } from "../../../theme";

export const styles = {
  wrapper: {
    position: "fixed",
    top: theme.spacing(3),
    // Should be above the drawer / side panel
    zIndex: 1201,
    display: "flex",
    flexDirection: "column-reverse",
    gap: 2,
    left: "50%",
  },
  snackbarComponent: {
    position: "static",
    left: 0,
  },
};
