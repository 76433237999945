import { useCallback, useRef, useState } from "react";
import { Trans, useTranslation } from "next-i18next";
import { FileUploadTask } from "@_types/*";
import { Result } from "@pairtreefamily/utils";
import { TaskTitle } from "./TaskTitle";
import { TaskDescription } from "./TaskDescription";
import { Button } from "@pairtreefamily/ui";
import { UploadedFilesModal } from "./UploadFilesModal";
import { TaskQuestionStatus } from "./TaskQuestionStatus";
import { TaskStatusElement } from "./task-status-element";

const MAX_FILE_SIZE_MB = 150;
const FILE_SIZE_WARNING_MB = 130;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1000000;

export type IssueeFileUploadRowProps = {
  task: FileUploadTask;
  getFileName: (fileId: string) => Promise<string | null | undefined>;
  uploadFile: (file: File) => void;
  answer: boolean | null;
  handleSetAnswer: (e: boolean | null) => void;
  handleDeleteFile: (fileId: string, fileName: string) => void;
  viewDocumentsLink: string | null;
  getFileUrl: (fileId: string) => Promise<Result<string, unknown>>;
  editTask?: (task: FileUploadTask) => void;
};

export function IssueeFileUploadRow(props: IssueeFileUploadRowProps) {
  const {
    task,
    getFileName,
    uploadFile,
    answer,
    handleSetAnswer,
    handleDeleteFile,
    viewDocumentsLink,
    getFileUrl,
    editTask,
  } = props;

  const { t } = useTranslation("components");

  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileModalOpen, setFileModalOpen] = useState(false);
  const [filesData, setFilesData] = useState<Array<{
    id: string;
    name: string;
  }> | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  const getFilesData = useCallback(async () => {
    const names: Array<{ id: string; name: string }> = [];
    for (const file of task?.taskFiles) {
      const fileName = await getFileName(file.googleDriveFileId);
      names.push({ id: file.googleDriveFileId, name: fileName ?? "" });
    }
    setFilesData(names);
  }, [task, getFileName]);

  return {
    column1: (
      <TaskTitle
        viewDocumentsLink={viewDocumentsLink}
        getFileUrl={getFileUrl}
        task={task}
        answer={answer}
        onAnswer={handleSetAnswer}
      />
    ),
    column2: <TaskDescription task={task} />,
    column3: task.question ? (
      <TaskQuestionStatus task={task} isFamily={false} />
    ) : (
      <TaskStatusElement task={task} />
    ),
    column4: (
      <div>
        <div className="flex flex-col items-center gap-2">
          {task.TaskGroup?.providerOnly && (
            <Button
              color="error"
              variant="outlined"
              onClick={() => editTask?.(task)}
            >
              {t("issuerElectronicDocumentRow.buttons.edit")}
            </Button>
          )}
          {((task.question && task.answer) || !task.question) && (
            //if the task has a question and the answer is yes
            //then we should allow the user to choose the file
            // of if the task does not has quedstion, we also should allow user to choose the file
            <Button
              variant="outlined"
              onClick={() => ref.current?.click()}
              disabled={task.isLocked}
            >
              {t("issueeFileUploadRow.chooseFile")}
            </Button>
          )}
          <input
            hidden
            ref={ref}
            type="file"
            onChange={(event) => {
              const file = event.target.files?.[0];
              if (file == undefined) {
                setFile(null);
                setError(t("issueeFileUploadRow.errors.selectedFile"));
                return;
              }
              if (file.size > MAX_FILE_SIZE_BYTES) {
                setFile(null);
                setError(
                  t("issueeFileUploadRow.errors.fileSize", {
                    fileSize: FILE_SIZE_WARNING_MB,
                  })
                );
                return;
              }
              setError(null);
              setFile(file);
            }}
          />

          {file && (
            <div>
              <Button
                disabled={!file || task.isLocked}
                isLoading={isLoading}
                onClick={async () => {
                  if (!file) {
                    return;
                  }
                  setIsLoading(true);
                  // TODO make async and handle errors/loading state upon completion
                  uploadFile(file);
                  setIsLoading(false);
                  setFile(null);
                }}
              >
                {t("issueeFileUploadRow.upload")}
              </Button>
            </div>
          )}
        </div>

        {file && (
          <p className="text-center">
            <Trans
              t={t}
              i18nKey={"issueeFileUploadRow.selectedFile"}
              values={{ fileName: file?.name }}
              components={[<i key="0" />]}
            />
          </p>
        )}

        {error && <p className="pt-2 text-center text-rust">{error}</p>}

        {task.taskFiles.length > 0 && (
          <div className="flex justify-center pt-5">
            <Button
              variant="text"
              size="small"
              onClick={() => {
                getFilesData();
                setFileModalOpen(true);
              }}
            >
              {t("issueeFileUploadRow.viewUploaded")}
            </Button>
          </div>
        )}

        <UploadedFilesModal
          isOpen={isFileModalOpen}
          onClose={() => setFileModalOpen(false)}
          filesData={filesData}
          getFileUrl={getFileUrl}
          viewDocumentsLink={viewDocumentsLink}
          onDeleteFile={(fileId, fileName) => {
            handleDeleteFile(fileId, fileName);
            getFilesData();
          }}
          isLocked={task.isLocked}
        />
      </div>
    ),
  };
}
