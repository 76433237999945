import { palette } from "../palette";
import { typography } from "../typography";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

export const MuiPaginationItem = {
  defaultProps: {
    slots: {
      prev: ChevronLeftRoundedIcon,
      next: ChevronRightRoundedIcon,
    },
  },
  styleOverrides: {
    root: {
      marginLeft: 4,
      marginRight: 4,
      borderRadius: "8px",
      color: palette.text.light,
      ...typography.body9,

      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: palette.secondary.main,
        color: palette.primary.contrastText,
      },
    },
    icon: {
      fontSize: "16px",
    },
  },
};
