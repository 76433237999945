import { trpc } from "../trpc";
import { useCallback, useMemo } from "react";

export const useGetProviderCandidates = () => {
  return trpc.homeStudy.getProviderCandidates.useQuery();
};

export const useGetCaseProviders = ({
  caseId,
  onlyProvidersWithContactInfo,
}: {
  caseId: string;
  onlyProvidersWithContactInfo?: boolean;
}) => {
  const { data: caseProviderRes } = trpc.homeStudy.getCaseProviders.useQuery({
    caseId,
    onlyProvidersWithContactInfo,
  });

  return useMemo(
    () => (caseProviderRes?.ok ? caseProviderRes.content : []),
    [caseProviderRes]
  );
};

export const useInvalidateProviderCandidates = () => {
  const { homeStudy } = trpc.useContext();

  return useCallback(() => {
    homeStudy.getProviderCandidates.invalidate();
  }, [homeStudy.getProviderCandidates]);
};

export const useInvalidateGetCaseProviders = ({
  caseId,
}: {
  caseId: string;
}) => {
  const { homeStudy } = trpc.useContext();

  return useCallback(() => {
    homeStudy.getCaseProviders.invalidate({ caseId: caseId });
  }, [caseId, homeStudy.getCaseProviders]);
};

export const useAssignProviderToCase = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: assignProviderToCase } =
    trpc.homeStudy.assignProviderToCase.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: {
      email: string;
      firstName: string;
      lastName: string;
      isAdmin: boolean;
      profilePictureUrl: string;
      phoneNumber: string;
      showContactInfo?: boolean;
    }) =>
      assignProviderToCase({
        caseId: caseId,
        ...args,
      }),
    [assignProviderToCase, caseId]
  );
};

export const useToggleProviderContactInfo = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: toggleProviderContactInfo } =
    trpc.homeStudy.toggleProviderContactInfo.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: { loginUserId: string; showContactInfo: boolean }) =>
      toggleProviderContactInfo({
        caseId: caseId,
        loginUserId: args.loginUserId,
        shouldShowContactInfo: args.showContactInfo,
      }),
    [toggleProviderContactInfo, caseId]
  );
};
