import { useTranslation } from "next-i18next";

import { TextField } from "@pairtreefamily/ui";
import { getClassName } from "@pairtreefamily/utils";

import { fieldsGroupClassName } from "../styles";
import { Separator } from "@shared/components";
import { ExpirationDateRequired } from "./ExpirationDateRequired";
import { Task } from "@_types/*";

type ReferenceTaskFieldsProps = {
  task?: Task;
  isAdmin: boolean;
};

export function ReferenceTaskFields(props: ReferenceTaskFieldsProps) {
  const { task, isAdmin } = props;

  const { t } = useTranslation("components");

  const isFieldDisabled = task?.status === "completed" && !isAdmin;

  return (
    <>
      <div className="text-left">
        <ExpirationDateRequired />
      </div>

      <div className={fieldsGroupClassName}>
        <div className={`pt-4 ${getClassName(fieldsGroupClassName)}`}>
          <TextField
            name="templateUuid"
            label={t("referenceTaskForm.fields.templatedUUID")}
            disabled={isFieldDisabled}
          />
        </div>
      </div>
    </>
  );
}
