import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const tokenPath = "pths.token";

export let token: string | undefined =
  typeof window !== "undefined"
    ? window.localStorage.getItem(tokenPath) ?? undefined
    : undefined;

/**
 * This lives in a hook so queries can automatically update when the token changes.
 * It will also check the `token` query param and set the token if it is present.
 */
export const useCaseJwt = () => {
  const router = useRouter();

  const [_localToken, _setLocalToken] = useState<string | undefined>(undefined);
  const setToken = (newToken: string) => {
    token = newToken;
    localStorage.setItem(tokenPath, token);
    _setLocalToken(newToken);
  };

  const clearToken = () => {
    token = undefined;
    localStorage.removeItem(tokenPath);
    _setLocalToken(undefined);
  };

  useEffect(() => {
    if (router.isReady) {
      const { token } = router.query;
      if (typeof token === "string") {
        setToken(token);
      }
    }
  }, [router.isReady, router.query]);

  return { token: _localToken, setToken, clearToken };
};
