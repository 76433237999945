import { getClassName } from "@pairtreefamily/utils";
import "react-circular-progressbar/dist/styles.css";
import { Box } from "@mui/material";
import { palette } from "@pairtreefamily/ui";

export function LinearProgressBar({
  percentComplete,
}: {
  percentComplete: number;
}) {
  return (
    <div className="flex gap-4">
      <div
        className="my-auto flex h-[5px] w-48 rounded-full bg-background-gray"
        // note: this may need to be changed if font size for task group heading is changed
        style={{ maxHeight: "30px" }}
      >
        <Box
          sx={{
            background:
              percentComplete === 0
                ? palette.background.light
                : `linear-gradient(90deg, ${palette.secondary.main} 0%, ${palette.primary.main} 100%);`,
          }}
          className={getClassName(
            "align-center h-[5px] min-w-fit justify-center rounded-full"
          )}
          style={{
            width: `${percentComplete}%`,
          }}
        ></Box>
      </div>
      <p className="text-body-4-semi my-auto w-[34px] text-darkgray">
        {" "}
        {`${percentComplete}%`}
      </p>
    </div>
  );
}
