import { trpc } from "../trpc";
import { useCallback } from "react";

export const useGetFileUrl = () => {
  const { mutateAsync: getFileUrl } = trpc.homeStudy.getFileUrl.useMutation();

  return useCallback(
    (args: { caseId: string; fileId: string }) =>
      getFileUrl({
        ...args,
      }),
    [getFileUrl]
  );
};

export const useGetFileData = () => {
  const { mutateAsync: getFileData } = trpc.homeStudy.getFileData.useMutation();

  return useCallback(
    (args: { caseId: string; googleDriveFileId: string }) =>
      getFileData({
        caseId: args.caseId,
        fileId: args.googleDriveFileId,
      }),
    [getFileData]
  );
};
