import { palette, typography } from "@pairtreefamily/ui";
import { alpha } from "@mui/material";

export const styles = {
  errorChip: {
    ...typography.tooltip,
    backgroundColor: palette.error.background,
    color: palette.error.dark,
  },
  warningChip: {
    ...typography.tooltip,
    backgroundColor: alpha(palette.active.main, 0.1),
    color: palette.active.contrastText,
  },
};
