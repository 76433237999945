import { rebuildObjProperties } from "./rebuild-object-properties";
import { toSnakeCase } from "./to-snake-case";

export const paramsSerializer = (params: any) => {
  const paramsWithoutEmptyValues = Object.keys(params || {}).reduce(
    (acc, key) => {
      if (params[key]) {
        // @ts-ignore
        acc[key] = params[key];
      }

      return acc;
    },
    {}
  );

  const paramsWithSnakeCasedKeys = rebuildObjProperties(
    paramsWithoutEmptyValues,
    toSnakeCase
  );

  return new URLSearchParams(paramsWithSnakeCasedKeys).toString();
};
