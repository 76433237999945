import { useTranslation } from "next-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  palette,
  useAuthStatus,
} from "@pairtreefamily/ui";
import { WorkspaceInfo } from "./components";

import { useOrganizationsData, useUserData } from "@shared/components";
import { menuListProps } from "./constants";
import { useCallback } from "react";
import { CombinedUserRole } from "../../../../../user-provider/types";
import { useRouter } from "next/router";
import { Routes } from "@features/routing";
import { appConfig } from "@lib";
import { Typography } from "@mui/material";
import { useQueryClient } from "react-query";

export const WorkspaceToolbar = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const queryClient = useQueryClient();

  const {
    organizationsListData,
    userOrganizationData,
    setActiveOrganizationId,
  } = useOrganizationsData();

  const { userData, invalidate } = useUserData();

  const { signOut } = useAuthStatus();

  const handleSignOut = useCallback(async () => {
    if (userData?.combinedUserRole !== CombinedUserRole.Family) {
      router.replace(`${Routes.ProAccountSignIn}?signOut=true`);
      queryClient.removeQueries();
      invalidate();
      return;
    }

    queryClient.removeQueries();
    invalidate();
    signOut();
  }, [queryClient, signOut, invalidate, userData?.combinedUserRole, router]);

  if (!userData) {
    return null;
  }

  return (
    <DropdownMenu
      triggerElement={
        <WorkspaceInfo
          organizationName={userOrganizationData?.name}
          userFirstName={userOrganizationData?.firstName}
          userLastName={userOrganizationData?.lastName}
        />
      }
      MenuListProps={menuListProps}
    >
      {organizationsListData && (
        <DropdownSection
          title={t("layout.header.workspace.sections.workspace.caption")}
        >
          {organizationsListData.map((workspaceItem) => (
            <DropdownItem
              onClick={() => setActiveOrganizationId(workspaceItem.id)}
              key={workspaceItem.id}
              selected={workspaceItem.id === userOrganizationData?.id}
            >
              {workspaceItem.name}
            </DropdownItem>
          ))}
        </DropdownSection>
      )}

      <DropdownSection
        title={t("layout.header.workspace.sections.account.caption")}
      >
        <DropdownItem onClick={handleSignOut}>
          {t("layout.header.workspace.sections.account.items.logOut")}
        </DropdownItem>
        <DropdownItem>
          <Typography
            variant="body9"
            component="a"
            sx={{
              color: palette.text.primary,
              "&:hover": { color: palette.primary.main },
            }}
            href={`mailto:${appConfig.supportContactEmail}`}
          >
            {t("layout.header.workspace.sections.account.items.needHelp")}
          </Typography>
        </DropdownItem>
      </DropdownSection>
      <DropdownSection title={userData?.email}>{null}</DropdownSection>
    </DropdownMenu>
  );
};
