export enum Routes {
  Home = "/",
  NotFound = "/404",

  Admin = "/admin",
  AdminCase = "/admin/[[...caseId]]",

  Family = "/family",
  FamilyCase = "/family/[caseId]",

  ProfessionalDashboard = "/professional",
  ProfessionalCase = "/professional/[...caseId]",

  References = "/references",

  SafetyCenter = "/safety-center",

  ProAccountSignIn = "/pro/sign-in",
  ProAccountSignUpWelcome = "/pro/sign-up/welcome",
  ProForgotPassword = "/pro/forgot-password",
  ProAccountSignUp = "/pro/sign-up",

  Connect = "/connect",

  Organization = "/organization",

  Billing = "/billing",
}
