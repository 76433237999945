import {
  IconButton,
  Snackbar,
  SnackbarOrigin,
  SnackbarProps as MuiSnackbarProps,
} from "@mui/material";
import { memo } from "react";
import RoundedCloseIcon from "@mui/icons-material/Close";
import { autoHideDuration as autoHideDurationConstant } from "./constants";
import { styles } from "./styles";
import { SnackbarType } from "./types";

type SnackbarProps = MuiSnackbarProps & {
  type?: SnackbarType;
};

const anchorOrigin: SnackbarOrigin = {
  vertical: "top",
  horizontal: "center",
};

export const SnackbarNotification = memo(
  ({
    autoHideDuration = autoHideDurationConstant,
    type = "info",
    ...props
  }: SnackbarProps) => {
    const action = (
      <IconButton
        size="small"
        aria-label="close"
        sx={styles.iconButton(type)}
        onClick={(e) => props.onClose?.(e, "escapeKeyDown")}
      >
        <RoundedCloseIcon fontSize="small" />
      </IconButton>
    );

    return (
      <Snackbar
        {...props}
        sx={{
          ...styles.snackbar(type),
          ...(props.sx ? props.sx : {}),
        }}
        action={action}
        autoHideDuration={autoHideDuration}
        anchorOrigin={anchorOrigin}
      />
    );
  }
);

SnackbarNotification.displayName = "SnackbarNotification";
