import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";

import { Switch, SwitchProps } from "../../mui/switch";
import { ErrorMessage } from "../error-message";

export interface SwitchFieldProps
  extends Omit<SwitchProps, "checked" | "onChange"> {
  name: string;
}

export const SwitchField = ({ name, ...rest }: SwitchFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field: { value, onChange }, fieldState }) => {
      return (
        <>
          <Switch
            checked={Boolean(value)}
            onChange={onChange}
            name={name}
            {...rest}
          />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </>
      );
    },
    [name, rest]
  );

  return <Controller name={name} render={render} />;
};
