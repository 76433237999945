export function ConnectLinkIcon() {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.55469 3.09375C5.86719 1.78125 8 1.78125 9.3125 3.09375C10.5781 4.33594 10.625 6.35156 9.47656 7.6875L9.33594 7.82812C9.125 8.0625 8.77344 8.08594 8.53906 7.89844C8.30469 7.6875 8.28125 7.33594 8.49219 7.10156L8.60938 6.96094C9.40625 6.07031 9.35938 4.73438 8.51562 3.89062C7.64844 3 6.21875 3 5.32812 3.89062L2.67969 6.53906C1.8125 7.42969 1.8125 8.83594 2.67969 9.72656C3.52344 10.5469 4.85938 10.5938 5.75 9.82031L5.89062 9.70312C6.125 9.49219 6.47656 9.51562 6.6875 9.75C6.89844 9.98438 6.875 10.3359 6.64062 10.5469L6.5 10.6641C5.16406 11.8359 3.14844 11.7656 1.88281 10.5234C0.570312 9.21094 0.570312 7.05469 1.88281 5.74219L4.55469 3.09375ZM11.4453 8.92969C10.1094 10.2422 7.97656 10.2422 6.66406 8.92969C5.39844 7.66406 5.35156 5.67188 6.5 4.33594L6.61719 4.19531C6.82812 3.96094 7.17969 3.9375 7.41406 4.14844C7.64844 4.35938 7.67188 4.71094 7.46094 4.94531L7.36719 5.0625C6.57031 5.95312 6.61719 7.28906 7.46094 8.13281C8.32812 9 9.75781 9 10.6484 8.13281L13.2969 5.48438C14.1641 4.59375 14.1641 3.1875 13.2969 2.29688C12.4531 1.47656 11.1172 1.42969 10.2266 2.20312L10.0859 2.32031C9.85156 2.53125 9.5 2.50781 9.28906 2.27344C9.07812 2.03906 9.10156 1.6875 9.33594 1.47656L9.47656 1.35938C10.8125 0.1875 12.8281 0.257812 14.0938 1.5C15.4062 2.8125 15.4062 4.94531 14.0938 6.28125L11.4453 8.92969Z"
        fill="currentColor"
      />
    </svg>
  );
}
