import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";

import { ThemeProvider } from "../../theme";
import { PhoneNumberInput, PhoneNumberInputProps } from "../../mui";
import { ErrorMessage } from "../error-message";

export type PhoneNumberFieldProps = PhoneNumberInputProps & {
  name: string;
};

export const PhoneNumberField = ({ name, ...rest }: PhoneNumberFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => {
      return (
        <ThemeProvider>
          <PhoneNumberInput
            {...rest}
            value={field.value}
            onChange={field.onChange}
            name={name}
          />

          {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
        </ThemeProvider>
      );
    },
    [name, rest]
  );

  return <Controller name={name} render={render} />;
};
