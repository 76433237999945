import { IconButton } from "@mui/material";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { styles } from "./styles";

type ToggleViewButtonProps = {
  onClick: () => void;
  isCollapsed: boolean;
};

export const ToggleViewButton = ({
  onClick,
  isCollapsed,
}: ToggleViewButtonProps) => {
  return (
    <IconButton onClick={onClick} sx={styles.iconButton} color="success">
      <KeyboardArrowRightRoundedIcon sx={styles.icon(isCollapsed)} />
    </IconButton>
  );
};
