import { typography } from "@pairtreefamily/ui";

export const styles = {
  text: ({
    isExpanded,
    maxLines,
  }: {
    isExpanded: boolean;
    maxLines: number;
  }) => ({
    display: "-webkit-box",
    WebkitLineClamp: isExpanded ? "none" : maxLines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    whiteSpace: "pre-line",
  }),
  expandButton: {
    ...typography.caption2,
  },
};
