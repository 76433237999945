import { format, isAfter } from "date-fns";
import { Trans, useTranslation } from "next-i18next";
import { WarningIcon } from "@pairtreefamily/ui";

import type { Task } from "@_types/tasks";
import { isDateExpiring } from "@shared/utils";
import { appConfig } from "@lib";

type ExpiredElementProps = Pick<
  Task,
  "expirationDateRequired" | "expirationDate"
>;

export function ExpiredElement({
  expirationDateRequired,
  expirationDate,
}: ExpiredElementProps) {
  const { t } = useTranslation("components");

  if (!expirationDateRequired) {
    return null;
  }

  const isDateExpired = expirationDate && isAfter(new Date(), expirationDate);
  const isExpiring = isDateExpiring(
    appConfig.daysWhenTaskConsideredExpiring,
    expirationDate
  );
  const formattedDate = expirationDate ? format(expirationDate, "P") : "";
  return (
    <div className={"text-body-4 mt-2 flex gap-2"}>
      {((expirationDateRequired && isDateExpired) || isExpiring) && (
        <div
          className={`mt-[3px] ${
            (isDateExpired || (expirationDateRequired && !expirationDate)) &&
            "text-rust"
          } ${isExpiring && "text-mango"}`}
        >
          <WarningIcon />
        </div>
      )}

      <em
        className={
          isDateExpired ? "text-body-4 text-onyx-light" : "text-body-4"
        }
      >
        {!expirationDate && t("statusElement.expiredElement.noValue")}

        {isDateExpired && (
          <Trans
            t={t}
            i18nKey={"statusElement.expiredElement.isExpired"}
            values={{ date: formattedDate }}
            components={[<br key="0" />]}
          />
        )}
        {expirationDate &&
          !isDateExpired &&
          t("statusElement.expiredElement.notExpired", {
            date: formattedDate,
          })}
      </em>
    </div>
  );
}
