import {
  createContext,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useAuthStatus } from "@pairtreefamily/ui";
import { CoreClient } from "./core-client";

const CoreClientContext = createContext<{ CoreClient: typeof CoreClient }>({
  CoreClient: CoreClient,
});

export const CoreClientProvider = memo(
  ({ children }: { children: ReactNode }) => {
    const { getToken } = useAuthStatus();

    useEffect(() => {
      const interceptor = CoreClient.ApiClient.interceptors.request.use(
        async (config) => {
          const awaitToken = await getToken();

          config.headers.Authorization = `Bearer ${awaitToken}`;

          return config;
        }
      );

      return () => {
        // Timeout needed to give a chance to request invoke with the token.
        // If we remove this timeout - sometimes some requests will fail while switching between different pages
        setTimeout(() => {
          CoreClient.ApiClient.interceptors.request.eject(interceptor);
        });
      };
    }, [getToken]);

    const value = useMemo(() => ({ CoreClient: CoreClient }), []);

    return (
      <CoreClientContext.Provider value={value}>
        {children}
      </CoreClientContext.Provider>
    );
  }
);

export const useCoreClient = () => useContext(CoreClientContext);

CoreClientProvider.displayName = "ApiClientProvider";
