import { CheckedIcon, UncheckedIcon } from "./Icons";
import { palette } from "../../palette";

export const MuiRadio = {
  defaultProps: {
    checkedIcon: <CheckedIcon />,
    icon: <UncheckedIcon />,
  },
  styleOverrides: {
    root: {
      color: palette.primary.main,
      padding: "5px",

      "& svg": {
        overflow: "visible",
      },
    },
  },
};
