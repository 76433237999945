import { requiredString } from "@shared/validation-schemas";
import { z } from "zod";

export const validationSchema = z.object({
  email: z.string().email(),
  password: requiredString,
});

export const validationSchemaForgotPassword = z.object({
  email: z.string().email(),
});

export type FormValues = z.infer<typeof validationSchema>;
