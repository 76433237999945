import { Box } from "@mui/material";
import { ReactNode } from "react";
import { styles } from "./styles";
import { palette } from "@pairtreefamily/ui";

type ContentWrapperProps = {
  children: ReactNode;
  background?: string;
};

export const ContentWrapper = ({
  children,
  background = palette.background.default,
}: ContentWrapperProps) => {
  return (
    <Box sx={styles.backgroundWrapper(background)}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.contentWidth}>{children}</Box>
      </Box>
    </Box>
  );
};
