import { ServerError } from "@pairtreefamily/home-study-t3/src/types/serverError";
import { Result } from "@pairtreefamily/utils";
import { TaskAssignment } from "@_types/*";
import { useCallback } from "react";
import { trpc } from "../trpc";

export const useRefreshElectronicDocumentSession = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}): ((
  taskAssignment: TaskAssignment
) => Promise<Result<TaskAssignment, ServerError>>) => {
  const { mutateAsync: _refreshElectronicDocumentSession } =
    trpc.homeStudy.refreshElectronicDocumentSession.useMutation({ onSuccess });

  return useCallback(
    (taskAssignment: TaskAssignment) => {
      return _refreshElectronicDocumentSession({
        taskAssignmentId: taskAssignment.id,
        caseId: caseId,
      });
    },
    [caseId, _refreshElectronicDocumentSession]
  );
};
