import React from "react";
import { Button } from "../../mui";
import { Box } from "@mui/material";

export type ForModalActionProps = {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading: boolean;
  submitLabel: string;
  isSubmitDisabled?: boolean;
};

const wrapperStyles = {
  display: "flex",
  justifyContent: "center",
  gap: 3,
  marginTop: 4,
};

export const FormModalActions = ({
  onCancel,
  isLoading,
  submitLabel,
  isSubmitDisabled,
}: ForModalActionProps) => {
  return (
    <Box sx={wrapperStyles}>
      <Button
        onClick={onCancel}
        color="primary"
        variant="outlined"
        size="small"
      >
        Cancel
      </Button>

      <Button
        disabled={isSubmitDisabled}
        type="submit"
        isLoading={isLoading}
        size="small"
      >
        {submitLabel}
      </Button>
    </Box>
  );
};
