import { Typography } from "@mui/material";
import { getMatchText } from "./get-match-text";

type HighlightedStringProps = {
  value: string;
  searchQuery: string;
};

export const HighlightedString = ({
  searchQuery,
  value,
}: HighlightedStringProps) => {
  const matchText = getMatchText({ searchQuery, value });

  return (
    <>
      {matchText.matchText ? (
        <>
          <span>{matchText.beforeMatch}</span>
          <Typography component="span" variant="body4" color="secondary.dark">
            {matchText.matchText}
          </Typography>
          <span>{matchText.afterMatch}</span>
        </>
      ) : (
        value
      )}
    </>
  );
};
