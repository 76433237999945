import {
  inputClasses,
  outlinedInputClasses,
  inputAdornmentClasses,
  inputBaseClasses,
} from "@mui/material";
import { palette } from "../palette";
import { typography } from "../typography";
import { customScroll } from "../custom-scroll";

export const MuiOutlinedInput = {
  styleOverrides: {
    root: {
      [`&.${inputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: palette.divider,
      },

      [`&.${inputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: "1px",
      },

      [`&.${inputBaseClasses.multiline}`]: {
        minHeight: "48px",
      },

      "& textarea": {
        padding: "0",
      },

      [`& .${inputAdornmentClasses.positionEnd}`]: {
        transform: "translateX(-8px)",
      },
    },
    notchedOutline: {
      borderColor: palette.divider,
    },
    input: {
      // important is a temporary solution to override the Tailwind styles
      padding: `8px 16px 8px 16px !important`,
      height: "32px",
      borderRadius: "10px",
      ...typography.input,

      [`&.${inputBaseClasses.inputMultiline}`]: {
        padding: "0 !important",
        ...customScroll,
      },

      [`&.${inputBaseClasses.inputAdornedStart}`]: {
        // important is a temporary solution to override the Tailwind styles
        paddingLeft: "8px !important",
      },

      "&:focus": {
        // important is a temporary solution to override the Tailwind styles
        outline: "none !important",
        borderColor: "transparent !important",
        boxShadow: "none !important",
      },

      "&::placeholder": {
        color: palette.text.light50,
        ...typography.input,
        opacity: 1,
      },
    },
  },
};
