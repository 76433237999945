import { FormControlLabel, TextField, TextFieldProps } from "@mui/material";
import { memo, ReactNode } from "react";
import { palette } from "../../theme";

export const controlLabelStyles = {
  alignItems: "flex-start",
  // temporary solution to add gap between inputs. Ideally, this should be handled from outside
  marginTop: 2,
  marginRight: 0,
  marginLeft: 0,
  cursor: "default",
  gap: "5px",
  width: "100%",
  "&.Mui-disabled .MuiFormControlLabel-asterisk": {
    color: palette.text.disabled,
  },
};

export const controlLabelComponentProps = {
  typography: { variant: "body4" as const },
};

export type TextInputProps = {
  label?: ReactNode;
} & Omit<TextFieldProps, "label">;

// Temporary name
export const TextInput = memo(({ label, ...rest }: TextInputProps) => {
  if (!label) return <TextField {...rest} />;

  return (
    <FormControlLabel
      sx={controlLabelStyles}
      componentsProps={controlLabelComponentProps}
      labelPlacement="top"
      control={<TextField {...rest} />}
      label={label}
    />
  );
});

TextInput.displayName = "TextInput";
