import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export type ButtonProps = Omit<
  MuiButtonProps,
  "variant" | "size" | "color" | "disabled"
> & {
  target?: string;
  variant?: "text" | "contained" | "outlined" | "iconVertical";
  size?: "small" | "medium";
  color?: "primary" | "secondary" | "proBlue" | "warning" | "error";
  isVertical?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
};

export const Button = ({ children, isLoading, ...rest }: ButtonProps) => {
  return (
    <MuiButton
      {...rest}
      endIcon={
        isLoading ? (
          <CircularProgress
            size={16}
            color="inherit"
            style={{ marginRight: 8 }}
          />
        ) : undefined
      }
    >
      {children}
    </MuiButton>
  );
};
