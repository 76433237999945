import { ReactNode } from "react";

type CardProps = {
  children: ReactNode;
};

export function Card(props: CardProps) {
  return (
    <div className="flex h-full w-full rounded-[15px] bg-white">
      <div className="h-full w-full rounded-[15px] border-[2px] border-cultured p-6 shadow-[0_4px_15px_0_rgba(0,0,0,0.03)]">
        {props.children}
      </div>
    </div>
  );
}
