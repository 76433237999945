import { useState, useCallback, useMemo, useRef } from "react";

interface UseModalStateReturnValue<Result> {
  isModalOpen: boolean;
  onAccept: (value?: Result) => void;
  onClose: () => void;
  openModalAsync: () => Promise<Result>;
}

export const useAsyncModalState = <
  Result
>(): UseModalStateReturnValue<Result> => {
  const { isOn, turnOn, turnOff } = useBooleanState();
  const acceptRef = useRef<((value?: Result) => void) | null>(null);
  const rejectRef = useRef<(() => void) | null>(null);

  const [openModalAsync] = useState(
    () => () =>
      new Promise<Result>((resolve, reject) => {
        acceptRef.current = resolve as (value?: Result) => void;
        rejectRef.current = reject;

        turnOn();
      })
  );

  const onAccept = useCallback(
    (value?: Result) => {
      acceptRef.current?.(value);
      acceptRef.current = null;

      turnOff();
    },
    [turnOff]
  );

  const onClose = useCallback(() => {
    rejectRef.current?.();
    rejectRef.current = null;

    turnOff();
  }, [turnOff]);

  return useMemo(
    () => ({
      isModalOpen: isOn,
      onAccept,
      onClose,
      openModalAsync,
    }),
    [isOn, onAccept, onClose, openModalAsync]
  );
};

export function useBooleanState(isOnByDefault = false) {
  const [isOn, setIsOn] = useState(() => isOnByDefault);

  const turnOn = useCallback(() => {
    setIsOn(true);
  }, []);

  const turnOff = useCallback(() => {
    setIsOn(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOn((isOnCurrentValue) => !isOnCurrentValue);
  }, []);

  return useMemo(
    () => ({
      isOn,
      turnOn,
      turnOff,
      setIsOn,
      toggle,
    }),
    [isOn, toggle, turnOff, turnOn]
  );
}
