import { useCallback } from "react";
import { ExtendedTaskGroup } from "@_types/tasks";
import { Result } from "@pairtreefamily/utils";

export const useReorderTaskGroups = (
  taskGroups: ExtendedTaskGroup[] | undefined,
  caseId: string,
  updateTaskGroupOrder: (args: {
    taskGroups: Array<{ taskGroupId: string; order: number }>;
    caseId: string;
  }) => Promise<Result<{ success: string }, string>>
) => {
  return useCallback(
    async (newOrder: string[]) => {
      const newTaskGroups: { taskGroupId: string; order: number }[] = [];

      newOrder.forEach((item, index) => {
        if (taskGroups?.[index]?.id !== item) {
          newTaskGroups.push({
            taskGroupId: item,
            order: index,
          });
        }
      });

      await updateTaskGroupOrder({
        taskGroups: newTaskGroups,
        caseId,
      }).catch(() => {
        console.log("error updating taskGroup order");
      });
    },
    [caseId, taskGroups, updateTaskGroupOrder]
  );
};
