import { palette } from "./palette";

export const customScroll = {
  scrollbarGutter: "stable",

  "&::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: "8px",
    height: "8px",
    zIndex: 9999,
    cursor: "pointer",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent",
    borderRadius: "8px",
    border: `0px solid ${palette.background.default}`,
  },
  "&:hover::-webkit-scrollbar-thumb": {
    backgroundColor: palette.divider,
  },

  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};
