import {
  ModalProps as MuiModalProps,
  Modal as MuiModal,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { memo, ReactNode, useCallback, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  modalContainer,
  title as titleStyle,
  error as errorStyle,
  buttons,
  closeButton,
  scrollingContainer,
} from "./styles";
import { Button } from "../button";

export type ModalProps = Omit<MuiModalProps, "children" | "title"> & {
  children: ReactNode;
  title: ReactNode;
  submitButton?: {
    label?: string;
    onClick: () =>
      | string
      | undefined
      | void
      | null
      | Promise<string | null | void>;
    disabled?: boolean;
  };
};

export const Modal = memo(({ title, ...props }: ModalProps) => {
  const [error, setError] = useState<string | undefined | void | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const res = await props.submitButton?.onClick();
    setError(res);

    setLoading(false);
  }, [props.submitButton]);

  return (
    <MuiModal {...props}>
      <Box sx={modalContainer}>
        <Box sx={titleStyle}>
          {typeof title === "string" ? (
            <Typography variant="head5">{title}</Typography>
          ) : (
            <div>{title}</div>
          )}
          <IconButton
            sx={closeButton}
            color="success"
            onClick={(event) => props.onClose?.(event, "escapeKeyDown")}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Box>

        <Box sx={scrollingContainer}>
          <Typography variant="input" component="div">
            {props.children}
          </Typography>

          {!!error?.length && <Box sx={errorStyle}>{error}</Box>}

          {props.submitButton?.onClick && (
            <Box sx={buttons}>
              <Button
                size="small"
                variant="outlined"
                onClick={(event) =>
                  props.onClose?.(event ?? {}, "escapeKeyDown")
                }
              >
                Cancel
              </Button>

              <Button
                size="small"
                isLoading={loading}
                disabled={loading || props.submitButton?.disabled}
                onClick={handleSubmit}
              >
                {props?.submitButton?.label ?? "Submit"}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </MuiModal>
  );
});

Modal.displayName = "Modal";
