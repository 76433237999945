export const CaseDocumentsIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0332 13.6999C13.0332 7.84993 17.6832 4.2666 23.5332 4.2666C29.3832 4.2666 34.0332 7.84993 34.0332 13.6999V16.0666C36.5832 16.0666 38.5332 18.0166 38.5332 20.5666V28.0666C38.5332 36.3166 31.7832 43.0666 23.5332 43.0666C15.2832 43.0666 8.5332 36.3166 8.5332 28.0666V20.5666C8.5332 18.0166 10.4832 16.0666 13.0332 16.0666V13.6999ZM23.5332 7.2666C19.3332 7.2666 16.0332 9.49993 16.0332 13.6999V16.0666H31.0332V13.6999C31.0332 9.49993 27.7332 7.2666 23.5332 7.2666ZM30.7875 26.8825C31.9698 25.6814 31.1409 23.6236 29.4809 23.6673C29.0031 23.688 28.5675 23.8809 28.2364 24.246L21.7053 30.9958C21.5187 31.168 21.2298 31.168 21.0631 30.9958L18.6787 28.5315C16.9787 26.6896 14.3453 29.4111 16.1275 31.168L20.4209 35.6258C20.9387 36.1609 21.8098 36.1609 22.3275 35.6258L30.7875 26.8825Z"
        fill="#99CA44"
      />
    </svg>
  );
};
