import { useCallback } from "react";
import { Controller, ControllerProps } from "react-hook-form";

import { ErrorMessage } from "../error-message";
import { Autocomplete, AutocompleteProps } from "../../mui";

export interface AutocompleteFieldProps
  extends Omit<AutocompleteProps<boolean>, "onChange" | "value"> {
  name: string;
}

export const AutocompleteField = ({
  name,
  ...rest
}: AutocompleteFieldProps) => {
  const render: ControllerProps["render"] = useCallback(
    ({ field, fieldState }) => (
      <>
        <Autocomplete
          id={name}
          onChange={field.onChange}
          value={field.value}
          {...rest}
        />

        {fieldState.invalid && <ErrorMessage fieldState={fieldState} />}
      </>
    ),
    [name, rest]
  );

  return <Controller name={name} render={render} />;
};
