export function sortByKey<T extends Record<string, any>>(
  array: T[],
  key: keyof T
): T[] {
  return [...array].sort((a, b) => {
    if (a[key].toLowerCase() < b[key].toLowerCase()) {
      return -1;
    }

    if (a[key].toLowerCase() > b[key].toLowerCase()) {
      return 1;
    }
    return 0;
  });
}
