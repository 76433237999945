import { z } from "zod";

import {
  dateValidationSchema,
  withMinDateValidation,
} from "@pairtreefamily/ui";
import { requiredString } from "@shared/validation-schemas";

import { taskTypes, taskStatuses } from "./constants";

const taskTypeEnum = z.enum(taskTypes);

const commonTaskValidationSchema = z
  .object({
    title: requiredString,
    status: z.enum(taskStatuses),
    description: z.string().optional(),
    taskGroupId: z.string().optional(),
    taskType: z.enum(taskTypes),
    question: z.string().optional(),
    answer: z.boolean().nullable(),
    expirationDateRequired: z.boolean(),
    expirationDate: dateValidationSchema.optional().nullable(),
    isPrivate: z.boolean().optional(),
    areNotificationsEnabled: z.boolean(),
    recipientEmailIds: z.array(z.string()).optional(),
  })
  .refine(
    (data) =>
      !data.areNotificationsEnabled ||
      (data.areNotificationsEnabled &&
        data.recipientEmailIds &&
        data.recipientEmailIds.length > 0),
    {
      path: ["recipientEmailIds"],
      params: { i18n: "custom.emptyRecipients" },
    }
  );

const electronicDocumentValidationSchema = z.object({
  taskType: z.literal(taskTypeEnum.enum.electronicDocument),
  templateUuid: requiredString,
});

const fileUploadValidationSchema = z.object({
  taskType: z.literal(taskTypeEnum.enum.fileUpload),
});

const referenceValidationSchema = z.object({
  taskType: z.literal(taskTypeEnum.enum.reference),
  templateUuid: requiredString,
});

const conditionalSchema = z.discriminatedUnion("taskType", [
  electronicDocumentValidationSchema,
  fileUploadValidationSchema,
  referenceValidationSchema,
]);

export const expirationDateSchema = z.object({
  expirationDate: withMinDateValidation(dateValidationSchema).nullable(),
});

export const validationSchema =
  commonTaskValidationSchema.and(conditionalSchema);

export const validationSchemaWithExpirationDateRequired =
  validationSchema.and(expirationDateSchema);

export type FormValues = z.infer<typeof validationSchema>;
