export const styles = {
  wrapper: {
    borderRadius: 1,
    padding: 2,
    color: "error.light",
    backgroundColor: "error.background",
    display: "flex",
    justifyItems: "center",
  },
};
