import {
  HTMLAttributes,
  memo,
  SyntheticEvent,
  useCallback,
  useMemo,
} from "react";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteOwnerState,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
  Chip,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import { TextInput } from "../text-input";
import { Checkbox } from "../checkbox";

import type { SelectOption } from "../../../types";

export interface AutocompleteProps<
  TMultiple extends boolean,
  TValue = TMultiple extends true ? string[] : string | boolean | null
> extends Omit<
    MuiAutocompleteProps<TValue, TMultiple, boolean, boolean>,
    "renderInput" | "onChange" | "value" | "options" | "multiple"
  > {
  label?: string;
  multiple?: TMultiple;
  value?: TValue;
  options: SelectOption<string | boolean>[];
  onChange?: (value: TValue) => void;
  required?: boolean;
}

export const Autocomplete = memo(
  ({
    required,
    label,
    options,
    value,
    onChange,
    multiple = false,
    ...rest
  }: AutocompleteProps<boolean, any>) => {
    const filteredValues = useMemo(() => {
      if (multiple) {
        return Array.isArray(value)
          ? options.filter((option) => value.includes(option.id))
          : [];
      }

      return options.find((option) => option.id === value) ?? null;
    }, [value, options, multiple]);

    const handleChange = useCallback(
      (_: SyntheticEvent, newValue: SelectOption | SelectOption[] | null) => {
        if (newValue === null) return onChange?.(null);

        if (multiple && Array.isArray(newValue)) {
          onChange?.(newValue.map((option) => option.id));
          return;
        }

        const singleValue = newValue as SelectOption;
        onChange?.(singleValue.id);
      },
      [multiple, onChange]
    );

    const renderTags = (
      value: SelectOption[],
      getTagProps: AutocompleteRenderGetTagProps
    ) =>
      value.map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          key={option.id.toString()}
          label={option.label}
          deleteIcon={<CloseRoundedIcon />}
        />
      ));

    const renderOption = (
      props: HTMLAttributes<HTMLLIElement>,
      option: SelectOption,
      { selected }: AutocompleteRenderOptionState,
      { multiple }: AutocompleteOwnerState<unknown, boolean, boolean, boolean>
    ) => {
      return (
        <li {...props} key={`${option.label}-${option.id}`}>
          {multiple && <Checkbox checked={selected} />}
          <Typography variant="input">{option.label}</Typography>
        </li>
      );
    };

    return (
      <MuiAutocomplete
        {...rest}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        options={options}
        value={filteredValues}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (multiple ? option.id : option.label)}
        renderInput={(params) => {
          const showPlaceholder =
            !multiple || !(Array.isArray(value) && value.length > 0);

          return (
            <TextInput
              placeholder={showPlaceholder ? rest.placeholder : ""}
              required={required}
              label={label}
              {...params}
            />
          );
        }}
        popupIcon={<KeyboardArrowDownRoundedIcon />}
        renderTags={renderTags}
        renderOption={renderOption}
        slotProps={{
          popupIndicator: { size: "small" },
          clearIndicator: { size: "small" },
        }}
        onChange={handleChange}
      />
    );
  }
);

Autocomplete.displayName = "Autocomplete";
