import { selectClasses } from "@mui/material";
import { palette } from "../palette";

export const MuiSelect = {
  defaultProps: {},
  styleOverrides: {
    root: {
      height: "48px",

      [`& .${selectClasses.iconOutlined}`]: {
        color: palette.success.light,
        width: "24px",
        height: "24px",
        marginTop: "5px",
        marginRight: "5px",
      },
    },
  },
};
