import { trpc } from "../trpc";
import { Result } from "@pairtreefamily/utils";
import { Case } from "@_types/*";
import { useCallback, useMemo } from "react";
import {
  AssignUserToCasePayload,
  AssignUserToCaseResult,
  CompleteCaseArgs,
} from "./types";

export const useGetProviderCases = () => {
  return trpc.homeStudy.getProviderCases.useQuery<
    void,
    Result<Case[], string>
  >();
};

export const useGetAllCases = () => {
  return trpc.homeStudy.getAllCases.useQuery<void, Result<Case[], string>>();
};

export const useInvalidateAllCases = () => {
  const context = trpc.useContext();

  return context.homeStudy.getAllCases.invalidate;
};

export const useInvalidateCaseLoginUsers = () => {
  const context = trpc.useContext();

  return context.homeStudy.getCaseLoginUsers.invalidate;
};

export const useGetFamilyNameByCaseId = ({
  caseId,
  enabled,
}: {
  caseId: string;
  enabled: boolean;
}) => {
  return trpc.homeStudy.getFamilyNameByCaseId.useQuery<
    void,
    Result<string, string>
  >({ caseId }, { enabled });
};

export const useGetCaseParticipants = ({ caseId }: { caseId: string }) => {
  return trpc.homeStudy.getCaseParticipants.useQuery({ caseId: caseId });
};

export const useUpsertCase = ({ onSuccess }: { onSuccess: () => void }) => {
  return trpc.homeStudy.upsertCase.useMutation({
    onSuccess,
  });
};

export const useCloneCase = ({ onSuccess }: { onSuccess: () => void }) => {
  const { mutateAsync: cloneCase } = trpc.homeStudy.cloneCase.useMutation({
    onSuccess,
  });

  return cloneCase;
};

export const useGetCaseLoginUsers = ({ caseId }: { caseId: string }) => {
  return trpc.homeStudy.getCaseLoginUsers.useQuery({
    caseId: caseId,
    role: ["family", "provider", "admin"],
  });
};

export const useAssignUserToCase = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: (
    data: AssignUserToCaseResult,
    variables: AssignUserToCasePayload
  ) => void;
}) => {
  const { mutateAsync: assignUserToCase } =
    trpc.homeStudy.assignUserToCase.useMutation({
      onSuccess,
    });

  return useCallback(
    async (args: AssignUserToCasePayload) =>
      assignUserToCase({
        caseId,
        ...args,
      }),
    [assignUserToCase, caseId]
  );
};

export const useRemoveAssigneeFromCase = ({
  onSuccess,
  caseId,
}: {
  onSuccess: () => void;
  caseId: string;
}) => {
  const { mutateAsync: removeAssigneeFromCase } =
    trpc.homeStudy.removeAssigneeFromCase.useMutation({
      onSuccess,
    });

  return useCallback(
    (args: { id: string; role: "provider" | "family" | "admin" }) =>
      removeAssigneeFromCase({
        ...args,
        caseId,
      }),
    [removeAssigneeFromCase, caseId]
  );
};

export const useHideCase = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: hideCase } = trpc.homeStudy.hideCase.useMutation({
    onSuccess,
  });

  return useCallback(
    (hide: boolean) =>
      hideCase({
        hide,
        caseId,
      }),
    [hideCase, caseId]
  );
};

export const useIssueDocumentForCase = ({
  caseId,
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const {
    mutateAsync: _issueDocumentsForCase,
    isIdle: issueDocumentsForCaseIsIdle,
    isLoading: issueDocumentsForCaseIsLoading,
  } = trpc.homeStudy.issueDocumentsForCase.useMutation({
    onSuccess,
  });

  const issueDocumentsForCase = useCallback(
    (onlyFailed?: boolean) => _issueDocumentsForCase({ caseId, onlyFailed }),
    [_issueDocumentsForCase, caseId]
  );
  return useMemo(() => {
    return {
      issueDocumentsForCase,
      issueDocumentsForCaseIsIdle,
      issueDocumentsForCaseIsLoading,
    };
  }, [
    issueDocumentsForCaseIsIdle,
    issueDocumentsForCaseIsLoading,
    issueDocumentsForCase,
  ]);
};

export const useInvalidateGetProviderCases = () => {
  const context = trpc.useContext();

  return context.homeStudy.getProviderCases.invalidate;
};

export const useGetCaseCompletion = ({ caseId }: { caseId: string }) => {
  return trpc.homeStudy.getCaseCompletion.useQuery({
    caseId,
  });
};

export const useInvalidateGetCaseCompletion = () => {
  const context = trpc.useContext();

  return context.homeStudy.getCaseCompletion.invalidate;
};

export const useChangeCaseStatus = ({
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: changeCaseStatus, isLoading: changeStatusLoading } =
    trpc.homeStudy.updateCaseStatus.useMutation({ onSuccess });

  return {
    changeStatusLoading,
    changeCaseStatus: useCallback(
      async ({ caseId, active }: { caseId: string; active: boolean }) => {
        await changeCaseStatus({ caseId, active });
      },
      [changeCaseStatus]
    ),
  };
};

export const useCompleteCase = ({
  onSuccess,
}: {
  caseId: string;
  onSuccess: () => void;
}) => {
  const { mutateAsync: completeCase, isLoading: completeCaseLoading } =
    trpc.homeStudy.completeCase.useMutation({ onSuccess });

  return {
    completeCaseLoading,
    completeCase: useCallback(
      async ({ caseId, approvedAt, expiresAt }: CompleteCaseArgs) => {
        await completeCase({ caseId, approvedAt, expiresAt });
      },
      [completeCase]
    ),
  };
};
