import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import type { TaskTypeOption } from "../types";

export function useTaskTypeOptions(): TaskTypeOption[] {
  const { t } = useTranslation("components");

  return useMemo(
    () => [
      {
        id: "electronicDocument",
        label: t("taskManagerModal.typeOptions.electronicDocument"),
      },
      {
        id: "fileUpload",
        label: t("taskManagerModal.typeOptions.fileUpload"),
      },
      {
        id: "reference",
        label: t("taskManagerModal.typeOptions.reference"),
      },
    ],
    [t]
  );
}
