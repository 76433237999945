import { memo, ReactNode } from "react";
import { IMaskInputProps } from "react-imask";

import { TextField, FormControlLabel, TextFieldProps } from "@mui/material";
import { IMaskCustomInput } from "./IMaskCustomInput";

import { controlLabelComponentProps, controlLabelStyles } from "../text-input";

export type PhoneNumberInputProps = Omit<TextFieldProps, "label"> &
  IMaskInputProps<HTMLInputElement> & {
    label?: ReactNode;
  };

export const PhoneNumberInput = memo(
  ({ label, ...restProps }: PhoneNumberInputProps) => {
    const TextFieldElement = (
      <TextField
        inputProps={restProps}
        InputProps={{
          inputComponent: IMaskCustomInput as any,
        }}
      />
    );

    if (!label) return TextFieldElement;

    return (
      <FormControlLabel
        label={label}
        required={restProps.required}
        sx={controlLabelStyles}
        componentsProps={controlLabelComponentProps}
        labelPlacement="top"
        control={TextFieldElement}
      />
    );
  }
);

PhoneNumberInput.displayName = "PhoneNumberInput";
