import { AppProps } from "next/app";
import "../shared/styles/index.css";
import { trpc } from "@api";
import {
  AuthStatusProvider,
  SnackbarNotificationProvider,
  ThemeProvider,
  UnsavedChangesProvider,
} from "@pairtreefamily/ui";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";
import { firebaseApp } from "@lib";
import { AuthGuard, Routes, routesConfig } from "@features/routing";

// Using alias @shared/components will break application initialization despite of it's defined in tsconfig.json
import { Layout } from "../shared/components/layout";
import { UserDataProvider } from "../shared/components/user-provider";
import { OrganizationsProvider } from "../shared/components/organizations-provider";
import { QueryProvider } from "@api";
import { useCallback } from "react";
import { FamilyPendingInvitationsProvider } from "../features/connect/components/pending-invitations";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { query, replace, pathname } = router;
  const routeConfig = routesConfig[pathname as Routes];

  const getSSOCustomJwt = useCallback(() => {
    const customJwt = (query?.jwt ?? null) as string | null;

    // remove the jwt from the query string
    if (customJwt) {
      const newQuery = { ...query };

      delete newQuery.jwt;

      replace(
        {
          query: {
            ...newQuery,
          },
        },
        undefined,
        { shallow: true }
      );
    }

    return customJwt;
  }, [query, replace]);

  return (
    <ThemeProvider>
      <SnackbarNotificationProvider>
        <AuthStatusProvider app={firebaseApp} getSSOCustomJwt={getSSOCustomJwt}>
          <QueryProvider>
            <UserDataProvider>
              <OrganizationsProvider>
                <AuthGuard>
                  <FamilyPendingInvitationsProvider>
                    <UnsavedChangesProvider router={router}>
                      <Layout routeConfig={routeConfig}>
                        <Component {...pageProps} />
                      </Layout>
                    </UnsavedChangesProvider>
                  </FamilyPendingInvitationsProvider>
                </AuthGuard>
              </OrganizationsProvider>
            </UserDataProvider>
          </QueryProvider>
        </AuthStatusProvider>
      </SnackbarNotificationProvider>
    </ThemeProvider>
  );
}
export default trpc.withTRPC(appWithTranslation(MyApp));
