import { useCallback, useState } from "react";
import type { FirebaseApp } from "firebase/app";
import { Form, TextField, useFirebaseAuth, Button } from "@pairtreefamily/ui";
import { GoogleIcon } from "@pairtreefamily/ui";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { googleButtonStyle, loginButtonStyle } from "./styles";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormValues, validationSchema } from "./validation-schema";
import { Routes } from "@features/routing";
import { Err, Ok, Result } from "@pairtreefamily/utils";
import { useTranslation } from "next-i18next";
import { useGetAuthUserDataAsync } from "@api";

export interface LoginProps {
  app: FirebaseApp;
  // Disables acting on any of the login option buttons.
  disabled?: boolean;
  mode: AuthMode;
  onSuccess?: (args: {
    token: string;
    provider: string;
    mode: AuthMode;
  }) => void;
  onFailure?: (args: {
    provider: string;
    mode: AuthMode;
    message: string;
  }) => void;
  submitButtonLabel?: string;
  onlyGoogle?: boolean;
  isLoading?: boolean;
}

export enum AuthMode {
  login = "login",
  signUp = "signup",
}

export function LoginAndSignUp(props: LoginProps) {
  const { t } = useTranslation("components");

  const {
    app,
    disabled,
    onSuccess,
    onFailure,
    mode,
    submitButtonLabel,
    onlyGoogle,
    isLoading,
  } = props;
  const { signInWithGoogle, signInWithEmail, createUserInFirebase, signOut } =
    useFirebaseAuth({ app });

  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getUserData = useGetAuthUserDataAsync();

  const actionText = mode === "login" ? "Log In" : "Sign Up";
  const formMethods = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const [email, password] = formMethods.watch(["email", "password"]);

  const handleGoogleLoginSignUp = useCallback(async (): Promise<
    Result<string, string>
  > => {
    try {
      const { token } = await signInWithGoogle();

      const { error } = await getUserData();

      if (error) {
        signOut();

        return Err("auth/user-not-found");
      }

      return Ok(token);
    } catch (e) {
      console.error(e);
      setError(t("loginSignUp.loginErrorGoogle", { actionText }));
      return Err(JSON.stringify(e));
    }
  }, [signInWithGoogle, getUserData, signOut, t, actionText]);

  const handleEmailLoginSignUp = useCallback(async (): Promise<
    Result<string, string>
  > => {
    if (mode === "login") {
      try {
        const { token } = await signInWithEmail(email, password);
        return Ok(token);
      } catch (e) {
        console.error(e);
        setError(t("loginSignUp.loginErrorEmail"));
        return Err(JSON.stringify(e));
      }
    } else {
      try {
        const { token } = await createUserInFirebase(email, password);
        return Ok(token);
      } catch (e) {
        console.error(e);
        setError(t("loginSignUp.signUpError"));
        return Err(JSON.stringify(e));
      }
    }
  }, [
    signInWithEmail,
    email,
    password,
    mode,
    setError,
    t,
    createUserInFirebase,
  ]);

  const getErrorMessage = useCallback(
    (text: string) => {
      if (text.includes("auth/email-already-in-use"))
        return t("loginSignUp.emailInUse");

      if (text.includes("auth/weak-password"))
        return t("loginSignUp.passwordInvalid");

      if (text.includes("auth/user-not-found"))
        return t("loginSignUp.userNotFound");

      if (text.includes("auth/wrong-password"))
        return t("loginSignUp.wrongPassword");

      return t("loginSignUp.genericError");
    },
    [t]
  );

  const loginOrSignUpWithProvider = useCallback(
    async (provider: "google" | "email") => {
      setLoading(true);
      let response;
      if (provider === "google") {
        response = await handleGoogleLoginSignUp();
      } else {
        response = await handleEmailLoginSignUp();
      }
      setLoading(false);

      if (!response.ok) {
        const message = getErrorMessage(response.error);
        setError(message);
        onFailure?.({ provider, mode, message });
        return;
      }

      setError(null);
      onSuccess?.({ token: response.content, provider, mode });
    },
    [
      onSuccess,
      onFailure,
      setError,
      setLoading,
      handleEmailLoginSignUp,
      handleGoogleLoginSignUp,
      mode,
      getErrorMessage,
    ]
  );

  return (
    <Box mt={4} display="flex">
      <Box maxWidth={500} mx="auto" width="100%">
        <Button
          disabled={disabled || loading}
          isLoading={loading || isLoading}
          sx={googleButtonStyle}
          variant="outlined"
          onClick={() => loginOrSignUpWithProvider("google")}
        >
          <GoogleIcon />
          <Box>
            <Typography textTransform="none" variant="body9">
              {t("loginSignUp.continueGoogle")}
            </Typography>
          </Box>
        </Button>

        {!onlyGoogle && (
          <Box>
            <Typography component="p" variant="body4" mt={4} textAlign="center">
              {t("loginSignUp.continueWithEmail")}
            </Typography>
            <Form<FormValues>
              formMethods={formMethods}
              onSubmit={() => loginOrSignUpWithProvider("email")}
            >
              <Box mt={2}>
                <TextField
                  name="email"
                  label="E-mail"
                  placeholder={t("loginSignUp.emailPlaceholder")}
                ></TextField>
              </Box>
              <Box mt={1}>
                <TextField
                  name="password"
                  type={visible ? "text" : "password"}
                  label="Password"
                  placeholder={t("loginSignUp.passwordPlaceholder")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={t("loginSignUp.ariaLabelVisibility")}
                          edge="end"
                          onClick={() => setVisible(!visible)}
                        >
                          <Box color="black">
                            {visible ? (
                              <VisibilityOutlinedIcon fontSize="small" />
                            ) : (
                              <VisibilityOffOutlinedIcon fontSize="small" />
                            )}
                          </Box>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {mode === AuthMode.login && (
                  <Typography component="p" textAlign="center" my={2}>
                    <Link
                      underline="none"
                      color="primary.main"
                      href={Routes.ProForgotPassword}
                    >
                      <Typography variant="body6">
                        {t("loginSignUp.forgotPassword")}
                      </Typography>
                    </Link>
                  </Typography>
                )}

                <Button
                  type="submit"
                  disabled={disabled || loading}
                  isLoading={loading || isLoading}
                  sx={loginButtonStyle}
                >
                  <Typography textTransform="none" variant="body9">
                    {" "}
                    {submitButtonLabel ?? t("loginSignUp.signIn")}
                  </Typography>
                </Button>
              </Box>
            </Form>
          </Box>
        )}
        {error && (
          <Typography variant="body2" color="error" mt={8}>
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
