import { z } from "zod";
import { requiredString } from "@shared/validation-schemas";

export const validationSchema = z.object({
  title: requiredString,
  taskGroupInstruction: requiredString,
  isHidden: z.boolean(),
  providerOnly: z.boolean(),
});

export type FormValues = z.infer<typeof validationSchema>;
