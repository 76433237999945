import { ExtendedTaskGroup } from "@_types/tasks";

export function getCompletedAndTotalTaskFromTaskGroups(
  taskGroups: ExtendedTaskGroup[]
) {
  return taskGroups?.reduce(
    (acc, cur) => {
      const completed = cur.tasks.filter(
        (item) => item.status === "completed"
      ).length;
      const total = cur.tasks.length;
      return { completed: acc.completed + completed, total: acc.total + total };
    },
    { completed: 0, total: 0 }
  );
}
