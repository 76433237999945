import { palette, theme } from "@pairtreefamily/ui";

const desktopHeaderHeight = 88;

export const styles = {
  wrapper: {
    width: "100%",
    height: desktopHeaderHeight,
    borderBottom: `1px solid ${palette.divider}`,
    backgroundColor: palette.background.default,
  },
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingX: theme.spacing(5),
    gap: theme.spacing(2),
  },
  image: {
    width: "198px",
  },
};
