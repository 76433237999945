import { trpc } from "../trpc";
import { Template, TemplateFolder } from "./types";
import { Result } from "@pairtreefamily/utils";
import { useCallback } from "react";

type UseGetTemplatesArgs = {
  rootFolderId: string;
  caseId: string;
};
export const useGetTemplates = ({
  caseId,
  rootFolderId,
}: UseGetTemplatesArgs) => {
  return trpc.homeStudy.getDocumentTemplates.useQuery<
    void,
    Result<Template[], string>
  >(
    {
      caseId,
      rootFolderId,
    },
    {
      enabled: !!caseId && !!rootFolderId,
    }
  );
};

type UseGetTemplateFoldersArgs = {
  rootFolderId: string;
  caseId: string;
};

export const useGetTemplateFolders = ({
  caseId,
  rootFolderId,
}: UseGetTemplateFoldersArgs) => {
  return trpc.homeStudy.getDocumentTemplateFolders.useQuery<
    void,
    Result<TemplateFolder[], string>
  >({
    caseId,
    rootFolderId,
  });
};

export const useGetTemplateRolesById = () => {
  const { mutateAsync: getTemplateRoles } =
    trpc.homeStudy.getTemplateRoles.useMutation();

  return useCallback(
    async (args: { templateId: string }) => await getTemplateRoles(args),
    [getTemplateRoles]
  );
};
