import { useTranslation } from "next-i18next";

import { AutocompleteField } from "@pairtreefamily/ui";

import { Separator } from "@shared/components";

import { ExpirationDateRequired } from "./ExpirationDateRequired";

import { useGetTemplatesOptions } from "../hooks";

import type { Template } from "@api";
import type { Task } from "@_types/*";
import { ElectronicDocumentTask } from "@_types/*";
import { Typography } from "@mui/material";

export type ElectronicDocumentFieldsProps = {
  task?: Task;
  templateUuid?: string | null;
  templatesList: Template[];
  isAdmin: boolean;
};

export function ElectronicDocumentFields(props: ElectronicDocumentFieldsProps) {
  const { task, templateUuid, templatesList, isAdmin } = props;

  const { t } = useTranslation("components");

  const templateOptions = useGetTemplatesOptions(templatesList);

  const isUpdateFieldWarningDisplayed =
    task && templateUuid !== (task as ElectronicDocumentTask)?.templateUuid;

  return (
    <>
      <AutocompleteField
        name="templateUuid"
        options={templateOptions}
        disabled={!isAdmin}
        label={t("electronicDocumentFields.fields.templateUUID")}
        className="-mt-4"
      />

      {!templatesList.length && (
        <Typography display="inline-block" variant="caption1" color="error">
          {t("taskManagerModal.electronicDocument.emptyTemplatesList")}
        </Typography>
      )}

      {isUpdateFieldWarningDisplayed && (
        <Typography display="inline-block" variant="body6" color="error">
          {t("electronicDocumentFields.updatingWarning")}
        </Typography>
      )}

      <Separator className="mt-8 mb-8" />

      <ExpirationDateRequired />
    </>
  );
}
