import { palette } from "../../theme";
import { paperClasses } from "@mui/material";
import type { SnackbarType } from "./types";

export const styles = {
  iconButton: (type: SnackbarType) => {
    return {
      color:
        type === "info"
          ? palette.proBlue.contrastText
          : palette.error.contrastText,

      "&:hover": {
        color:
          type === "info"
            ? palette.proBlue.contrastText
            : palette.error.contrastText,
      },
    };
  },
  snackbar: (type: SnackbarType) => {
    return {
      [`& .${paperClasses.root}`]: {
        backgroundColor:
          type === "info" ? palette.proBlue.dark : palette.error.main,
      },
    };
  },
};
