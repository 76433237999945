import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material";
import { ChangeEvent, memo, ReactNode, useCallback, useMemo } from "react";

const componentProps = {
  typography: {
    variant: "input" as const,
    paddingLeft: "4px",
  },
};

const formControlLabelStyles = {
  marginLeft: "-5px",
};

export type CheckboxProps = {
  label?: ReactNode;
  onChange?: (isChecked: boolean) => void;
} & Omit<MuiCheckboxProps, "onChange">;

export const Checkbox = memo(({ label = "", ...rest }: CheckboxProps) => {
  const inputProps = useMemo(() => {
    if (typeof label === "string") {
      return { "aria-label": label };
    }
    return undefined;
  }, [label]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      rest.onChange?.(event.target.checked);
    },
    [rest]
  );

  if (!label) {
    return (
      <MuiCheckbox inputProps={inputProps} {...rest} onChange={handleChange} />
    );
  }

  return (
    <FormControlLabel
      sx={formControlLabelStyles}
      componentsProps={componentProps}
      control={
        <MuiCheckbox
          inputProps={inputProps}
          {...rest}
          onChange={handleChange}
        />
      }
      label={label}
    />
  );
});

Checkbox.displayName = "Checkbox";
