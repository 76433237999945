import { palette } from "../palette";
import { alpha, autocompleteClasses } from "@mui/material";
import { typography } from "../typography";

export const MuiAutocomplete = {
  defaultProps: {},
  styleOverrides: {
    listbox: {
      [`& .${autocompleteClasses.option}`]: {
        minHeight: 48,
        ...typography.input,

        ["&[aria-selected='true']"]: {
          backgroundColor: palette.background.light,
        },

        "&.Mui-focused": {
          backgroundColor: palette.primary.background,

          "&:hover": {
            backgroundColor: `${alpha(
              palette.background.light,
              palette.action.focusOpacity
            )}`,
          },

          ["&[aria-selected='true']"]: {
            backgroundColor: palette.background.light,
          },
        },

        "&.Mui-focusVisible": {
          backgroundColor: palette.primary.background,
        },
      },
    },
    inputRoot: {
      gap: 8,
      "&.MuiInputBase-sizeSmall": {
        padding: "8px 0 8px 8px",

        "& .MuiAutocomplete-input": {
          boxSizing: "border-box",
          height: 32,
          padding: "0 32px 0 8px!important",
        },
      },
      "&.MuiInputBase-adornedStart": {
        paddingLeft: "8px!important",
      },
      "& .MuiAutocomplete-endAdornment": {
        top: 11,
        right: 12,
        fontSize: 0,

        "& .MuiAutocomplete-popupIndicator": {
          margin: 0,
        },
      },
    },
    tag: {
      margin: 0,
      backgroundColor: `${alpha(
        palette.background.light,
        palette.action.focusOpacity
      )}`,
      border: `1px solid ${palette.divider}`,
      borderRadius: 6,

      "& .MuiChip-label": {
        padding: `0 8px`,
        color: palette.text.primary,
        ...typography.input,
      },
      "& .MuiChip-deleteIcon": {
        margin: "0 8px 0 0",
        fontSize: 16,
        color: palette.success.light,
      },
    },
    option: {
      "& .MuiTypography-root": {
        paddingLeft: 4,
      },
    },
    noOptions: {
      ...typography.input,
    },
  },
};
